import { Helmet } from 'react-helmet-async';
// @mui
import {
    Card,
    Stack,
    Container,
    Typography,
    TableContainer, CardContent, Button, CircularProgress, Box,
} from '@mui/material';

import {useNavigate, useParams} from 'react-router-dom';

// components
import Scrollbar from '../components/scrollbar';
// sections
import { StepByStepTable } from "../sections/@dashboard/attestations";
import {GeneralLayout} from "../layouts/GeneralLayout";
import Label from "../components/label";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {useGetNextAttestationNumberQuery, usePostCreateAttestationMutation} from "../store/attestation/attestationApi";
import PageTitle from "../components/PageTitle";

export default function CreateAttestationPage() {
    const {id} = useParams();
    const navigate = useNavigate();
    const [isSaving, setIsSaving] = useState(false);
    const [startDate, setStartDate] = useState(formatDate(new Date()));
    const [endDate, setEndDate] = useState(formatDate(new Date()));
    const {data} = useGetNextAttestationNumberQuery({courseId: id});
    const [createAttestation] = usePostCreateAttestationMutation();
    
    useEffect(() => {
        if (data === null || data === undefined)
            return;
        
        if (!data.success) {
            data.errors.map((e) => {
                return alert(e);
            })
        }
    }, [data])

    function formatDate(dateObj) {
        return `${dateObj.getFullYear()}-${withZero(dateObj.getMonth() + 1)}-${withZero(dateObj.getDate())}`;
    }

    function withZero(num) {
        return num < 10 ? `0${num}` : num;
    }

    const handleSave = async () => {
        setIsSaving(true);

        await createAttestation({courseId: id, startDate, endDate}).unwrap().then((fulfilled) => {
            if (fulfilled?.success) {
                navigate('/facultyattestations');
            } else {
                fulfilled.errors.map((item) => {
                    alert(item);
                    return 1;
                });
                
                setIsSaving(false);
            }
        });
    };
    
    return (
        <GeneralLayout>
            <PageTitle title={'Создать аттестацию'} />

            <Container>
                <Stack direction="column"
                       padding="120px 0px 0px 100px"
                       spacing={2}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {data === null || data === undefined ? <CircularProgress /> : <></>}
                            Создать аттестацию
                        </Typography>
                    </Stack>

                    <Card>
                        <CardContent>
                            <Stack spacing={2}>
                                <Typography><strong>Номер аттестации:</strong> {data?.data?.attestationNumber}</Typography>
                                
                                <Stack direction="row" spacing={2}>
                                    <Typography alignSelf="center">
                                        <strong>Дата начала аттестации</strong>
                                    </Typography>
                                    <TextField type="date" 
                                               value={startDate} 
                                               onChange={(event) => {setStartDate((event.target.value))}}/>
                                </Stack>

                                <Stack direction="row" spacing={2}>
                                    <Typography alignSelf="center">
                                        <strong>Дата окончания аттестации</strong>
                                    </Typography>
                                    <TextField type="date"
                                               value={endDate}
                                               onChange={(event) => {setEndDate((event.target.value))}}/>
                                </Stack>

                                <Stack spacing={2}>
                                    <Typography><strong>Дисциплины:</strong></Typography>
                                    {data?.data?.disciplines.map((d) => {
                                        return <Typography>{d}</Typography>
                                    })}
                                </Stack>
                                
                                <Box height={10}/>
                                
                                <Stack justifyContent="space-between">
                                    {
                                        !isSaving
                                            ? <Button variant="contained"
                                                      disabled={data === null || data === undefined} 
                                                      onClick={handleSave}>Сохранить</Button>
                                            : <CircularProgress/>
                                    }
                                </Stack>
                            </Stack>
                        </CardContent>
                    </Card>
                </Stack>
            </Container>
        </GeneralLayout>
    );
}