import { Helmet } from 'react-helmet-async';
// @mui
import {
    Card,
    Stack,
    Container,
    Typography,
    TableContainer, CircularProgress, Button, Table, TableBody, TableCell, TableRow,
} from '@mui/material';

import {Link, useNavigate} from 'react-router-dom';

// components
import Scrollbar from '../components/scrollbar';
// sections
import { StepByStepTable } from "../sections/@dashboard/attestations";
import {GeneralLayout} from "../layouts/GeneralLayout";
import {ArrowRightIcon} from "@mui/x-date-pickers";
import {useGetEmployeeAttestationsQuery} from "../store/attestation/attestationApi";
import MainLayout from "./main";
import PageTitle from "../components/PageTitle";

export default function AttestationsPage() {
    const navigate = useNavigate();
    const {data} = useGetEmployeeAttestationsQuery();

    return (
        <MainLayout>
            <PageTitle title={'Список групп к аттестации'} />

            <Container>
                <Stack direction="column"
                       padding="30px 0px 0px 0px"
                       spacing={2}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {data === null || data === undefined ? <CircularProgress /> : <></>}
                            Список групп к аттестации
                        </Typography>
                    </Stack>

                    {data !== null && data?.disciplines?.length === 0
                    ? <Typography align="center">Нет доступных аттестационных листов</Typography>
                    : <></>}

                    {data?.disciplines.map((item) => {
                        return <>
                            <Typography variant="h5" gutterBottom>
                                {item.disciplineName} ({item.courseName})
                            </Typography>

                            <Card>
                                <Table>
                                    <TableBody>
                                        {item.statements.map((s) => {
                                            return <TableRow>
                                                        <TableCell>{s.groupName} ({s.lessonTypeName})</TableCell>
                                                        <TableCell>Для заполнения осталось {s.daysLeft} дней</TableCell>
                                                        <TableCell>
                                                            <Button endIcon={<ArrowRightIcon />}
                                                                    href={`/attestationedit/${s.attestationStatementId}`}>Заполнить аттестационный лист</Button>
                                                        </TableCell>
                                                    </TableRow>
                                        })}
                                    </TableBody>
                                </Table>
                            </Card>
                        </>
                    })}
                </Stack>
            </Container>
        </MainLayout>
    );
}