export function openFsLink(link) {
    // костыль для того, чтобы можно было скачивать файлы из тестового хранилища
    const links = localStorage.getItem("cachedFsLinks")?.split(', ') ?? [];
    const cachedLink = links.find((item) => item.link === link);
    if (cachedLink !== undefined) {
        console.log('Ссылка найдена в кэше')
        window.open(cachedLink.correctLink, '_blank')
        return;
    }
    
    function addLinkToCache(newLink) {
        localStorage.setItem('cachedFsLinks', (localStorage.getItem("cachedFsLinks")?.split(', ') ?? []).push({
            link,
            correctLink: newLink
        }))
    }
    
    console.log('ссылка не найдена в кэше, определяем корректную форму')

    try {
        fetch(link)
            .then((resp) => {
                console.log('response', resp);
                if (resp.status === 404) {
                    console.log(`${link} = 404`)

                    addLinkToCache(`${link}?useTest=1`)

                    window.open(`${link}?useTest=1`, '_blank')
                }
            }).catch((resp) => {
            console.log('error', resp)
            addLinkToCache(`${link}?useTest=1`)
        })
    } catch (err) {
        console.log('catch', err)
    }
}