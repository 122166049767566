import {
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Link,
    Snackbar,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {useEffect, useState} from "react";
import {useDispatch} from 'react-redux';
import {useNavigate, useParams, redirect, useLocation} from "react-router-dom";

import {setRoles, setToken} from '../store/auth/authActions';
import {useSinginMutation} from '../store/auth/authApi';
import {Alert} from "@mui/lab";
// import {store} from "../store/store";
import "./styles/auth.scss";
import PageTitle from "../components/PageTitle";


export const Auth = () => {
    // const {externalToken} = useParams();
    const debugMode = false;
    const {search} = useLocation();
    const externalToken = search?.slice(search?.indexOf('=') + 1);
    const [username, setUsername] = useState("");
    const [isUsernameError, setIsUsernameError] = useState("");
    const [isExternalTokenAuth, setIsExternalTokenAuth] = useState(externalToken !== null && externalToken !== undefined && externalToken.length > 0);
    const [isLoading, setIsLoading] = useState(isExternalTokenAuth);
    const [password, setPassword] = useState("");
    const [isPasswordError, setIsPasswordError] = useState("");
    const [singin, signinResult] = useSinginMutation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const semester = localStorage.getItem("selectedSemesterId");
    const [login, setLogin] = useState("")
    const [message, setMessage] = useState("")
    const [snackOpen, setSnackOpen] = useState(false)
    const [messageType, setMessageType] = useState("success")

    document.onkeyup = (event) => {
        if (event.key === 'Enter') {
            onLoginClick();
        }
    };

    useEffect(() => {
        // setIsLoading(false);
        if (externalToken !== null && externalToken !== undefined && externalToken.length > 0) {
            console.log('Got a token');
            // decode from base64
            const decodedToken = atob(externalToken)?.split(';');
            if (decodedToken !== null && decodedToken !== undefined && decodedToken.length === 2) {
                console.log('Token was decoded successfully')
                setAuthResult(decodedToken[0], decodedToken[1]?.split(','))
            }
            return;
        }

        if (!debugMode) {
            console.log('Redirect')
            window.location.replace('https://auth.kubsau.ru/Account/Authorize?referer=10a77f92-7413-ee11-b48d-bc97e1a423f8');
        }

        // disabled for redirecting to auth.kubsau.ru
        if (signinResult.isSuccess) {
            if (signinResult?.data?.accessToken === null || signinResult?.data?.accessToken === undefined) {
                return;
            }

            setAuthResult(signinResult.data.accessToken, signinResult.data.roles)
        }
    }, [signinResult, externalToken]);

    const doLogin = () => {
        // if (login !== "admin") {
        //     // Заглушка для ошибки входа
        //     setMessage("Неверная пара логин/пароль!")
        //     setMessageType("error")
        //     setSnackOpen(true)
        // } else {
        //     // Заглушка для успешного входа
        //     setMessage("Добро пожаловать")
        //     setMessageType("success")
        //     setSnackOpen(true)
        //
        //     // Нужно заменить на актуальные данные
        //     const user = {
        //         login,
        //         fullname: "",
        //         role: "admin"
        //     }
        //
        //     // Сохранение пользователя в localStorage
        //     localStorage.setItem("loggedUser", JSON.stringify(user))
        //
        //     // Сохранение пользователя в store
        //     store.dispatch({
        //         type: "SET_USER",
        //         payload: user
        //     })
        //
        //     // Переходим на главную страницу после отображения приветствия
        //     setTimeout(() => {
        //         navigate("/main/courses")
        //     }, 800)
        // }

        onLoginClick();
    }

    function setAuthResult(authToken, authRoles) {
        dispatch(setToken({token: authToken}))
        dispatch(setRoles({roles: authRoles}))
        localStorage.setItem("token", authToken);
        localStorage.setItem("authorized-at", new Date());

        const roles = authRoles.map((item) => {
            return roleIdToRoleName(item);
        });

        localStorage.setItem("roles", roles);
        if (semester === undefined || semester === null) {
            localStorage.setItem("selectedSemesterId", signinResult?.data?.semesterId ?? 21) // TODO: obtain semester from backend
        }

        const employeeRole = '2';
        if (roles.find((item) => item === employeeRole) !== undefined) {
            console.log('Переадресация на journalMain')
            // return redirect("/journalmain");
            navigate("/journalmain");
            return
        }

        console.log('Переадресация')
        navigate("/");
        // return redirect('/');
    }

    function roleIdToRoleName(id) {
        let roleName = '';

        switch (id) {
            case '1':
                roleName = 'faculty';
                break;
            case '2':
                roleName = 'employee';
                break;
            case '3':
                roleName = 'chair';
                break;
            default:
                roleName = id.toString();
        }

        return roleName;
    }

    function onLoginClick() {
        if (login.length < 3 || password.length < 8)
            return;

        setIsLoading(true);
        singin({username: login, password})
    }

    function loginPageLayout() {
        return <>
            <h1>Вход</h1>
            <Typography fontSize={12} color={'red'}>{debugMode ? 'DEBUG MODE' : ''}</Typography>
            <div className="field">
                <TextField
                    value={login}
                    sx={{width: 280}}
                    name="login"
                    label="Логин"
                    onInput={e => setLogin(e.target.value)}
                />
            </div>
            <div className="field">
                <TextField
                    value={password}
                    sx={{width: 280}}
                    name="password"
                    label="Пароль"
                    type="password"
                    onInput={e => setPassword(e.target.value)}
                />
            </div>
            <div className="field">
                <Button onClick={doLogin} disabled={isLoading}
                        startIcon={isLoading ? <CircularProgress size={15}/> : <></>} variant="contained">
                    Войти
                </Button>
            </div>
        </>
    }

    return (<>
        <PageTitle title={'Вход'} />
        <div className="login-screen">
            <Card className="login-card">
                <CardContent sx={{padding: "30px"}}>
                    {isExternalTokenAuth
                        ? <Stack alignItems={'center'}>
                            <h1>АИС "Успеваемость"</h1>
                            <p>Вход...</p>
                            <CircularProgress/>
                        </Stack>
                        : <>
                            {!debugMode
                                ? <Stack alignItems={'center'}>
                                    <h1>Перенаправляем на <br/>АИС "Служба авторизации"</h1>
                                    <CircularProgress/>
                                </Stack>
                                : loginPageLayout()}
                        </>}
                </CardContent>
                <Snackbar
                    open={snackOpen}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center"
                    }}
                >
                    <Alert severity={messageType}>{message}</Alert>
                </Snackbar>
            </Card>
        </div>
    </>)
}
