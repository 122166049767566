import { ToggleButton, ToggleButtonGroup, Grid, Card, CardContent, TextField } from "@mui/material"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers"
import { LocalLibraryRounded, EditRounded } from "@mui/icons-material"
import { ThemeProvider } from "@emotion/react"
import toggleTheme from "../../../../components/toggleTheme"
import "dayjs/locale/ru"
import "./styles.scss"
import React, {useState} from "react"
import moment from "moment";

/**
 * Фильтры групп
 * @param props Параметры компонента
 * @returns React.Node
 */
function GroupFilters(props) {
  const [pairsList, setPairsList] = useState([1,2,3,4,5,6]);
  
  const handleSetPair = (e, pair) => {
    if (pair !== null) {
      props.pairSetter(pair)
    }
  }

  const handleSetTheme = (e) => {
    props.themeSetter(e.target.value);
  }

  const disableOtherDates = function (input) {
      const exists = props?.availableLessonDates?.find((item) => {
          const momentItem = moment(item.dateTime);
          // return momentItem.day() === input.day() && momentItem.month() === input.month() && momentItem.year() === input.year();
          return isSameDate(momentItem, input)
      });

      return input.day() === 0 || !exists;
  }
  
  function isSameDate(date1, date2) {
    return date1.date() === date2.date() && date1.month() === date2.month() && date1.year() === date2.year();
  }

  return (
    <Card className="filters-card">
      <CardContent className="filters-card-content">
        <Grid container spacing={2}>
          <Grid item xl={5} lg={4}>
            <TextField
              onChange={handleSetTheme}
              sx={{ width: "100%" }}
              variant="standard"
              placeholder="Тема занятия"
              InputProps={{
                startAdornment: <EditRounded sx={{ marginRight: "10px" }} />
              }}
            />
          </Grid>
          <Grid item lg={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
              <DatePicker
                value={props.date}
                onChange={props.dateSetter}
                disableFuture
                shouldDisableDate={(date)=>disableOtherDates(date)}
                slotProps={{
                  textField: {
                    variant: "standard"
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xl={5} lg={6}>
            <div className="pair-setup">
              <div className="icon-block">
                <LocalLibraryRounded />
                <span>Пара</span>
              </div>
              <div className="buttons-wrapper">
                <ThemeProvider theme={toggleTheme}>
                  <ToggleButtonGroup
                    exclusive
                    value={props.pair}
                    onChange={handleSetPair}
                  >
                    {pairsList.map((item) => {
                      return <ToggleButton size="small" 
                                           disabled={props.availablePairs?.filter((pair) => pair === item).length === 0} 
                                           value={item} 
                                           aria-label={`${item} пара`}>
                        {item}
                      </ToggleButton>
                    })}
                  </ToggleButtonGroup>
                </ThemeProvider>
              </div>
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default GroupFilters;