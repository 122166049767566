import {Helmet} from 'react-helmet-async';
// @mui
import {
    Card,
    Stack,
    Container,
    Typography,
    TableContainer, Button, CircularProgress, TableBody, Table, TableRow, TableCell, ToggleButton, ToggleButtonGroup,
} from '@mui/material';
// components
import {useEffect, useState} from "react";
import Scrollbar from '../components/scrollbar';
import StudentGradeEditTable from "../sections/@dashboard/common/StudentGradeEditTable";
import {useNavigate, useParams} from "react-router-dom";
import {GeneralLayout} from "../layouts/GeneralLayout";
import {TimeIcon} from "@mui/x-date-pickers";
import {
    useGetEmployeeAttestationStatementDetailsQuery,
    usePostEmployeeEditAttestationStatementMutation
} from "../store/attestation/attestationApi";
import MainLayout from "./main";
import PageTitle from "../components/PageTitle";
// sections

export default function AttestationEditPage() {
    const navigate = useNavigate();
    const {id} = useParams();
    const [isSaving, setIsSaving] = useState(false);
    const [refresh, setRefresh] = useState(false);
    
    const [copyOfStudents, setCopyOfStudents] = useState([]);
    const {data} = useGetEmployeeAttestationStatementDetailsQuery({attestationStatementId: id});
    const [saveChanges] = usePostEmployeeEditAttestationStatementMutation();
    
    useEffect(() => {
        
        console.log(data);
        
        if (data === null || data === undefined)
            return;
        
        setCopyOfStudents(copyObject(data.data.students));
    }, [data]);
    
    function copyObject(source) {
        if (source === null || source === undefined)
            return {};
        
        return JSON.parse(JSON.stringify(source));
    }
    
    function applyVisualChanges(){
        setRefresh(!refresh);
    }
    
    function onGradeChanged(student, value) {
        student.gradeId = parseInt(value, 10);
        applyVisualChanges();
    }

    function formatDate(dateObj) {
        return `${dateObj.getFullYear()}-${withZero(dateObj.getMonth() + 1)}-${withZero(dateObj.getDate())}`;
    }

    function withZero(num) {
        return num < 10 ? `0${num}` : num;
    }

    function toDateString(date){
        if (date === null || date === undefined)
            return '';
        
        return new Date(date).toLocaleDateString("ru-RU");
    }

    const handleSave = async () => {
        setIsSaving(true);

        await saveChanges({attestationStatementId: id, students: copyOfStudents}).unwrap().then((fulfilled) => {
            if (fulfilled.success)
                navigate(`/attestations`);
            else {
                alert(fulfilled.message);
                setIsSaving(false);
            }
        });
    };

    return (
        <MainLayout>
            <PageTitle title={'Атестация'} />

            <Container>
                <Stack direction="column"
                       padding="30px 0px 0px 0px"
                       spacing={2}>
                    <Stack spacing={0} mb={5}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography variant="h4" gutterBottom>
                                {data === null || data === undefined ? <CircularProgress/> : <></>}
                                {data?.data?.disciplineName ?? 'Загрузка...'}
                            </Typography>
                        </Stack>

                        <Typography variant="h4" gutterBottom>
                            Аттестация №{data?.data?.attestationNumber ?? '-'} ({toDateString(data?.data?.startDate)} - {toDateString(data?.data?.endDate)})
                        </Typography>
                    </Stack>

                    <Typography variant="h5">
                        {data?.data?.groupName ?? 'Группа'}
                    </Typography>
                    <Card>
                        <Stack spacing={3}>
                            <Table>
                                <TableBody>
                                    {copyOfStudents.map((item, index) => {
                                        return <TableRow>
                                            <TableCell width={10}>
                                                <Typography fontSize="large">{index + 1}</Typography>
                                            </TableCell>
                                            <TableCell><strong>{item.lastName}</strong><br /> {item.firstName} {item.middleName}</TableCell>
                                            <TableCell>
                                                <ToggleButtonGroup orientation="horizontal"
                                                                   exclusive
                                                                   size={"small"}
                                                                   fullWidth
                                                                   value={item.gradeId}
                                                                   onChange={(event) => { onGradeChanged(item, event.target.value) }}
                                                                   aria-label="Оценка"
                                                                   color="primary">
                                                    <ToggleButton value={5} area-label="Отлично" color="success">5</ToggleButton>
                                                    <ToggleButton value={4} area-label="Хорошо" color="success">4</ToggleButton>
                                                    <ToggleButton value={3} area-label="Удовлетворительно" color="warning">3</ToggleButton>
                                                    <ToggleButton value={2} area-label="Неудовлетворительно" color="error">2</ToggleButton>
                                                    <ToggleButton value={6} area-label="Нет данных" color="error">н/д</ToggleButton>
                                                    <ToggleButton value={1} area-label="-" color="info">-</ToggleButton>
                                                </ToggleButtonGroup>
                                            </TableCell>
                                        </TableRow>
                                    })}
                                </TableBody>
                            </Table>

                            <Stack justifyContent="space-between">
                                {
                                    !isSaving
                                        ? <Button variant="contained" onClick={handleSave}>Сохранить</Button>
                                        : <CircularProgress/>
                                }
                            </Stack>
                        </Stack>
                    </Card>
                </Stack>
            </Container>
        </MainLayout>
    );
}