import { Helmet } from 'react-helmet-async';
// @mui
import {
    Card,
    Stack,
    Container,
    Typography,
    TableContainer, CardContent, Button, CircularProgress, Box, Table, TableHead, TableRow, TableCell, TableBody,
} from '@mui/material';

import {useNavigate, useParams} from 'react-router-dom';

// components
import Scrollbar from '../components/scrollbar';
// sections
import { StepByStepTable } from "../sections/@dashboard/attestations";
import {GeneralLayout} from "../layouts/GeneralLayout";
import Label from "../components/label";
import TextField from "@mui/material/TextField";
import React, {useEffect, useState} from "react";
import {useGetNextAttestationNumberQuery, usePostCreateAttestationMutation} from "../store/attestation/attestationApi";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {useGetCuratorsQuery, useGetJobsOfChairQuery, useSaveGroupCuratorsMutation} from "../store/chair/chairApi";
import PageTitle from "../components/PageTitle";

export default function CuratorsPage() {
    const navigate = useNavigate();
    const [isSaving, setIsSaving] = useState(false);
    const {data} = useGetCuratorsQuery();
    const {data: allJobs} = useGetJobsOfChairQuery();
    const [copyOfData, setCopyOfData] = useState([]);
    const [saveCurators] = useSaveGroupCuratorsMutation();
    const [refresh, setRefresh] = useState(false);
    
    useEffect(() => {
        if (data?.data !== null && data !== undefined) {
            setCopyOfData(copyObject(data.data));
        }
    }, [data])

    function formatDate(dateObj) {
        return `${dateObj.getFullYear()}-${withZero(dateObj.getMonth() + 1)}-${withZero(dateObj.getDate())}`;
    }
    
    function applyVisualChanges() {
        setRefresh(!refresh);
    }

    function withZero(num) {
        return num < 10 ? `0${num}` : num;
    }

    function copyObject(obj) {
        return JSON.parse(JSON.stringify(obj));
    }

    const handleSave = async () => {
        setIsSaving(true);
        const request = copyOfData.map((item) => {
            return {
                groupId: item.groupId,
                jobId: item.jobId
            }
        })

        await saveCurators({data: request}).unwrap().then((fulfilled) => {
            if (fulfilled?.success) {
                navigate('/journalmainold');
            } else {
                fulfilled.errors.map((item) => {
                    alert(item);
                    return 1;
                });

                setIsSaving(false);
            }
        });
    };

    function toDateString(date){
        if (date === null || date === undefined)
            return '';

        return new Date(date).toLocaleDateString("ru-RU");
    }

    return (
        <GeneralLayout>
            <PageTitle title={`Кураторы`} />

            <Container>
                <Stack direction="column"
                       padding="120px 0px 0px 100px"
                       spacing={2}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {data === null || data === undefined ? <CircularProgress /> : <></>}
                            Кураторы
                        </Typography>
                    </Stack>

                    <Card>
                        <CardContent>
                            <Stack spacing={2}>
                                <Table size={"small"}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Группа</TableCell>
                                            <TableCell>Куратор</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {copyOfData?.sort((left, right) => {
                                            return left.groupName > right.groupName ? 1 : -1;
                                        }).map((item) => {
                                            return <TableRow>
                                                <TableCell>{item.groupName}</TableCell>
                                                <TableCell>
                                                    { item.fromDifferentChair
                                                    ? <>{item.curatorFullName} с кафедры {item.chairName}</>
                                                    : <Select size={"small"} 
                                                              value={item.jobId}
                                                              onChange={(event) => { item.jobId = event.target.value; applyVisualChanges()}}
                                                              fullWidth>
                                                        {allJobs?.jobs?.map((item) => {
                                                            return <MenuItem value={item.jobId} key={item.jobId}>{item.fullName} (ставка с {toDateString(item.activeFrom)} по {item.activeTo !== null ? toDateString(item.activeTo) : 'настоящий момент'})</MenuItem>
                                                        })}
                                                    </Select>}
                                                </TableCell>
                                            </TableRow>
                                        })}
                                    </TableBody>
                                </Table>

                                <Stack justifyContent="space-between">
                                    {
                                        !isSaving
                                            ? <Button variant="contained"
                                                      disabled={data === null || data === undefined}
                                                      onClick={handleSave}>Сохранить</Button>
                                            : <CircularProgress/>
                                    }
                                </Stack>
                            </Stack>
                        </CardContent>
                    </Card>
                </Stack>
            </Container>
        </GeneralLayout>
    );
}