import {
	Divider,
	MenuList,
	MenuItem,
	Tooltip,
	Button,
	ToggleButtonGroup,
	ToggleButton, IconButton, Snackbar,
	ThemeProvider, Popper, ClickAwayListener
} from "@mui/material"
  import { useState, useEffect } from "react"
  import "./styles.scss"
  import toggleTheme from "../../../../components/toggleTheme"
  import { CheckRounded } from "@mui/icons-material"
import PropTypes from "prop-types";

ValueMenu.propTypes = {
	type: PropTypes.string,
	grade: PropTypes.number,
	gradeType: PropTypes.string,
	valueId: PropTypes.number,
	studentId: PropTypes.number,
	key: PropTypes.number,
	content: PropTypes.element,
	changeHandler: PropTypes.func,
	removeHandler: PropTypes.func,
}
  
  export default function ValueMenu(props) {
	const [anchorEl, setAnchorEl] = useState(null)
	const [grade, setGrade] = useState(5);
    const [gradeType, setGradeType] = useState(null)
	const [open, setOpen] = useState(false)
	const [type, setType] = useState(null)
	const [snackbarOpen, setSnackbarOpen] = useState(false)
	const [snackbarMessage, setSnackbarMessage] = useState("")
  
	useEffect(() => {
		setType(props.type || null)
		
		if (props.grade > 0) {
		  	setGrade(props.grade)
		} else {
		  	setGrade(5)
		}
		
		if (gradeType === null || gradeType === undefined)
			setGradeType(props.gradeType);
	}, [props.type, props.gradeType, props.grade])
  
	// useEffect(() => {}, [anchorEl])
  
	const valueTypes = [
		{ id: 1, title: "Тестирование" },
		{ id: 2, title: "Лабораторная работа" },
		{ id: 3, title: "Контрольная работа" },
		{ id: 4, title: "Расчётно-графическая работа" },
		{ id: 5, title: "Домашняя работа" },
		{ id: 6, title: "Другое" }
	]
  
	// Сохранение оценки
	const handleSubmit = () => {
	  if (!gradeType || !grade) {
		setSnackbarMessage("Выберите оценку и тип оценки")
		setSnackbarOpen(true)
  
		// Закрываем toast через 2 секунды
		setTimeout(() => {
		  setSnackbarOpen(false)
		}, 2000)
		return
	  }
	  setAnchorEl(null)
	  setOpen(false)
	  props.changeHandler(
		0,
		props.studentId,
		props.valueId,
		grade,
		gradeType
	  )
	}
  
	// Открытие popup
	const handleClick = e => {
	  setAnchorEl(e.currentTarget)
	  setTimeout(() => setOpen(prev => !prev))
	}
  
	const handleGradeTypeChange = (id) => {
	  setGradeType(id)
	}
  
	const removeHandler = () => {
	  props.removeHandler?.(0, props.studentId, props.valueId)
	  setOpen(false)
	}
  
	const handleClickAway = e => {
	  setOpen(false)
	}
  
	const handleValue = (e, newVal) => {
	  if (newVal !== null) 
		  setGrade(newVal)
	}
  
	const Header = () => {
	  switch (props.type) {
		case `update`:
		  return <div className="menu-header">Изменить оценку</div>
		default:
		  return <div className="menu-header">Новая оценка</div>
	  }
	}
  
	const menuitem = (text, id, index) => {
	  const icon =
		gradeType === id ? (
		  <CheckRounded sx={{ marginRight: "10px" }} />
		) : (
		  <CheckRounded
			key={index}
			sx={{ color: "transparent", marginRight: "10px" }}
		  />
		)
	  return (
		<MenuItem key={index} onClick={() => handleGradeTypeChange(id)}>
		  {icon} {text}
		</MenuItem>
	  )
	}
  
	const tooltip = props.type === `create` ? "Добавить оценку" : valueTypes.find((item) => item.id === props.gradeType)?.title ?? ''
  
	let control = <></>
	if (props.grade) {
	  switch (props.grade) {
		case 5:
		  control = (
			<IconButton
			  color="success"
			  onClick={handleClick}
			  className="icon-button"
			>
			  {props.content}
			</IconButton>
		  )
		  break
		case 4:
		  control = (
			<IconButton
			  color="success"
			  onClick={handleClick}
			  className="icon-button"
			>
			  {props.content}
			</IconButton>
		  )
		  break
		case 3:
		  control = (
			<IconButton
			  color="warning"
			  onClick={handleClick}
			  className="icon-button"
			>
			  {props.content}
			</IconButton>
		  )
		  break
		case 2:
		  control = (
			<IconButton
			  color="error"
			  onClick={handleClick}
			  className="icon-button"
			>
			  {props.content}
			</IconButton>
		  )
		  break
		default:
		  control = (
			<IconButton onClick={handleClick} className="icon-button">
			  {props.content}
			</IconButton>
		  )
		  break
	  }
	}
  
	return (
	  <>
		<Tooltip title={tooltip} placement="top">
		  {control}
		</Tooltip>
		<ClickAwayListener onClickAway={handleClickAway}>
		  <Popper
			id="placement-popper"
			open={open}
			anchorEl={anchorEl}
			placement="bottom-end"
		  >
			<div className="popup-menu-wrapper">
			  <Header />
			  <Divider />
			  <div className="value">
				<ThemeProvider theme={toggleTheme}>
				  <ToggleButtonGroup
					exclusive
					value={grade}
					onChange={handleValue}
				  >
					<ToggleButton size="small" value={5}>
					  5
					</ToggleButton>
					<ToggleButton size="small" value={4}>
					  4
					</ToggleButton>
					<ToggleButton size="small" value={3}>
					  3
					</ToggleButton>
					<ToggleButton size="small" value={2}>
					  Неуд
					</ToggleButton>
				  </ToggleButtonGroup>
				</ThemeProvider>
			  </div>
			  <div className="types">
				<MenuList>
				  {valueTypes.map((el, index) => {
					return menuitem(el.title, el.id, index)
				  })}
				</MenuList>
			  </div>
			  <Divider />
			  <div className="actions">
				{props.type === `update` ? (
				  <Button
					onClick={removeHandler}
					variant="text"
					sx={{ color: "red" }}
				  >
					Удалить
				  </Button>
				) : (
				  <span>-</span>
				)}
				<Button onClick={handleSubmit}>Ок</Button>
			  </div>
			</div>
		  </Popper>
		</ClickAwayListener>
		<Snackbar
		  open={snackbarOpen}
		  message={snackbarMessage}
		  anchorOrigin={{
			vertical: "bottom",
			horizontal: "right"
		  }}
		/>
	  </>
	)
  }
  