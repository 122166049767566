import React, {useState} from "react";
import PropTypes from "prop-types";
import {Box, Button, Popper, Typography} from "@mui/material";

DisciplineBindingCard.propTypes = {
    totalHours: PropTypes.number,
    filledHours: PropTypes.number,
    groups: PropTypes.array,
    bindingId: PropTypes.number,
    employeeName: PropTypes.string,
    clickAction: PropTypes.func,
    studentsSet: PropTypes.bool,
    hasVirtualGroups: PropTypes.bool
}

export default function DisciplineBindingCard(props) {
    const [openPopup, setOpenPopup] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const roles = localStorage.getItem("roles").split(",");
    const isEmployee = roles.find((item) => item === "employee") !== undefined;

    const handleHover = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
        setOpenPopup(!openPopup);
    };

    return (<div>
        <Button variant="contained"
                color={(props.filledHours >= props.totalHours ? "success" : (props.employeeName === null || props.employeeName === undefined || (isEmployee && (props.employeeName === '' || !props.studentsSet) ? "error" : "info")))}
                fullWidth
                size={"small"}
                onMouseEnter={handleHover}
                onMouseLeave={handleHover}
                onClick={() => props.studentsSet || !isEmployee ? props.clickAction() : alert('Сперва распределите студентов по подгруппам')}>
            {props.groups
                .sort((left, right) => left > right ? 1 : -1)
                .map((item) => {
                    return (
                        <>
                            {item}
                            <br/>
                        </>
                    )
                })}
        </Button><br/>

        <Popper id={props.bindingId.toString()} open={openPopup} anchorEl={anchorEl}>
            <Box sx={{border: 1, p: 1, bgcolor: 'background.paper'}}>
                {props.employeeName ?? "Преподаватель не выбран"}
            </Box>
        </Popper>

        <Typography align="center" fontSize={"small"}>{props.filledHours}/{props.totalHours}</Typography>
    </div>);
}