import { Helmet } from 'react-helmet-async';
// @mui
import {
	Card,
	Stack,
	Container,
	Typography,
	TableContainer,
	CardContent,
	Button,
	CircularProgress,
	Box,
	TableCell,
	TableRow,
	TableHead,
	Table,
	TableBody,
	ButtonGroup, CardHeader, FormControlLabel, InputLabel, FormControl, Autocomplete,
} from '@mui/material';

import {Link, useNavigate, useParams} from 'react-router-dom';

// components
import Scrollbar from '../components/scrollbar';
// sections
import { StepByStepTable } from "../sections/@dashboard/attestations";
import {GeneralLayout} from "../layouts/GeneralLayout";
import Label from "../components/label";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {useGetNextAttestationNumberQuery, usePostCreateAttestationMutation} from "../store/attestation/attestationApi";
import {useGetFacultyCoursesQuery} from "../store/faculty/facultyApi";
import {DriveFileRenameOutline} from "@mui/icons-material";
import PrintIcon from "@mui/icons-material/Print";
import {ArrowRightIcon} from "@mui/x-date-pickers";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import PageTitle from "../components/PageTitle";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import copyObject from "../utils/copyObject";
import simpleSort from "../utils/simpleSort";

export default function ManagePracticesByCourses() {
	const {id} = useParams();
	const navigate = useNavigate();
	const [isSaving, setIsSaving] = useState(false);
	const [scheduleOfCourseIsLoading, setScheduleOfCourseIsLoading] = useState(false);
	const {data} = useGetFacultyCoursesQuery();
	const [courses, setCourses] = useState([]);
	const [groups, setGroups] = useState([]);
	const [selectedCourseFilter, setSelectedCourseFilter] = useState(null);
	const [selectedGroupFilter, setSelectedGroupFilter] = useState(null);

	useEffect(() => {
		if (data?.data === null || data === undefined)
			return;
		
		const dataCopy = copyObject(data?.data);
		const coursesArr = dataCopy.map(x => x.value);
		setCourses(simpleSort(coursesArr));
		
		const tempGroups = [];
		data.data?.forEach((item) => {
			item.groups.forEach((group) => {
				if (tempGroups.find((g) => g === group) === undefined) {
					tempGroups.push(group);
				}
			})
		});
		
		setGroups(simpleSort(tempGroups))
	}, [data]);

	function formatDate(dateObj) {
		return `${dateObj.getFullYear()}-${withZero(dateObj.getMonth() + 1)}-${withZero(dateObj.getDate())}`;
	}

	function withZero(num) {
		return num < 10 ? `0${num}` : num;
	}

	function downloadScheduleOfCourse(courseId) {
		setScheduleOfCourseIsLoading(true);
		fetch(`/Reports/CoursePracticeSchedule?courseId=${courseId}`,
			{
				method: "GET",
				headers: { "semesterid": localStorage.getItem("selectedSemesterId"), "Content-Type": "application/json", "Authorization": `Bearer ${localStorage.getItem('token')}`}
			}).then(response => {
			setScheduleOfCourseIsLoading(false);
			return response.blob();
		}).then(response => {
			const url = window.URL.createObjectURL(
				new Blob([response]),
			);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute(
				'download',
				`Расписание курса.pdf`,
			);

			// Append to html link element page
			document.body.appendChild(link);

			// Start download
			link.click();

			// Clean up and remove the link
			link.parentNode.removeChild(link);
		})
	}
	
	function setFilterByCourse(value) {
		setSelectedCourseFilter(value?.value);
		console.log(`Установлен фильтр по курсу ${value?.value}`)
	}

	return (
		<GeneralLayout>
			<PageTitle title={`Управление практиками`} />

			<Container>
				<Stack direction="column"
					   padding="120px 0px 0px 100px"
					   spacing={2}>
					<Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
						<Typography variant="h4" gutterBottom>
							{data === null || data === undefined ? <CircularProgress /> : <></>}
							Управление практиками
						</Typography>
					</Stack>

					<Card>
						<CardContent>
							<Stack direction={'row'} spacing={2} alignItems={'center'}>
								<Typography variant={'h6'}>Фильтры</Typography>
								<FormControl fullWidth>
									<Autocomplete
										options={courses}
										sx={{width: 400}}
										renderInput={(params) => <TextField {...params} label="Курс" />}
										onChange={(event: any,selectedCourseFilter: string | null) => {
											setSelectedCourseFilter(selectedCourseFilter);
										}}
									/>
								</FormControl>

								<FormControl fullWidth>
									<Autocomplete
										options={groups}
										sx={{width: 400}}
										renderInput={(params) => <TextField {...params} label="Группа" />}
										onChange={(event: any,selectedGroupFilter: string | null) => {
											setSelectedGroupFilter(selectedGroupFilter);
										}}
									/>
								</FormControl>
							</Stack>
						</CardContent>
					</Card>
					
					<Card>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Курс</TableCell>
									<TableCell>Операции</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{data?.data?.filter((item) => (selectedCourseFilter === null || item.value === selectedCourseFilter) 
															&& (selectedGroupFilter === null || item.groups.find((g) => g === selectedGroupFilter) !== undefined))
									.sort((left, right) => left.value > right.value ? 1 : -1)
									.map((item) => {
									return <TableRow>
										<TableCell>{item.value}<br /><small>{item.groups.join(', ')}</small></TableCell>
										<TableCell>
											<ButtonGroup orientation={"vertical"}
														 size={"small"}>
												<Button startIcon={<InsertDriveFileOutlinedIcon />}
														href={`/course-practices/${item.key}`}>Перейти</Button>
												<Button startIcon={<PrintIcon />}
														onClick={() => downloadScheduleOfCourse(item.key)}>Печать расписания курса</Button>
											</ButtonGroup>
										</TableCell>
									</TableRow>
								})}
							</TableBody>
						</Table>
					</Card>
					<br />
				</Stack>
			</Container>
		</GeneralLayout>
	);
}