import { Helmet } from 'react-helmet-async';
// @mui
import {
    Card,
    Stack,
    Container,
    Typography,
    TableContainer,
    CardContent,
    Button,
    CircularProgress,
    Box,
    TableCell,
    TableRow,
    TableHead,
    Table,
    TableBody,
    ButtonGroup,
} from '@mui/material';

import {Link, useNavigate, useParams} from 'react-router-dom';

// components
import Scrollbar from '../components/scrollbar';
// sections
import { StepByStepTable } from "../sections/@dashboard/attestations";
import {GeneralLayout} from "../layouts/GeneralLayout";
import Label from "../components/label";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {useGetNextAttestationNumberQuery, usePostCreateAttestationMutation} from "../store/attestation/attestationApi";
import {useGetFacultyCoursesQuery} from "../store/faculty/facultyApi";
import {DriveFileRenameOutline} from "@mui/icons-material";
import PrintIcon from "@mui/icons-material/Print";
import {ArrowRightIcon} from "@mui/x-date-pickers";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import {useGetCourseGroupsQuery} from "../store/course/courseApi";
import PageTitle from "../components/PageTitle";

export default function CoursePracticesPage() {
    const {id} = useParams();
    const navigate = useNavigate();
    const [isSaving, setIsSaving] = useState(false);
    const {data} = useGetCourseGroupsQuery({courseId: id});


    function formatDate(dateObj) {
        return `${dateObj.getFullYear()}-${withZero(dateObj.getMonth() + 1)}-${withZero(dateObj.getDate())}`;
    }

    function withZero(num) {
        return num < 10 ? `0${num}` : num;
    }

    return (
        <GeneralLayout>
            <PageTitle title={'Управление практиками'} />

            <Container>
                <Stack direction="column"
                       padding="120px 0px 0px 100px"
                       spacing={2}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {data === null || data === undefined ? <CircularProgress /> : <></>}
                            Практики {data?.courseName}
                        </Typography>
                    </Stack>

                    <Card>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Группа</TableCell>
                                    <TableCell width={250}>Операции</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data?.groups?.map((item) => {
                                    return <TableRow>
                                        <TableCell>{item.value}</TableCell>
                                        <TableCell>
                                            <ButtonGroup orientation={"vertical"}
                                                         size={"small"}>
                                                <Button startIcon={<InsertDriveFileOutlinedIcon />}
                                                        href={`/faculty-practices-for-group/${item.key}`}>Перейти к списку практик</Button>
                                            </ButtonGroup>
                                        </TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                    </Card>
                </Stack>
            </Container>
        </GeneralLayout>
    );
}