import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { prepareAuthHeaders } from '../../utils/prepareAuthHeaders';

export const facultyApi = createApi({
    reducerPath: 'facultyApi',
    keepUnusedDataFor: 1,
    baseQuery: fetchBaseQuery({
        baseUrl: '/Faculty',
        prepareHeaders: prepareAuthHeaders,
    }),
    endpoints: (builder) => ({
        getFacultyGroups: builder.query({
            query: () => ({
                url: `/FacultyGroups`,
                method: 'GET',
            }),
        }),
        getFacultyGroupsOfSession: builder.query({
            query: ({sessionId}) => ({
                url: `/FacultyGroupsOfSession`,
                method: 'GET',
                params: {sessionId}
            }),
        }),
        getFacultyGroupsByCourse: builder.query({
            query: ({courseId}) => ({
                url: `/FacultyGroups`,
                method: 'GET',
                params: {courseId}
            }),
        }),
        getFacultyCourses: builder.query({
            query: () => ({
                url: `/FacultyCourses`,
                method: 'GET',
            }),
        }),
        getFaculties: builder.query({
            query: () => ({
                url: `/Faculties`,
                method: 'GET',
            }),
        }),
    }),
});

export const { 
    useGetFacultyGroupsQuery, 
    useGetFacultyGroupsByCourseQuery, 
    useGetFacultyCoursesQuery,
    useGetFacultiesQuery,
    useGetFacultyGroupsOfSessionQuery
} = facultyApi;
