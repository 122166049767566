import { Table, TableHead, TableBody, TableRow, TableCell } from "@mui/material"
import { QueryBuilderRounded } from "@mui/icons-material"
import { Link } from "react-router-dom"

/* *
 * Табличный блок контента дочерней таблицы
 * @prop title Заголовок блока
 * @prop data Данные для отображения
 * */ 
function TableBlock(props) {
  // Рендер компонента
  return (
    <div className="details-block">
      <h2>{props.title}</h2>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Группа</TableCell>
            <TableCell>Время</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data?.map((el, index) => {
            // Вывод данных в таблицу
            const groupData = el

            const element = (
              <TableRow key={index} hover>
                <TableCell>
                  <Link
                    style={{ color: "#2C65D3" }}
                    to={`/journaledit/${groupData.groupFlowTeamId}/0`}
                  >
                      {groupData.groups.map(item => item)}
                  </Link>
                </TableCell>
                <TableCell>
                  <div className="icon-block">
                    <QueryBuilderRounded />
                    {groupData.filledHours} / {groupData.totalHours}
                  </div>
                </TableCell>
              </TableRow>
            )
            return element
          })}
        </TableBody>
      </Table>
    </div>
  )
}

export default TableBlock
