export const prepareAuthHeaders = (headers, { getState }) => {
    let {token} = getState().auth.token
    if (!token) {
        token = localStorage.getItem("token")
    }
    // If we have a token set in state, let's assume that we should be passing it.
    if (token) {
        headers.set('authorization', `Bearer ${token}`);
        headers.set('semesterId', localStorage.getItem("selectedSemesterId"));
    }

    return headers
}
