import {useNavigate, useParams} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import {
    Box,
    Button,
    Card,
    CardContent, CircularProgress,
    Container, Input,
    Stack, Tab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {GeneralLayout} from "../layouts/GeneralLayout";
import {Alert, TabContext, TabList} from "@mui/lab";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {useEffect, useState} from "react";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import {useGetSessionScheduleQuery, usePostSaveSessionScheduleMutation} from "../store/session/sessionApi";
import PageTitle from "../components/PageTitle";

export default function EditSessionSchedulePage() {
    const navigate = useNavigate();
    const {id} = useParams();
    const [refresh, setRefresh] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [canEdit, setCanEdit] = useState(true);
    const {data} = useGetSessionScheduleQuery({sessionId: id});
    const [tabValue, setTabValue] = useState('0');
    const [saveSessionSchedule] = usePostSaveSessionScheduleMutation();
    
    const [copyOfData, setCopyOfData] = useState({
        courseName: '-',
        controlType: 1,
        disciplines: [],
        chairEmployees: []
    });

    useEffect(() => {
        if (data === null || data === undefined)
            return;
        
        setCopyOfData(copyData(data));
        const filled = data.schedule.disciplines?.filter((d) => {
            return d.groups?.filter((g) => {
                return g.eventPlace !== null && g.eventPlace !== undefined && g.eventPlace.length > 0;
            }).length > 0;
        }).length > 0;
        
        console.log(filled);
        console.log(data.schedule.disciplines?.filter((d) => {
            return d.groups?.filter((g) => {
                return g.eventPlace !== null && g.eventPlace !== undefined && g.eventPlace.length > 0;
            }).length > 0;
        }).length > 0);
        
        setCanEdit(data.schedule?.approveStatus === 3 || data?.schedule?.approveStatus === 0);
    }, [data]);
    
    function copyData(source) {
        if (source === null || source === undefined)
            return {
                courseName: '',
                disciplines: [],
                chairEmployees: [],
                controlType: 1,
                sessionId: 0
            };
        
        return {
            courseName: source.schedule.courseName,
            sessionId: id,
            disciplines: source.schedule.disciplines?.map((item) => {
                return copyDiscipline(item);
            }),
            chairEmployees: source.chairEmployees?.map((item) => {
                return copyEmployee(item);
            })
        };
    }
    
    function copyDiscipline(discipline) {
        return {
            controlType: discipline.controlType,
            disciplineId: discipline.disciplineId,
            disciplineName: discipline.disciplineName,
            chairName: discipline.chairName,
            chairId: discipline.chairId,
            groups: discipline.groups.map((group) => {
                return copyGroup(group);
            })
        };
    }
    
    function copyGroup(group) {
        return {
            groupId: group.groupId,
            groupName: `${group.groupName}`,
            eventDate: formatDate(group.eventDate !== null ? new Date(group.eventDate) : null),
            eventTime: `${group.eventTime ?? ''}`,
            eventPlace: `${group.eventPlace ?? ''}`,
            employees: group.employees.map((item) => {
                return copyEmployee(item);
            }),
            extraEmployees: group.extraEmployees.map((item) => {
                return copyExtraEmployee(item);
            }),
        }
    }
    
    function copyExtraEmployee(employee) {
        return {
            extraEmployeeId: employee.extraEmployeeId,
            lastName: employee.lastName,
            firstName: employee.firstName,
            middleName: employee.middleName,
            sessionDisciplineDetailId: employee.sessionDisciplineDetailId
        }
    }
    
    function copyEmployee(employee) {
        // используется костыль для копирования объекта
        return {
            employeeId: employee.employeeId + 1 - 1,
            chairId: employee.chairId + 1 - 1,
            lastName: `${employee.lastName}`,
            firstName: `${employee.firstName}`,
            middleName: `${employee.middleName}`
        }
    }
    
    function addEmployee(discipline, group, employee) {
        if (group.employees.find((item) => item.employeeId === employee.employeeId))
            return;
        
        group.employees.push({
            employeeId: employee.employeeId,
            lastName: employee.lastName,
            firstName: employee.firstName,
            middleName: employee.middleName
        });
        
        applyVisualChanges();
    }
    
    function removeEmployee(groupEmployee, group){
        const index = group.employees.indexOf(groupEmployee);
        
        group.employees.splice(index, 1);
        applyVisualChanges();
    }

    function removeExtraEmployee(groupEmployee, group){
        const index = group.extraEmployees.indexOf(groupEmployee);

        group.extraEmployees.splice(index, 1);
        applyVisualChanges();
    }
    
    function addExtraEmployee(group) {
        group.extraEmployees.push({
            lastName: '',
            firstName: '',
            middleName: '',
            extraEmployeeId: 0
        });
        
        applyVisualChanges();
    }

    const handleSave = async () => {
        setIsSaving(true);
        
        // костыль преобразования пустых дат
        invertEmptyDates();

        await saveSessionSchedule({data: copyOfData}).unwrap().then((fulfilled) => {
            if (fulfilled.isSuccess)
                navigate('/sessions');
            else {
                invertEmptyDates();
                alert(fulfilled.message);
                setIsSaving(false);
            }
        });
    };
    
    function invertEmptyDates() {
        copyOfData.disciplines.map((discipline) => {
            discipline.groups.map((group) => {
                if (group.eventDate === null || group.eventDate === undefined || group.eventDate === '' || group.eventDate === '1970-01-01') {
                    if (group.eventDate === '1970-01-01')
                        group.eventDate = '';
                    else
                        group.eventDate = '1970-01-01';
                }

                return null;
            })
            return null;
        })
        
        applyVisualChanges();
    }

    function applyVisualChanges() {
        setRefresh(!refresh);
    }

    function formatDate(dateObj) {
        if (dateObj === null || dateObj === undefined)
            return '';
        
        return `${dateObj.getFullYear()}-${withZero(dateObj.getMonth() + 1)}-${withZero(dateObj.getDate())}`;
    }

    function withZero(num) {
        return num < 10 ? `0${num}` : num;
    }
    
    function onTabChange(value) {
        setTabValue(value);
    }
    
    function controlTypeToInternalType(controlType) {
        if (controlType === 0)
            return 0;

        if (controlType === 1 || controlType === 2)
            return 1;
        
        return 2;
    }

    return <GeneralLayout>
        <PageTitle title={`Редактировать расписание сессии`} />

        <Container>
            <Stack
                padding="120px 0px 0px 100px"
                direction="column"
                spacing={2}
            >
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Редактировать расписание сессии {
                        copyOfData?.courseName !== null && copyOfData?.courseName !== undefined 
                        ? <>для {copyOfData.courseName}</>
                        : <CircularProgress />
                    }
                    </Typography>
                </Stack>
                
                <Alert color="warning">
                    Будьте внимательны. После заполнения расписания хотя бы для одного предмета, редактирование предметов сессии станет недоступным
                </Alert>

                {canEdit 
                    ? <></>
                    : <Alert color="error">
                            Редактирование недоступно
                    </Alert>
                }
                
                <Card>
                    <CardContent>
                        <Stack spacing={1}>
                            <TabContext value={tabValue}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={(event, value) => { onTabChange(value); }}>
                                        <Tab label="Экзамены" value="0" />
                                        <Tab label="Зачёты" value="1" />
                                        <Tab label="Курсовые работы" value="2" />
                                    </TabList>
                                </Box>
                            </TabContext>
                            
                            {copyOfData?.disciplines?.filter((item) => controlTypeToInternalType(item.controlType) === parseInt(tabValue, 10))
                                .map((item) => {
                                return <>
                                    <Typography variant="h5">{item.disciplineName}</Typography>
                                    <Typography fontSize="small">Кафедра {item.chairName}</Typography>

                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Группа</TableCell>
                                                {tabValue !== '2'
                                                ? <>
                                                    <TableCell>Дата и время</TableCell>
                                                    <TableCell>Место проведения</TableCell>
                                                </>
                                                : <></>}
                                                <TableCell>Преподаватели</TableCell>
                                                <TableCell>Дополнительные преподаватели (не являются постоянными
                                                    преподавателями ВУЗа)</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {item.groups?.map((group) => {
                                                return <>
                                                    <TableRow>
                                                        <TableCell>{group.groupName}</TableCell>
                                                        {tabValue !== '2'
                                                            ? <TableCell>
                                                                <Stack spacing={1}>
                                                                    <TextField size="small"
                                                                               type="Date"
                                                                               value={group.eventDate}
                                                                               onChange={(event) => {
                                                                                   if (!canEdit)
                                                                                       return;
                                                                                   
                                                                                   group.eventDate = event.target.value;
                                                                                   applyVisualChanges()
                                                                               }}/>

                                                                    <TextField size="small"
                                                                               type="Time"
                                                                               value={group.eventTime}
                                                                               onChange={(event) => {
                                                                                   if (!canEdit)
                                                                                       return;

                                                                                   group.eventTime = event.target.value;
                                                                                   applyVisualChanges()
                                                                               }}/>
                                                                </Stack>
                                                            </TableCell>
                                                        : <></>}
                                                        {tabValue !== '2'
                                                        ? <TableCell>
                                                            <TextField size="small"
                                                                       placeholder="Место проведения"
                                                                       value={group.eventPlace}
                                                                       onChange={(event) => {
                                                                           if (!canEdit)
                                                                               return;

                                                                           group.eventPlace = event.target.value;
                                                                           applyVisualChanges()
                                                                       }}/>
                                                        </TableCell>
                                                        : <></>}
                                                        <TableCell>
                                                            <Stack spacing={1}>
                                                                {group?.employees?.map((groupEmployee) => {
                                                                    return <Stack direction="row" alignItems="center">
                                                                        <Typography
                                                                            fontSize="small">{groupEmployee.lastName} {groupEmployee.firstName} {groupEmployee.middleName}</Typography>
                                                                        <Button size="small" onClick={() => {
                                                                            if (!canEdit)
                                                                                return;

                                                                            removeEmployee(groupEmployee, group)
                                                                        }}><RemoveCircleIcon/></Button>
                                                                    </Stack>
                                                                })}

                                                                {group.employees.length > 0 ? <><br/></> : <></>}
                                                            </Stack>

                                                            <Select size="small"
                                                                    onChange={(event) => {
                                                                        if (!canEdit)
                                                                            return;

                                                                        addEmployee(item, group, event.target.value);
                                                                        applyVisualChanges();
                                                                    }}
                                                                    fullWidth>
                                                                {copyOfData?.chairEmployees?.filter((e) => {
                                                                    return e.chairId === item.chairId;
                                                                })?.map((e) => {
                                                                    return <MenuItem key={e.employeeId}
                                                                                     value={e}>{`${e.lastName} ${e.firstName} ${e.middleName}`}</MenuItem>
                                                                })}
                                                            </Select>
                                                        </TableCell>
                                                        <TableCell>
                                                            {group?.extraEmployees?.map((groupEmployee) => {
                                                                return <>
                                                                    <Stack spacing={2}>
                                                                        <TextField placeholder="Фамилия" size="small"
                                                                                   value={groupEmployee.lastName}
                                                                                   onChange={(event) => {
                                                                                       if (!canEdit)
                                                                                           return;

                                                                                       groupEmployee.lastName = event.target.value;
                                                                                       applyVisualChanges()
                                                                                   }}/>

                                                                        <TextField placeholder="Имя" size="small"
                                                                                   value={groupEmployee.firstName}
                                                                                   onChange={(event) => {
                                                                                       if (!canEdit)
                                                                                           return;

                                                                                       groupEmployee.firstName = event.target.value;
                                                                                       applyVisualChanges()
                                                                                   }}/>

                                                                        <TextField placeholder="Отчество (не обяз.)"
                                                                                   size="small"
                                                                                   value={groupEmployee.middleName}
                                                                                   onChange={(event) => {
                                                                                       if (!canEdit)
                                                                                           return;

                                                                                       groupEmployee.middleName = event.target.value;
                                                                                       applyVisualChanges()
                                                                                   }}/>

                                                                        <Button size="small" color="error"
                                                                                onClick={() => {
                                                                                    if (!canEdit)
                                                                                        return;

                                                                                    removeExtraEmployee(groupEmployee, group)
                                                                                }}><RemoveCircleIcon/> Удалить</Button>
                                                                        <hr/>
                                                                        <br/>
                                                                    </Stack>
                                                                </>
                                                            })}

                                                            <Button variant="outlined"
                                                                    onClick={() => {
                                                                        if (!canEdit)
                                                                            return;

                                                                        addExtraEmployee(group)
                                                            }} fullWidth>Добавить</Button>
                                                        </TableCell>
                                                    </TableRow>
                                                </>
                                            })}
                                        </TableBody>
                                    </Table>
                                    <br/>
                                </>
                            })}

                            {canEdit
                            ? <Stack alignItems="center" fullWidth>
                                    {
                                        !isSaving
                                            ? <Button color="primary" variant="contained" onClick={() => {handleSave()}} fullWidth>Сохранить</Button>
                                            : <>Сохранение <CircularProgress /></>
                                    }
                                </Stack>
                            : <></>}
                        </Stack>
                    </CardContent>
                </Card>
            </Stack>
        </Container>
    </GeneralLayout>
}