import {useNavigate, useParams} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import {
    Box,
    Button,
    Card,
    CardContent, CircularProgress,
    Container, Input,
    Stack, Tab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {GeneralLayout} from "../layouts/GeneralLayout";
import {Alert, TabContext, TabList} from "@mui/lab";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {useEffect, useState} from "react";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import {
    useGetSessionRetakesScheduleQuery,
    useGetSessionScheduleQuery, usePostSaveSessionRetakesScheduleMutation,
    usePostSaveSessionScheduleMutation
} from "../store/session/sessionApi";
import PageTitle from "../components/PageTitle";

export default function EditSessionRetakesPage() {
    const navigate = useNavigate();
    const {id} = useParams();
    const [refresh, setRefresh] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const {data} = useGetSessionRetakesScheduleQuery({sessionId: id});
    const [tabValue, setTabValue] = useState('0');
    const [saveSessionRetakesSchedule] = usePostSaveSessionRetakesScheduleMutation();

    const [copyOfData, setCopyOfData] = useState({
        courseName: '-',
        controlType: 1,
        disciplines: []
    });

    useEffect(() => {
        if (data === null || data === undefined)
            return;

        setCopyOfData(copyData(data));
    }, [data]);

    function copyData(source) {
        if (source === null || source === undefined)
            return {
                courseName: '',
                disciplines: [],
                controlType: 1,
                sessionId: 0
            };

        return {
            courseName: source.schedule.courseName,
            sessionId: id,
            disciplines: source.schedule.disciplines?.map((item) => {
                return copyDiscipline(item);
            })
        };
    }

    function copyDiscipline(discipline) {
        return {
            controlType: discipline.controlType,
            disciplineId: discipline.disciplineId,
            disciplineName: discipline.disciplineName,
            chairName: discipline.chairName,
            chairId: discipline.chairId,
            groups: discipline.groups.map((group) => {
                return copyGroup(group);
            })
        };
    }

    function copyGroup(group) {
        return {
            canEdit: group.canEdit,
            groupId: group.groupId,
            groupName: `${group.groupName}`,
            eventDate: formatDate(group.eventDate !== null ? new Date(group.eventDate) : new Date()),
            eventTime: `${group.eventTime ?? ''}`,
            eventPlace: `${group.eventPlace ?? ''}`,
            employees: group.employees
        }
    }

    const handleSave = async () => {
        setIsSaving(true);

        await saveSessionRetakesSchedule({data: copyOfData}).unwrap().then((fulfilled) => {
            if (fulfilled.isSuccess)
                navigate('/sessions');
            else {
                alert(fulfilled.message);
                setIsSaving(false);
            }
        });
    };

    function onTabChange(value) {
        setTabValue(value);
    }

    function applyVisualChanges() {
        setRefresh(!refresh);
    }

    function controlTypeToInternalType(controlType) {
        if (controlType === 0)
            return 0;

        if (controlType === 1 || controlType === 2)
            return 1;

        return 2;
    }

    function formatDate(dateObj) {
        return `${dateObj.getFullYear()}-${withZero(dateObj.getMonth() + 1)}-${withZero(dateObj.getDate())}`;
    }

    function withZero(num) {
        return num < 10 ? `0${num}` : num;
    }

    return <GeneralLayout>
        <PageTitle title={`Редактировать расписание пересдач`} />

        <Container>
            <Stack
                padding="120px 0px 0px 100px"
                direction="column"
                spacing={2}
            >
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Редактировать расписание пересдач {
                        copyOfData?.courseName !== null && copyOfData?.courseName !== undefined
                            ? <>для {copyOfData.courseName}</>
                            : <CircularProgress/>
                    }
                    </Typography>
                </Stack>

                <Card>
                    <CardContent>
                        <Stack spacing={1}>
                            <TabContext value={tabValue}>
                                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                                    <TabList onChange={(event, value) => {
                                        onTabChange(value);
                                    }}>
                                        <Tab label="Экзамены" value="0"/>
                                        <Tab label="Зачёты" value="1"/>
                                    </TabList>
                                </Box>
                            </TabContext>

                            {copyOfData?.disciplines?.filter((item) => controlTypeToInternalType(item.controlType) === parseInt(tabValue, 10))
                                .map((item) => {
                                    return <>
                                        <Typography variant="h5">{item.disciplineName}</Typography>
                                        <Typography fontSize="small">Кафедра {item.chairName}</Typography>

                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Группа</TableCell>
                                                    <TableCell>Дата и время</TableCell>
                                                    <TableCell>Место проведения</TableCell>
                                                    <TableCell>Преподаватели</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {item.groups?.map((group) => {
                                                    return <>
                                                        <TableRow>
                                                            <TableCell>{group.groupName}</TableCell>
                                                            <TableCell>
                                                                <Stack spacing={1}>
                                                                    <TextField size="small"
                                                                               type="Date"
                                                                               disabled={!group.canEdit}
                                                                               value={group.eventDate}
                                                                               onChange={(event) => {
                                                                                   group.eventDate = event.target.value;
                                                                                   applyVisualChanges()
                                                                               }}/>

                                                                    <TextField size="small"
                                                                               type="Time"
                                                                               value={group.eventTime}
                                                                               disabled={!group.canEdit}
                                                                               onChange={(event) => {
                                                                                   group.eventTime = event.target.value;
                                                                                   applyVisualChanges()
                                                                               }}/>
                                                                </Stack>
                                                            </TableCell>
                                                            <TableCell>
                                                                <TextField size="small"
                                                                           placeholder="Место проведения"
                                                                           value={group.eventPlace}
                                                                           disabled={!group.canEdit}
                                                                           onChange={(event) => {
                                                                               group.eventPlace = event.target.value;
                                                                               applyVisualChanges()
                                                                           }}/>
                                                            </TableCell>
                                                            <TableCell>
                                                                {group.employees.map((employee) => {
                                                                    return <Typography>{employee}</Typography>
                                                                })}
                                                            </TableCell>
                                                        </TableRow>
                                                    </>
                                                })}
                                            </TableBody>
                                        </Table>
                                        <br/>
                                    </>
                                })}

                            <Stack alignItems="center" fullWidth>
                                {
                                    !isSaving
                                        ? <Button color="primary" variant="contained" onClick={() => {handleSave()}} fullWidth>Сохранить</Button>
                                        : <>Сохранение <CircularProgress /></>
                                }
                            </Stack>
                        </Stack>
                    </CardContent>
                </Card>
            </Stack>
        </Container>
    </GeneralLayout>
}