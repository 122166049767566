import {Box, Button, CircularProgress, Grid, Input, Link, Paper, Stack, Typography} from "@mui/material";
import {PrintOutlined, Save} from "@mui/icons-material";
import PropTypes from "prop-types";
import {styled} from "@mui/material/styles";
import {
    useAddEmployeeToStatementMutation,
    useGetAvailableEmployeesForSessionStatementQuery,
    useGetStatementInfoQuery, useRemoveEmployeeFromStatementMutation,
    useUpdateStatementInfoMutation
} from "../../../store/statement/statementApi";
import {useEffect, useState} from "react";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {useListEmployeesQuery} from "../../../store/employee/employeeApi";

StatementInfoBlock.propTypes = {
    statement: PropTypes.object,
    printEnabled: PropTypes.bool,
    adminMode: PropTypes.bool
}

export default function StatementInfoBlock(props) {
    const [statementIsLoading, setStatementIsLoading] = useState(false);
    const [adminChangesAreSaving, setAdminChangesAreSaving] = useState(false);
    const [copyOfStatement, setCopyOfStatement] = useState(null);
    const [eventDate, setEventDate] = useState(null);
    const [closingDate, setClosingDate] = useState(null);
    const [deadline, setDeadline] = useState(null);
    const [updateStatementInfo] = useUpdateStatementInfoMutation();
    const [statementId, setStatementId] = useState(null);
    const {data: employeesData} = useGetAvailableEmployeesForSessionStatementQuery({statementId});
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [addEmployeeToStatement] = useAddEmployeeToStatementMutation();
    const [removeEmployeeFromStatement] = useRemoveEmployeeFromStatementMutation();
    const Item = styled(Paper)(({theme}) => ({
        backgroundColor: '#F9FAFB',
        ...theme.typography.body2,
        textAlign: 'start',
    }));
    
    useEffect(() => {
        setCopyOfStatement(copyObject(props.statement));
        setEventDate(props.statement?.eventDate);
        setClosingDate(props.statement?.closingDate);
        setDeadline(props.statement?.deadline);
        setStatementId(props.statement?.statementId);
        console.log('render info')
        
        console.log(props.statement)
    }, [props.statement])

    function copyObject(obj) {
        if (obj === null || obj === undefined)
            return null;
        
        return JSON.parse(JSON.stringify(obj));
    }

    function formatDate(dateObj) {
        return `${dateObj.getFullYear()}-${withZero(dateObj.getMonth() + 1)}-${withZero(dateObj.getDate())}`;
    }

    function toDateString(date) {
        return new Date(date).toLocaleDateString("ru-RU");
    }

    function withZero(num) {
        return num < 10 ? `0${num}` : num;
    }

    function nameOfControlType(statementType) {
        switch (statementType) {
            case 0:
                return 'экзаменационная';
            case 1:
                return 'зачётная';
            case 2:
                return 'зачётная';
            case 3:
                return 'курсовая работа';
            case 4:
                return 'курсовой проект';
            default:
                return '';
        }
    }

    function downloadStatement() {
        setStatementIsLoading(true);
        fetch(`/Reports/SessionStatement?statementId=${copyOfStatement.statementId}`,
            {
                method: "GET",
                headers: {
                    "semesterid": localStorage.getItem("selectedSemesterId"),
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem('token')}`
                }
            }).then(response => {
            setStatementIsLoading(false);
            return response.blob();
        }).then(response => {
            const url = window.URL.createObjectURL(
                new Blob([response]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `Ведомость.pdf`,
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
        })
    }

    function applyVisualChanges() {
        setRefresh(!refresh);
    }
    
    async function addEmployee() {
        setAdminChangesAreSaving(true);
        const request = {
            statementId: copyOfStatement.statementId,
            employeeId: parseInt(selectedEmployee.key, 10)
        }

        await addEmployeeToStatement({ data: request }).unwrap()
            .then((fulfilled) => {
                if (fulfilled?.success) {
                    copyOfStatement.employees.push(selectedEmployee);
                    setAdminChangesAreSaving(false);
                } else {
                    fulfilled.errors.map((item) => {
                        alert(item);
                        return 1;
                    });

                    setAdminChangesAreSaving(false);
                }
            }).catch((reason) => {
                alert("Ошибка");
                setAdminChangesAreSaving(false);
            });
    }

    async function removeEmployee(employeeId) {
        setAdminChangesAreSaving(true);
        const request= {
            statementId: copyOfStatement.statementId,
            employeeId: parseInt(employeeId, 10)
        }

        await removeEmployeeFromStatement({ data: request }).unwrap()
            .then((fulfilled) => {
                if (fulfilled?.success) {
                    const employee = copyOfStatement.employees.find((item) => parseInt(item.key, 10) === parseInt(employeeId, 10))
                    copyOfStatement.employees.splice(copyOfStatement.employees.indexOf(employee), 1);
                    setAdminChangesAreSaving(false);
                } else {
                    fulfilled.errors.map((item) => {
                        alert(item);
                        return 1;
                    });

                    setAdminChangesAreSaving(false);
                }
            }).catch((reason) => {
                alert("Ошибка");
                setAdminChangesAreSaving(false);
            });
    }

    function adminDateField(obj, propName) {
        return !props.adminMode
            ? <>{obj !== null && obj !== undefined && obj[propName] !== null && obj[propName] !== undefined ? toDateString(obj[propName]) : '-'}</>
            : <>
                <TextField size={'small'}
                       defaultValue={obj !== null && obj !== undefined ? obj[propName] : null} 
                       disabled={adminChangesAreSaving}
                       variant={'outlined'}
                       onInput={(event) => { obj[propName] = event.target.value;  }}/>
            </>
    }
    
    async function saveAdminChanges() {
        setAdminChangesAreSaving(true);
        const request = {
            statementId: copyOfStatement.statementId,
            eventDate: copyOfStatement.eventDate,
            creationDate: copyOfStatement.creationDate,
            deadline: copyOfStatement.deadline,
            closingDate: copyOfStatement.closingDate
        }

        await updateStatementInfo({ data: request }).unwrap()
            .then((fulfilled) => {
                if (fulfilled?.success) {
                    setAdminChangesAreSaving(false);
                } else {
                    fulfilled.errors.map((item) => {
                        alert(item);
                        return 1;
                    });

                    setAdminChangesAreSaving(false);
                }
            }).catch((reason) => {
                alert("Ошибка");
                setAdminChangesAreSaving(false);
            });
    }

    return (
        <>
            <Stack direction="row" spacing={4}>
                <Typography variant="h5">Информация о ведомости</Typography>
                {props.printEnabled
                    ? (<Button variant="outlined"
                               color="primary"
                               startIcon={statementIsLoading ? <CircularProgress size={15}/> : <PrintOutlined/>}
                               onClick={() => downloadStatement()}>Печать</Button>)
                    : (<></>)}
            </Stack>

            <br/>
            <Box sx={{flexGrow: 1}}>
                <Grid container spacing={2}>
                    <Grid item md={6}>
                        <Item>
                            <Stack spacing={3}>
                                <Typography><strong>Тип
                                    ведомости: </strong>{nameOfControlType(copyOfStatement?.controlType)}</Typography>
                                <Typography><strong>Дисциплина: </strong>{copyOfStatement?.disciplineName}</Typography>
                                <Typography><strong>Группа: </strong>{copyOfStatement?.groupName}</Typography>
                                <Typography>
                                    <strong>Преподаватели: </strong>{!props.adminMode
                                        ? copyOfStatement?.employees?.map((item) => item.value).join(', ')
                                        : copyOfStatement?.employees?.map((item) => {
                                        return <><Typography>{item.value} <IconButton size={'small'} 
                                                                                      onClick={() => removeEmployee(item.key)}
                                                                                      color={'error'}><RemoveCircleIcon /></IconButton></Typography></>
                                    })}
                                    {copyOfStatement?.extraEmployees?.length > 0 ? `, ${copyOfStatement?.extraEmployees?.join(', ')}` : ''}
                                </Typography>
                                {props.adminMode
                                ? <>
                                    <Typography><strong>Добавить преподавателя:</strong></Typography> 
                                    <Select size={'small'} 
                                            value={selectedEmployee}
                                            onChange={(event) => setSelectedEmployee(event.target.value)}>
                                        {employeesData?.employees?.map((item) => {
                                            return <MenuItem key={item.key} value={item}>{item.value}</MenuItem>
                                        })}
                                    </Select>
                                        
                                    <Button startIcon={adminChangesAreSaving ? <CircularProgress size={15}/> : <Save />}
                                            disabled={adminChangesAreSaving}
                                            variant={'contained'}
                                            onClick={() => addEmployee()}>Добавить преподавателя</Button>
                                </>
                                : <></>}
                            </Stack>
                        </Item>
                    </Grid>
                    <Grid item md={6}>
                        <Item>
                            <Stack spacing={3}>
                                <Typography><strong>Статус: </strong>{copyOfStatement?.closingDate != null ? 'Ведомость закрыта' : 'Ведомость открыта'}
                                </Typography>
                                <Typography><strong>Сканированный
                                    документ: </strong> {props.printEnabled && copyOfStatement?.scannedDocumentId != null && copyOfStatement?.scannedDocumentId > 0
                                    ? (<Link>Просмотреть</Link>)
                                    : (<span>-</span>)}
                                </Typography>
                                <Typography><strong>Дата
                                    проведения: </strong>{adminDateField(copyOfStatement, 'eventDate')}</Typography>
                                <Typography><strong>Дата
                                    выдачи: </strong>{adminDateField(copyOfStatement, 'creationDate')}
                                </Typography>
                                <Typography><strong>Рекомендуемая крайняя дата
                                    закрытия: </strong>{adminDateField(copyOfStatement, 'deadline')}</Typography>
                                <Typography><strong>Дата
                                    закрытия: </strong>{adminDateField(copyOfStatement, 'closingDate')}
                                </Typography>
                                {props.adminMode
                                ? <Button color={'primary'}
                                          startIcon={adminChangesAreSaving ? <CircularProgress size={15}/> : <Save />}
                                          onClick={() => saveAdminChanges()}
                                          variant={'contained'}
                                          disabled={adminChangesAreSaving}>Сохранить изменения</Button>
                                : <></>}
                            </Stack>
                        </Item>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}