import { Helmet } from 'react-helmet-async';
// @mui
import {
    Card,
    Stack,
    Container,
    Typography,
    TableContainer, CardContent, Button, CircularProgress, Box, TableRow, TableHead, Table, TableCell, TableBody,
} from '@mui/material';

import {useNavigate, useParams} from 'react-router-dom';

// components
import Scrollbar from '../components/scrollbar';
// sections
import { StepByStepTable } from "../sections/@dashboard/attestations";
import {GeneralLayout} from "../layouts/GeneralLayout";
import Label from "../components/label";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {useGetNextAttestationNumberQuery, usePostCreateAttestationMutation} from "../store/attestation/attestationApi";
import {Check, Remove, RemoveDone, RemoveFromQueue, RemoveRedEye, RemoveSharp, Watch} from "@mui/icons-material";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import {
    useApproveSessionStopRequestMutation,
    useSessionGeneralInfoQuery,
    useSessionStopRequestDetailsQuery
} from "../store/session/sessionApi";
import PageTitle from "../components/PageTitle";

export default function SessionStopRequestApprovalPage() {
    const {requestId, sessionId} = useParams();
    console.log('request ID',requestId)
    const navigate = useNavigate();
    const [isSaving, setIsSaving] = useState(false);
    const {data} = useSessionStopRequestDetailsQuery({requestId});
    const [request, setRequest] = useState(null);
    const {data: disciplinesData} = useSessionGeneralInfoQuery({sessionId});
    const [approveSessionStopRequest] = useApproveSessionStopRequestMutation();

    useEffect(() => {
        if (data === null || data === undefined)
            return;
        
        if (data.requests.length === 0)
            return;
        
        const request = data.requests[0];
        setRequest(request);
    }, [data])

    function formatDate(dateObj) {
        return `${dateObj.getFullYear()}-${withZero(dateObj.getMonth() + 1)}-${withZero(dateObj.getDate())}`;
    }

    function withZero(num) {
        return num < 10 ? `0${num}` : num;
    }

    const handleSave = async (approved) => {
        setIsSaving(true);

        await approveSessionStopRequest({data: {sessionStopRequestId: requestId, approved}}).unwrap().then((fulfilled) => {
            if (fulfilled?.success) {
                navigate('/session-stop-request-list');
            } else {
                fulfilled.errors.map((item) => {
                    alert(item);
                    return 1;
                });

                setIsSaving(false);
            }
        });
    };

    return (
        <GeneralLayout>
            <PageTitle title={`Заявка на прерывание сессии`} />

            <Container>
                <Stack direction="column"
                       padding="120px 0px 0px 100px"
                       spacing={2}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {data === null || data === undefined ? <CircularProgress /> : <></>}
                            Заявка на прерывание сессии
                        </Typography>
                    </Stack>

                    <Card>
                        <CardContent>
                            <Stack spacing={2}>
                                <Typography><strong>Факультет: </strong>{request?.facultyName}</Typography>
                                <Typography><strong>Курс: </strong>{request?.courseName}</Typography>
                                <Typography><strong>Дата/время создания заявки: </strong>{request?.requestDate}</Typography>
                                <Typography><strong>Текст заявки: </strong>{request?.fullText}</Typography>

                                {disciplinesData === null || disciplinesData === undefined
                                ? <CircularProgress />
                                : <></>}
                                <Table size={"small"}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Дисциплина</TableCell>
                                            <TableCell align={"right"}>Список ведомостей</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell colSpan={2} align={"center"}>
                                                <strong>Экзамены</strong>
                                            </TableCell>
                                        </TableRow>
                                        {disciplinesData?.disciplines?.filter((item) => item.controlType === 0).length === 0
                                            ? <TableRow><TableCell colSpan={2} align={"center"}>Нет записей</TableCell></TableRow>
                                            : <></>}
                                        {disciplinesData?.disciplines?.filter((item) => item.controlType === 0)
                                            .map((discipline) => {
                                                return <TableRow>
                                                    <TableCell>{discipline.disciplineName}</TableCell>
                                                    <TableCell align={"right"}>
                                                        {discipline.statements.length === 0
                                                            ? 'Нет ведомостей'
                                                            : ''}
                                                        {discipline.statements.map((statement) => {
                                                            return <><Button href={`/statementresults/${statement.statementId}`} target={'_blank'}>Ведомость №{statement.statementNumber}</Button> ({statement.closed? 'Закрытая' : 'Открытая'}) <br /></>
                                                        })}
                                                    </TableCell>
                                                </TableRow>
                                            })}

                                        <TableRow>
                                            <TableCell colSpan={2} align={"center"}>
                                                <strong>Зачёты</strong>
                                            </TableCell>
                                        </TableRow>
                                        {disciplinesData?.disciplines?.filter((item) => item.controlType === 1 || item.controlType === 2).length === 0
                                            ? <TableRow><TableCell colSpan={2} align={"center"}>Нет записей</TableCell></TableRow>
                                            : <></>}
                                        {disciplinesData?.disciplines?.filter((item) => item.controlType === 1 || item.controlType === 2)
                                            .map((discipline) => {
                                                return <TableRow>
                                                    <TableCell>{discipline.disciplineName} {discipline.controlType === 2 ? '(Дифф.)' : ''}</TableCell>
                                                    <TableCell align={"right"}>
                                                        {discipline.statements.length === 0
                                                            ? 'Нет ведомостей'
                                                            : ''}
                                                        {discipline.statements.map((statement) => {
                                                            return <><Button  href={`/statementresults/${statement.statementId}`} target={'_blank'}>Ведомость №{statement.statementNumber}</Button> ({statement.closed? 'Закрытая' : 'Открытая'}) <br /></>
                                                        })}
                                                    </TableCell>
                                                </TableRow>
                                            })}

                                        <TableRow>
                                            <TableCell colSpan={2} align={"center"}>
                                                <strong>Курсовые работы</strong>
                                            </TableCell>
                                        </TableRow>
                                        {disciplinesData?.disciplines?.filter((item) => item.controlType === 3 || item.controlType === 4).length === 0
                                            ? <TableRow><TableCell colSpan={2} align={"center"}>Нет записей</TableCell></TableRow>
                                            : <></>}
                                        {disciplinesData?.disciplines?.filter((item) => item.controlType === 3 || item.controlType === 4)
                                            .map((discipline) => {
                                                return <TableRow>
                                                    <TableCell>{discipline.disciplineName}</TableCell>
                                                    <TableCell align={"right"}>
                                                        {discipline.statements.length === 0
                                                            ? 'Нет ведомостей'
                                                            : ''}
                                                        {discipline.statements.map((statement) => {
                                                            return <><Button  href={`/statementresults/${statement.statementId}`} target={'_blank'}>Ведомость №{statement.statementNumber}</Button> ({statement.closed? 'Закрытая' : 'Открытая'}) <br /></>
                                                        })}
                                                    </TableCell>
                                                </TableRow>
                                            })}
                                    </TableBody>
                                </Table>

                                <Stack justifyContent="space-between">
                                    {
                                        !isSaving
                                            ? <Stack direction={"row"} spacing={1}>
                                                <Button variant="contained"
                                                        startIcon={<Check />}
                                                        color={"success"}
                                                        style={{"color": "white"}}
                                                        disabled={data === null || data === undefined}
                                                        onClick={() => { handleSave(true) }}>Одобрить</Button>

                                                <Button variant="contained"
                                                        startIcon={<Remove />}
                                                        disabled={data === null || data === undefined}
                                                        color={"error"}
                                                        onClick={() => { handleSave(false) }}>Отклонить</Button>
                                            </Stack>
                                            : <CircularProgress/>
                                    }
                                </Stack>
                            </Stack>
                        </CardContent>
                    </Card>
                </Stack>
            </Container>
        </GeneralLayout>
    );
}