export default function getApproveStatusName(approveStatus) {
    switch (approveStatus) {
        case 0:
            return 'Не отправлено на утверждение в УМУ';
        case 1:
            return 'Отправлено на утверждение в УМУ';
        case 2:
            return 'Отправлено на утверждение в диспетчерскую';
        case 3:
            return 'Возвращено для внесения изменений по решению диспетчерской';
        case 4:
            return 'Запрос на прерывание отправлен в УМУ';
        case 5:
            return 'Запрос на прерывание отправлен в диспетчерскую';
        case 6:
            return 'Запрос на прерывание отправлен в УМУ и диспетчерскую';
        case 7:
            return 'Возвращено для внесения изменений по решению УМУ';
        case 100:
            return 'Утверждено';
        default:
            return 'Неизвестный статус';
    }
}