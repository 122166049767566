import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import {Provider} from "react-redux";
import './index.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import App from './App';
import { AppRoutes } from './AppRoutes';
import * as serviceWorker from './serviceWorker';
import {store} from "./store/store";
import {env} from "process";


const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter(AppRoutes);
const target = // 'https://new-att.kubsau.ru/';
    // 'https://localhost:44434'
    env.ASPNETCORE_HTTPS_PORT ? `https://localhost:${env.ASPNETCORE_HTTPS_PORT}` :
        env.ASPNETCORE_URLS ? env.ASPNETCORE_URLS.split(';')[0] : 'http://localhost:11905';

localStorage.setItem('baseUrl', target);

// root.render(
//     <React.StrictMode>
//         <App />
//     </React.StrictMode>
// );

root.render(
  <HelmetProvider>
    <App>
        <Provider store={store}>
            <RouterProvider router={router} />
        </Provider>
    </App>
  </HelmetProvider>
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();
