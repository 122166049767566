import {Helmet} from "react-helmet-async";
import {useEffect, useState} from "react";
import {
    Box, Button,
    Card,
    Container,
    Stack,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tabs,
    Typography,
    Checkbox, Hidden, CircularProgress
} from "@mui/material";
import {TabContext, TabPanel} from "@mui/lab";
import {
    useGetSubgroupsOfGroupFlowBindingQuery,
    usePostSetStudentsBySubgroupsMutation
} from "../store/journal/journalApi";
import PropTypes from "prop-types";
import {Navigate, useNavigate, useParams} from "react-router-dom";
import {GeneralLayout} from "../layouts/GeneralLayout";
import MainLayout from "./main";
import PageTitle from "../components/PageTitle";
import {sub} from "date-fns";

EditSubGroupsPage.propTypes = {
    bindingId: PropTypes.number,
}

export default function EditSubGroupsPage(props) {
    const navigate = useNavigate();
    const [refresh, setRefresh] = useState(false);
    const {courseid, disciplineid} = useParams();
    const {data} = useGetSubgroupsOfGroupFlowBindingQuery({courseId: courseid, disciplineId: disciplineid});
    const [setStudentsBySubgroups, setStudentsBySubgroupsResult] = usePostSetStudentsBySubgroupsMutation();
    const [changedStudents, setChangedStudents] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    
    const [tabIndex, setTabIndex] = useState('0');
    
    useEffect(() => {
        const std = [];
        if (data === undefined || data === null)
            return;
        
        data.subGroups.map((sg, index) => {
            sg.students.map((st) => {
                std.push({
                    studentId: st.studentId,
                    accepted: st.accepted,
                    groupFlowTeamId: sg.groupFlowTeamId
                });
                return null;
            });
            return null;
        });
        
        setChangedStudents(std);
    }, [data]);
    
    const handleChange = (event, newValue) => {
        setTabIndex(newValue);
    };
    
    const handleSaveResult = function(result) {
        navigate('/journalmain',
                {
                    replace: true,
                });
    }
    
    const handleSave = async () => { 
        setIsSaving(true);

        await setStudentsBySubgroups({studentsData: changedStudents}).unwrap().then((fulfilled) => handleSaveResult(fulfilled));
    };
    
    function handleStudentCheckChange(event, value, student, subGroup) {
        console.log(subGroup)
        student.accepted = value;
        if (value)
            student.groupFlowTeamId = subGroup.groupFlowTeamId;
        else 
            student.groupFlowTeamId = null;
        
        applyVisualChanges();
    }

    function applyVisualChanges(){
        setRefresh(!refresh);
    }
    
    function checkAllStudents() {
        const hasUnchecked = changedStudents.find((item) => !item.accepted) !== undefined;
        
        for (let i = 0; i < changedStudents.length; i+=1) {
            changedStudents[i].accepted = hasUnchecked;
        }
        
        applyVisualChanges();
    }
    
    function inverseStudents() {
        for (let i = 0; i < changedStudents.length; i+=1) {
            changedStudents[i].accepted = !changedStudents[i].accepted;
        }

        applyVisualChanges();
    }
    
    function isStudentChecked(student, subGroup) {
        const item = changedStudents.find(x => x.studentId === student.studentId);
        return (item?.accepted ?? false)
            && item?.groupFlowTeamId === subGroup.groupFlowTeamId;
    }

    return (
        <MainLayout>
            <PageTitle title={`Редактирование подгрупп`} />

            <Container>
                <main>
                    <Stack
                        padding="60px 0px 0px 0px"
                        direction="column"
                        spacing={2}
                    >
                        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                            <Typography variant="h4" gutterBottom>
                                Редактирование подгрупп - {data?.disciplineName ?? `...`}
                            </Typography>
                            {data?.disciplineName == null
                                ? <CircularProgress />
                                : <></>}
                        </Stack>

                        <TabContext value={tabIndex}>
                            <Box sx={{width: '100%'}}>
                                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                                    <Tabs value={tabIndex} onChange={(event, newValue) => handleChange(event, newValue)}>
                                        {data?.subGroups.map((item, index) => {
                                            return (
                                                <Tab key={index} label={item.groupFlowTeamName} value={index.toString()} />
                                            )
                                        })}
                                    </Tabs>
                                </Box>
                                {data?.subGroups.map((item, index) => {
                                    return (
                                        <TabPanel key={index} value={index.toString()}>
                                            <Card>
                                                <Table size={"small"}>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>#</TableCell>
                                                            <TableCell>ФИО</TableCell>
                                                            <TableCell align="center">
                                                                Добавить в подгруппу
                                                                <br />
                                                                <Button onClick={() => checkAllStudents()}>Отметить всех</Button>
                                                                <Button onClick={() => inverseStudents()}>Инверсия</Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {item.students
                                                            .map((student, studentIndex) => {
                                                                return (
                                                                    <TableRow key={student.studentId}>
                                                                        <TableCell>{studentIndex+1}</TableCell>
                                                                        <TableCell><b>{student.lastName}</b><br />{student.firstName} {student.middleName}</TableCell>
                                                                        <TableCell align="center">
                                                                            {student.attachedEmployee != null && student.attachedEmployee.length > 0 && !student.isCurrentEmployee
                                                                                ? <>{"Преподаватель — "} {student.attachedEmployee}</>
                                                                                : <Checkbox onChange={(event, value) => handleStudentCheckChange(event, value, changedStudents.find(x => x.studentId === student.studentId), item)}
                                                                                            name={`students[${studentIndex}].accepted`}
                                                                                            checked={isStudentChecked(student, item)}
                                                                                />}
                                                                            <input type="hidden" name={`students[${studentIndex}].studentId`}
                                                                                   value={student.studentId.toString()}/>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )
                                                            })}
                                                    </TableBody>
                                                </Table>

                                                <br />

                                                <Stack alignItems="center" fullWidth>
                                                    {
                                                        !isSaving
                                                            ? <Button color="primary" variant="contained" onClick={handleSave} fullWidth>Сохранить</Button>
                                                            : <>Сохранение <CircularProgress /></>
                                                    }
                                                </Stack>
                                            </Card>
                                        </TabPanel>
                                    )
                                })}
                            </Box>
                        </TabContext>
                    </Stack>
                </main>
            </Container>
        </MainLayout>
    );
}