import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { prepareAuthHeaders } from '../../utils/prepareAuthHeaders';

export const dashboardApi = createApi({
  reducerPath: 'dashboardApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/Dashboard',
    prepareHeaders: prepareAuthHeaders,
  }),
  endpoints: (builder) => ({
    getScheduleForCurrentWeek: builder.query({
      query: () => ({
        url: `/ScheduleForCurrentWeek`,
        method: 'GET',
      }),
    }),
    getDisciplinesOfEmployee: builder.query({
      query: () => ({
        url: `/DisciplinesOfEmployee`,
        method: 'GET',
      }),
    }),
    getCurrentWeekNumber: builder.query({
      query: () => ({
        url: `/CurrentWeekNumber`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetScheduleForCurrentWeekQuery, useGetDisciplinesOfEmployeeQuery, useGetCurrentWeekNumberQuery } =
  dashboardApi;
