import {Helmet} from "react-helmet-async";
import {useEffect, useState} from "react";
import {
    Backdrop,
    Box, Button,
    Card,
    CardContent, CircularProgress,
    Container,
    Stack,
    Tab,
    Table, TableBody, TableCell,
    TableContainer,
    TableHead, TableRow,
    Typography
} from "@mui/material";
import {Alert, AlertTitle, TabContext, TabList, TabPanel} from "@mui/lab";
import EmployeeStatementsPage from "./EmployeeStatementsPage";
import PlainStatementsList from "../sections/@dashboard/sessions/PlainStatementsList";
import {GeneralLayout} from "../layouts/GeneralLayout";
import {useGetMyStatementsQuery} from "../store/session/sessionApi";
import {useCloseSessionStatementMutation, useGetEmployeeStatementsQuery} from "../store/statement/statementApi";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import {Link} from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import {useCloseStatementMutation} from "../store/practice/practiceApi";
import MainLayout from "./main";
import PageTitle from "../components/PageTitle";

MyStatementsPage.propTypes = {}

export default function MyStatementsPage() {
    const [tabIndex, setTabIndex] = useState("1");
    const [isLoading, setIsLoading] = useState(false);
    const [statementCategories, setStatementCategories] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const dataQuery = useGetEmployeeStatementsQuery();
    const {data} = dataQuery;
    const [closeStatementRequest] = useCloseSessionStatementMutation();

    useEffect(() => {
    }, [data, tabIndex]);

    async function closeStatement(statementId) {
        setIsSaving(true);
        await closeStatementRequest({
            data: {
                statementId
            }
        }).unwrap().then((fulfilled) => {
            if (fulfilled?.success) {
                dataQuery.refetch()
                setIsSaving(false);
            } else {
                console.log(fulfilled.errors);
                fulfilled.errors.map((item) => {
                    alert(item);
                    return 1;
                });

                setIsSaving(false);
            }
        });
    }

    function downloadStatement(statementId) {
        setIsLoading(true);
        fetch(`/Reports/SessionStatement?statementId=${statementId}`,
            {
                method: "GET",
                headers: {
                    "semesterid": localStorage.getItem("selectedSemesterId"),
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem('token')}`
                }
            }).then(response => {
            setIsLoading(false);
            return response.blob();
        }).then(response => {
            const url = window.URL.createObjectURL(
                new Blob([response]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `Ведомость.pdf`,
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
        })
    }

    function downloadStatementDraft(statementId) {
        setIsLoading(true);
        fetch(`/Reports/StatementDraft?statementId=${statementId}`,
            {
                method: "GET",
                headers: {
                    "semesterid": localStorage.getItem("selectedSemesterId"),
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem('token')}`
                }
            }).then(response => {
            setIsLoading(false);
            return response.blob();
        }).then(response => {
            const url = window.URL.createObjectURL(
                new Blob([response]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `Справочная ведомость.pdf`,
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
        })
    }

    function handleTabChange(event, val) {
        setTabIndex(val);
    }

    function toDateString(date) {
        return new Date(date).toLocaleDateString("ru-RU");
    }

    function statementTypeNameByNumber(number) {
        switch (number) {
            case 0:
                return 'Основная';
            case 1:
                return 'Дополнительная';
            case 2:
                return 'Комиссионная';
            case 10:
                return 'Внеплановая';
            default:
                return 'Неизвестный тип ведомости';
        }
    }

    function controlTypeToTabIndex(controlType) {
        console.log('cast ', controlType)
        switch (controlType) {
            case 0:
                return 0;
            case 1:
                return 1;
            case 2:
                return 1;
            case 3:
                return 3;
            case 4:
                return 3;
            default:
                return 0;
        }
    }

    return (<MainLayout>
        <PageTitle title={`Ведомости`} />

        <main>
            <Container>
                <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={isSaving || isLoading}
                >
                    <CircularProgress color="inherit"/>
                </Backdrop>
                <Stack direction="column"
                       padding="30px 0px 0px 0px"
                       spacing={0}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            Ведомости
                        </Typography>
                    </Stack>

                    <Alert color="info" variant="outlined">
                        <AlertTitle align="center">Внимание!<br/>(из Кодекса корпоративной этики Кубанского
                            ГАУ)</AlertTitle>
                        <p>Коррупцией считается злоупотребление служебным положением, дача взятки, получение взятки,
                            злоупотребление полномочиями, коммерческий подкуп либо иное незаконное использование
                            физическим
                            лицом своего должностного положения вопреки законным интересам общества и государства в
                            целях
                            получения выгоды в виде денег, ценностей, иного имущества или услуг имущественного
                            характера,
                            иных имущественных прав для себя или для третьих лиц либо незаконное предоставление такой
                            выгоды
                            указанному лицу другими физическими лицами, а также совершение указанных деяний от имени или
                            в
                            интересах юридического лица. К коррупционным деяниям относятся следующие преступления:
                            злоупотребление должностными полномочиями (ст. 285 УК РФ), дача взятки (ст. 291 УК РФ),
                            получение взятки (ст. 290 УК РФ), посредничество во взяточничестве (ст. 291.1 УК РФ), мелкое
                            взяточничество (ст. 291.2 УК РФ), злоупотребление полномочиями (ст. 201 УК РФ), коммерческий
                            подкуп (ст. 204 УК РФ), а также иные деяния, попадающие под понятия «коррупция», указанное
                            выше.
                            За указанные преступления предусмотрено наказание вплоть до лишения свободы на срок до
                            пятнадцати лет со штрафом в размере до семидесятикратной суммы взятки и с лишением права
                            занимать определенные должности или заниматься определенной деятельностью на срок до
                            пятнадцати
                            лет.</p>
                    </Alert>

                    <br/>

                    <Card>
                        <CardContent>
                            <TabContext value={tabIndex}>
                                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                                    <TabList onChange={(event, val) => handleTabChange(event, val)}>
                                        <Tab label="Зачеты" value="1"/>
                                        <Tab label="Курсовые работы" value="3"/>
                                        <Tab label="Экзамены" value="0"/>
                                    </TabList>
                                </Box>
                            </TabContext>

                            {data?.disciplines?.filter((item) => {
                                return controlTypeToTabIndex(item.controlTypeId) === parseInt(tabIndex, 10)
                                    && item.details.find((d) => d.statementId !== null) !== undefined
                            }).map((discipline) => {
                                return <>
                                    <br/>
                                    <Typography variant="h6">{discipline.disciplineName}</Typography>
                                    <Typography fontSize="small">Кафедра {discipline.chairName}</Typography>
                                    <br/>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Тип сдачи</TableCell>
                                                <TableCell>Группа/студент</TableCell>
                                                <TableCell>№ ведомости</TableCell>
                                                <TableCell>Операции</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {discipline?.details?.filter((statement) => statement.statementId !== null)
                                                .map((statement) => {
                                                    return (
                                                        <TableRow key={statement.statementId}
                                                                  style={statement.closingDate !== null ? {background: "#5AD72A33"} : {}}>
                                                            <TableCell>
                                                                {statementTypeNameByNumber(statement.numberInOrder)}
                                                                <br/>
                                                                {statement.closingDate !== null ? `Дата закрытия: ${toDateString(statement.closingDate)}` : ''}
                                                            </TableCell>
                                                            <TableCell>{statement.groupName}</TableCell>
                                                            <TableCell>{statement.statementName}</TableCell>
                                                            <TableCell>
                                                                <Button startIcon={statement.closingDate != null
                                                                    ? (<InsertDriveFileOutlinedIcon/>)
                                                                    : (<BorderColorOutlinedIcon/>)}
                                                                        href={statement.closingDate === null
                                                                            ? `/employeeeditstatement/${statement.statementId}`
                                                                            : `/statementresults/${statement.statementId}`}>
                                                                    {
                                                                        statement.closingDate != null
                                                                            ? 'Подробности'
                                                                            : 'Редактировать'
                                                                    }
                                                                </Button>
                                                                {statement.canClose
                                                                    ? <>
                                                                        <br/>
                                                                        <Button startIcon={<CheckIcon/>}
                                                                                onClick={() => {
                                                                                    closeStatement(statement.statementId)
                                                                                }}>Закрыть ведомость</Button>
                                                                    </>
                                                                    : <></>}
                                                                <br/>
                                                                <Button startIcon={<LocalPrintshopOutlinedIcon/>}
                                                                        onClick={() => downloadStatement(statement.statementId)}>Печать</Button>
                                                                <br/>
                                                                <Button startIcon={<ReceiptLongOutlinedIcon/>}
                                                                        onClick={() => downloadStatementDraft(statement.statementId)}>Справочная
                                                                    вед.</Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>


                                </>
                            })}

                            {data === null
                            || data?.disciplines?.length === 0
                            || data?.disciplines === null
                            || data?.disciplines?.filter((item) => {
                                return controlTypeToTabIndex(item.controlTypeId) === parseInt(tabIndex, 10)
                                    && item.details.find((d) => d.statementId !== null) !== undefined
                            }).length === 0
                                ? <><br/><Typography align={"center"}>Нет ведомостей</Typography></>
                                : <></>}
                        </CardContent>
                    </Card>

                    <br/>
                </Stack>
            </Container>
        </main>
    </MainLayout>);
}