import {useNavigate, useParams} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import {
    Button,
    Card,
    CardContent, CircularProgress,
    Container,
    Grid,
    Stack,
    Typography
} from "@mui/material";
import {GeneralLayout} from "../layouts/GeneralLayout";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {useGetStatementInfoQuery, usePostEditStatementNumberMutation} from "../store/statement/statementApi";
import PageTitle from "../components/PageTitle";

export default function EditStatementPage() {
    const {id} = useParams();
    const navigate = useNavigate();
    const [isSaving, setIsSaving] = useState(false);
    const [statementNumber, setStatementNumber] = useState(``);
    const [editStatementNumber] = usePostEditStatementNumberMutation();
    const {data} = useGetStatementInfoQuery({statementId: id});
    
    useEffect(() => {
        if (data === null || data === undefined)
            return;
        
        setStatementNumber(data.statement.statementNumber);
    }, [data]);

    const handleSave = async () => {
        setIsSaving(true);

        await editStatementNumber({statementId: id, statementNumber}).unwrap().then((fulfilled) => {
            if (fulfilled.isSuccess) {
                navigate(`/managestatements/${data?.statement.sessionId}`);
            }
            else {
                alert(fulfilled.message);
                setIsSaving(false);
            }
        });
    };

    function formatDate(dateObj) {
        return `${dateObj.getFullYear()}-${withZero(dateObj.getMonth() + 1)}-${withZero(dateObj.getDate())}`;
    }

    function toDateString(date){
        return new Date(date).toLocaleDateString("ru-RU");
    }

    function withZero(num) {
        return num < 10 ? `0${num}` : num;
    }
    
    function nameOfControlType(statementType) {
        switch (statementType)
        {
            case 0: return 'экзаменационная';
            case 1: return 'зачётная';
            case 2: return 'зачётная';
            case 3: return 'курсовая работа';
            case 4: return 'курсовой проект';
            default: return '';
        }
    }

    return <GeneralLayout>
        <PageTitle title={`Редактировать ведомость`} />

        <Container>
            <Stack
                padding="120px 0px 0px 100px"
                direction="column"
                spacing={2}
            >
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Редактировать ведомость {data?.statement?.statementNumber}
                    </Typography>
                </Stack>

                <Card>
                    <CardContent>
                        <Stack spacing={5}>
                            <Grid container rowGap={1}>
                                <Grid xs={2}>
                                    <Typography>
                                        <strong>Тип ведомости</strong>
                                    </Typography>
                                </Grid>
                                <Grid xs={6}>
                                    <Typography>
                                        {nameOfControlType(data?.statement.controlType)}
                                    </Typography>
                                </Grid>
                                <Grid xs={2}>
                                    <Typography><strong>Статус</strong></Typography>
                                </Grid>
                                <Grid xs={2}>
                                    <Typography>{data?.statement.closingDate === null ? 'Открыта' : 'Закрыта'}</Typography>
                                </Grid>
                                
                                {/* ------------ */}
                                
                                <Grid xs={2}>
                                    <Typography>
                                        <strong>Дисциплина</strong>
                                    </Typography>
                                </Grid>
                                <Grid xs={6}>
                                    <Typography>
                                        {data?.statement.disciplineName}
                                    </Typography>
                                </Grid>
                                <Grid xs={2}>
                                    <Typography><strong>Дата выдачи</strong></Typography>
                                </Grid>
                                <Grid xs={2}>
                                    <Typography>{toDateString(new Date(data?.statement.creationDate))}</Typography>
                                </Grid>
    
                                {/* ------------ */}
    
                                <Grid xs={2}>
                                    <Typography>
                                        <strong>Группа</strong>
                                    </Typography>
                                </Grid>
                                <Grid xs={6}>
                                    <Typography>
                                        {data?.statement.groupName}
                                    </Typography>
                                </Grid>
                                <Grid xs={2}>
                                    <Typography><strong>Рекомендуемая крайняя дата закрытия</strong></Typography>
                                </Grid>
                                <Grid xs={2}>
                                    <Typography>{data?.statement.deadline !== null ? toDateString(new Date(data?.statement.deadline)) : ''}</Typography>
                                </Grid>
    
                                {/* ------------ */}
    
                                <Grid xs={2}>
                                    <Typography>
                                        <strong>Преподаватели</strong>
                                    </Typography>
                                </Grid>
                                <Grid xs={6}>
                                    <Typography>
                                        {data?.statement.employees.join(', ')}
                                        {data?.statement.extraEmployees.length > 0 ? `, ${data?.statement.extraEmployees.join(', ')}` : ''}
                                    </Typography>
                                </Grid>
                                <Grid xs={4}>
                                    <></>
                                </Grid>
    
                                {/* ------------ */}
    
                                <Grid xs={2}>
                                    <Typography>
                                        <strong>Номер ведомости</strong>
                                    </Typography>
                                </Grid>
                                <Grid xs={6}>
                                    <TextField size="small"
                                               placeholder="Номер ведомости"
                                               onChange={(event) => { setStatementNumber(event.target.value) }}
                                               value={statementNumber}/>
                                </Grid>
                                <Grid xs={4}>
                                    <></>
                                </Grid>
                            </Grid>
    
                            <Stack justifyContent="space-between">
                                {
                                    !isSaving
                                        ? <Button variant="contained" onClick={handleSave}>Сохранить</Button>
                                        : <CircularProgress/>
                                }
                            </Stack>
                        </Stack>
                    </CardContent>
                </Card>
            </Stack>
        </Container>
    </GeneralLayout>
}