import {Helmet} from 'react-helmet-async';
// @mui
import {
    Card,
    Stack,
    Container,
    Typography,
    TableContainer, CardContent, Button, CircularProgress, Box, CardHeader, Grid, RadioGroup, Radio, Checkbox,
} from '@mui/material';

import {useNavigate, useParams} from 'react-router-dom';

// components
import Scrollbar from '../components/scrollbar';
// sections
import {StepByStepTable} from "../sections/@dashboard/attestations";
import {GeneralLayout} from "../layouts/GeneralLayout";
import Label from "../components/label";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {useGetNextAttestationNumberQuery, usePostCreateAttestationMutation} from "../store/attestation/attestationApi";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import AddIcon from "@mui/icons-material/Add";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import {CheckBox, Save} from "@mui/icons-material";
import {useGetFacultyGroupsQuery} from "../store/faculty/facultyApi";
import {useGetStudentsInGroupQuery} from "../store/group/groupApi";
import {useAddCertificateOfSkippingMutation} from "../store/document/documentApi";
import PageTitle from "../components/PageTitle";

export default function CreateCertificateOfSkippingPage() {
    const {id} = useParams();
    const navigate = useNavigate();
    const [isSaving, setIsSaving] = useState(false);
    const {data: facultyGroups} = useGetFacultyGroupsQuery();
    const [selectedGroupId, setSelectedGroupId] = useState(null);
    const {data: students, status: studentsStatus} = useGetStudentsInGroupQuery({
        groupId: parseInt(selectedGroupId, 10),
        atMoment: formatDate(new Date())
    })
    const [copyOfGroups, setCopyOfGroups] = useState([]);
    const [copyOfStudents, setCopyOfStudents] = useState([]);
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [selectedStudentId, setSelectedStudentId] = useState(null);
    const [studentSelectionMode, setStudentSelectionMode] = useState("radio");
    const [selectedDocumentType, setSelectedDocumentType] = useState(0);
    const [dateFrom, setDateFrom] = useState(null);
    const [pairFrom, setPairFrom] = useState(1);
    const [dateTo, setDateTo] = useState(null);
    const [pairTo, setPairTo] = useState(1);
    const [saveCertificate] = useAddCertificateOfSkippingMutation();
    const [refresh, setRefresh] = useState(false);
    const [comment, setComment] = useState('');
    
    useEffect(() => {
        if (facultyGroups !== null && facultyGroups !== undefined && copyOfGroups.length === 0) {
            setCopyOfGroups(copyObject(facultyGroups.data))
        }

        if (students !== null && students !== undefined) {
            setCopyOfStudents(copyObject(students.data))
        }
    }, [facultyGroups, students]);

    function copyObject(obj) {
        return JSON.parse(JSON.stringify(obj));
    }

    function isStudentSelected(student) {
        return selectedStudents.find((item) => item === student.key) !== undefined;
    }

    function formatDate(dateObj) {
        return `${dateObj.getFullYear()}-${withZero(dateObj.getMonth() + 1)}-${withZero(dateObj.getDate())}`;
    }

    function withZero(num) {
        return num < 10 ? `0${num}` : num;
    }

    const handleSave = async () => {
        setIsSaving(true);

        const request = {
            documentType: selectedDocumentType,
            dateFrom,
            dateTo,
            pairFrom,
            pairTo,
            comment,
            students: studentSelectionMode === "radio" ? [ selectedStudentId ] : selectedStudents
        };
        await saveCertificate({data: request}).unwrap().then((fulfilled) => {
            if (fulfilled?.success) {
                navigate('/certificates-of-skipping');
            } else {
                fulfilled.errors.map((item) => {
                    alert(item);
                    return 1;
                });

                setIsSaving(false);
            }
        });
    };

    function handleSelectedDocumentTypeChange(value) {
        setSelectedDocumentType(value);
        if (value === 1 || value === 3) {
            setStudentSelectionMode("checkbox");
        } else {
            setStudentSelectionMode("radio");
        }
        
        selectedStudents.splice(0);
        setSelectedStudentId(null);
    }

    function handleGroupChange(value) {
        setSelectedGroupId(value);
        selectedStudents.splice(0);
        setSelectedStudentId(null);
    }

    function checkStudent(studentId, checked) {
        console.log(`check student ${studentId} ${checked}`)
        if (checked) {
            if (selectedStudents.find((item) => item === studentId) === undefined) {
                // add
                selectedStudents.push(studentId);
                console.log('add new student')
            }
        } else {
            const index = selectedStudents.indexOf(studentId);
            if (index >= 0) {
                selectedStudents.splice(index, 1);
                console.log('remove  student')
            }
        }
        
        applyVisualChanges();
    }
    
    function applyVisualChanges() {
        setRefresh(!refresh);
    }
    
    function canSave() {
        return (selectedStudents.length > 0 || selectedStudentId !== null && selectedStudentId !== undefined && selectedStudentId > 0)
            && (dateFrom === dateTo ? pairFrom <= pairTo : dateFrom < dateTo);
    }
    
    return (
        <GeneralLayout>
            <PageTitle title={'Регистрация документа'} />

            <Container>
                <Stack direction="column"
                       padding="120px 0px 0px 100px"
                       spacing={2}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {facultyGroups === null || facultyGroups === undefined ? <CircularProgress/> : <></>}
                            Регистрация документа
                        </Typography>
                    </Stack>

                    <Grid container columnGap={2}>
                        <Grid xs={8}>
                            <Card>
                                <CardContent>
                                    <Stack spacing={2} fullWidth>
                                        <Typography variant={"h5"}>Информация о документе</Typography>
                                        <Grid container spacing={2} columnGap={2}>
                                            <Grid xs={3} textAlign={"right"}>
                                                <strong>Тип документа</strong>
                                            </Grid>
                                            <Grid xs={8}>
                                                <Select size={"small"}
                                                        value={selectedDocumentType}
                                                        onChange={(event) => handleSelectedDocumentTypeChange(event.target.value)}
                                                        fullWidth>
                                                    <MenuItem value={0}>Справка</MenuItem>
                                                    <MenuItem value={1}>Распоряжение деканата</MenuItem>
                                                    <MenuItem value={2}>Заявление</MenuItem>
                                                    <MenuItem value={3}>График дежурств</MenuItem>
                                                </Select>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={2} columnGap={2}>
                                            <Grid xs={3} textAlign={"right"}>
                                                <strong>Тип пропуска</strong>
                                            </Grid>
                                            <Grid xs={8}>
                                                <TextField value={'Пропуск по болезни'} disabled size={"small"} fullWidth/>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={2} columnGap={2}>
                                            <Grid xs={3} textAlign={"right"}>
                                                <strong>Дата с</strong>
                                            </Grid>
                                            <Grid xs={2}>
                                                <TextField size={"small"}
                                                           value={dateFrom}
                                                           onInput={(event) => setDateFrom(event.target.value)}
                                                           type={"date"}/>
                                            </Grid>

                                            <Grid xs={3} textAlign={"right"}>
                                                <strong>Номер пары</strong>
                                            </Grid>
                                            <Grid xs={2}>
                                                <Select size={"small"} 
                                                        value={pairFrom}
                                                        onChange={(event) => setPairFrom(event.target.value)} 
                                                        fullWidth>
                                                    <MenuItem value={1}>1</MenuItem>
                                                    <MenuItem value={2}>2</MenuItem>
                                                    <MenuItem value={3}>3</MenuItem>
                                                    <MenuItem value={4}>4</MenuItem>
                                                    <MenuItem value={5}>5</MenuItem>
                                                    <MenuItem value={6}>6</MenuItem>
                                                </Select>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={2} columnGap={2}>
                                            <Grid xs={3} textAlign={"right"}>
                                                <strong>Дата по</strong>
                                            </Grid>
                                            <Grid xs={2}>
                                                <TextField size={"small"}
                                                           value={dateTo}
                                                           onInput={(event) => setDateTo(event.target.value)}
                                                           type={"date"}/>
                                            </Grid>

                                            <Grid xs={3} textAlign={"right"}>
                                                <strong>Номер пары</strong>
                                            </Grid>
                                            <Grid xs={2}>
                                                <Select size={"small"}
                                                        value={pairTo}
                                                        onChange={(event) => setPairTo(event.target.value)} 
                                                        fullWidth>
                                                    <MenuItem value={1}>1</MenuItem>
                                                    <MenuItem value={2}>2</MenuItem>
                                                    <MenuItem value={3}>3</MenuItem>
                                                    <MenuItem value={4}>4</MenuItem>
                                                    <MenuItem value={5}>5</MenuItem>
                                                    <MenuItem value={6}>6</MenuItem>
                                                </Select>
                                            </Grid>
                                        </Grid>
                                        
                                        <Grid container spacing={2} columnGap={2}>
                                            <Grid xs={3} textAlign={"right"}>
                                                <strong>Комментарий</strong>
                                            </Grid>
                                            <Grid xs={8}>
                                                <TextField size={"small"} 
                                                           maxRows={3}
                                                           minRows={3}
                                                           value={comment}
                                                           onInput={(event) => setComment(event.target.value)}
                                                           multiline
                                                           fullWidth/>
                                            </Grid>
                                        </Grid>
                                    </Stack>

                                    <br/>
                                    <Stack justifyContent="space-between">
                                        <Button variant="contained"
                                                startIcon={isSaving ? <CircularProgress size={15} /> : <Save />}
                                                disabled={!canSave() || isSaving}
                                                onClick={handleSave}>Сохранить</Button>
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid xs={3.8} fullWidth>
                            <Card fullWidth>
                                <CardContent>
                                    <Stack spacing={2}>
                                        <Typography variant={"h5"}>Студенты</Typography>

                                        <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                            <strong>Группа</strong>
                                            <Select size={"small"}
                                                    value={selectedGroupId}
                                                    onChange={(event) => handleGroupChange(event.target.value)}
                                                    fullWidth>
                                                {copyOfGroups?.sort((left, right) => left.value > right.value ? 1 : -1)
                                                    .map((item) => {
                                                        return <MenuItem key={item.value}
                                                                         value={item.key}>{item.value}</MenuItem>
                                                    })}
                                            </Select>
                                        </Stack>

                                        {studentsStatus === 'pending'
                                            ? <CircularProgress/>
                                            : <></>}

                                        {studentSelectionMode === 'radio'
                                            ? <FormControl size={"small"}>
                                                <RadioGroup size={"smallest"}
                                                            value={selectedStudentId}
                                                            onChange={(event) => setSelectedStudentId(event.target.value)}>
                                                    {copyOfStudents?.sort((left, right) => left.value > right.value ? 1 : -1)
                                                        .map((item) => {
                                                            return <FormControlLabel
                                                                control={<Radio value={item.key} size={"small"}/>}
                                                                label={<small>{item.value}</small>}/>
                                                        })}
                                                </RadioGroup>
                                            </FormControl>
                                            : <FormControl size={"small"}>
                                                {copyOfStudents?.sort((left, right) => left.value > right.value ? 1 : -1)
                                                    .map((item) => {
                                                        return <FormControlLabel
                                                            control={<Checkbox
                                                                size={"small"}
                                                                onChange={(event, checked) => checkStudent(item.key, checked)}
                                                                value={item.key}
                                                                checked={isStudentSelected(item)}/>}
                                                            label={<small>{item.value}</small>}/>
                                                    })}
                                            </FormControl>}
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Stack>
            </Container>
        </GeneralLayout>
    );
}