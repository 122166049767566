import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { prepareAuthHeaders } from '../../utils/prepareAuthHeaders';

export const groupApi = createApi({
  reducerPath: 'groupApi',
  keepUnusedDataFor: 1,
  baseQuery: fetchBaseQuery({
    baseUrl: '/Group',
    prepareHeaders: prepareAuthHeaders,
  }),
  endpoints: (builder) => ({
    getStudentsInGroup: builder.query({
      query: ({ groupId, atMoment }) => ({
        url: `/StudentsInGroup`,
        method: 'GET',
        params: { groupId, atMoment }
      }),
    }),
  }),
});

export const { useGetStudentsInGroupQuery } =
    groupApi;
