import { Helmet } from 'react-helmet-async';
// @mui
import {
    Card,
    Stack,
    Container,
    Typography,
    TableContainer,
    CardContent,
    Button,
    CircularProgress,
    Box,
    ButtonGroup,
    Grid,
    Table,
    TableHead,
    TableRow,
    TableCell, TableBody,
} from '@mui/material';

import {useNavigate, useParams} from 'react-router-dom';

// components
import Scrollbar from '../components/scrollbar';
// sections
import {GeneralLayout} from "../layouts/GeneralLayout";
import TextField from "@mui/material/TextField";
import React, {useEffect, useState} from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import AddIcon from "@mui/icons-material/Add";
import {RemoveSharp} from "@mui/icons-material";
import {
    useGetGroupsOfEmployeeDisciplineQuery,
    useGetLaboratoryWorkDisciplinesOfEmployeeQuery
} from "../store/employee/employeeApi";
import {
    useGetStudentsDetailsOfGroupFlowBindingQuery,
    useGetVirtualGroupsQuery,
    useSaveVirtualGroupMutation
} from "../store/journal/journalApi";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import MainLayout from "./main";
import PageTitle from "../components/PageTitle";

export default function CreateVirtualGroupPage() {
    const navigate = useNavigate();
    const [refresh, setRefresh] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [selectedDiscipline, setSelectedDiscipline] = useState(null);
    const [selectedDisciplineId, setSelectedDisciplineId] = useState(null); // костыль
    const {data} = useGetLaboratoryWorkDisciplinesOfEmployeeQuery();
    const {data: virtualGroupsData} = useGetVirtualGroupsQuery();
    const [disciplines, setDisciplines] = useState([]);
    const [groups, setGroups] = useState([]);
    const [students, setStudents] = useState([]);
    const [studentFilter, setStudentFilter] = useState('');
    const [selectedGroupId, setSelectedGroupId] = useState(null);
    const [selectedGroup, setSelectedGroup] = useState(null); // костыль
    const {data: groupData} = useGetGroupsOfEmployeeDisciplineQuery({disciplineId: selectedDisciplineId});
    const {data: studentsData} = useGetStudentsDetailsOfGroupFlowBindingQuery({disciplineId: selectedDisciplineId, groupId: selectedGroupId});
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [newGroupName, setNewGroupName] = useState('');
    const [saveVirtualGroupRequest] = useSaveVirtualGroupMutation();
    
    useEffect(() => {
        if (data === null || data === undefined)
            return;
        
        if (disciplines.length === 0) {
            setDisciplines(copyObject(data?.disciplines));
        }
        
        if (groups.length === 0 && groupData?.data !== null && groupData?.data !== undefined) {
            setGroups(copyObject(groupData.data));
        }
        
        if (studentsData !== null && studentsData !== undefined) {
            const copyOfStudents = copyObject(studentsData.data);
            for (let i = 0; i < copyOfStudents.length; i+=1) {
                copyOfStudents[i].accepted = false;    
            }
            
            setStudents(copyOfStudents);
        }
    }, [data, groupData, studentsData]);
    
    function applyVisualChanges() {
        setRefresh(!refresh);
    }
    
    function addStudentToSubGroup(student) {
        const existingStudent = selectedStudents.find((item) => item.studentId === student.studentId);
        if (existingStudent === undefined) {
            selectedStudents.push({
                studentId: student.studentId,
                fullName: student.fullName,
                groupName: selectedGroup.value
            });
        } else {
            selectedStudents.splice(selectedStudents.indexOf(existingStudent), 1);
        }
        
        student.accepted = !student.accepted;
        
        applyVisualChanges();
    }
    
    function removeStudentFromSubgroup(student) {
        students.find((item) => item.studentId === student.studentId).accepted = false;
        selectedStudents.splice(selectedStudents.indexOf(student), 1);
        
        applyVisualChanges();
    }

    function formatDate(dateObj) {
        return `${dateObj.getFullYear()}-${withZero(dateObj.getMonth() + 1)}-${withZero(dateObj.getDate())}`;
    }

    function copyObject(obj) {
        if (obj === null || obj === undefined) {
            return {};
        }
        
        return JSON.parse(JSON.stringify(obj));
    }

    function withZero(num) {
        return num < 10 ? `0${num}` : num;
    }

    const handleSave = async () => {
        setIsSaving(true);

        const request = {
            disciplineId: selectedDisciplineId,
            virtualGroupId: 0, // temp
            virtualGroupName: newGroupName,
            studentIds: selectedStudents.map((item) => {
                return item.studentId
            })
        };
        
        await saveVirtualGroupRequest({data: request}).unwrap().then((fulfilled) => {
            if (fulfilled?.success) {
                navigate('/journalmain');
            } else {
                fulfilled.errors.map((item) => {
                    alert(item);
                    return 1;
                });

                setIsSaving(false);
            }
        });
    };
    
    function handleDisciplineChange(event) {
        const value = event.target.value;
        setSelectedDiscipline(value);
        setSelectedDisciplineId(value.key);
    }
    
    function handleGroupChange(event) {
        const value = event.target.value;
        setSelectedGroupId(value.key);
        setSelectedGroup(value);
    }

    return (
        <MainLayout>
            <PageTitle title={`Создать сводную группу`} />

            <Container>
                <Stack direction="column"
                       padding="50px 0px 0px 0px"
                       spacing={2}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {data === null || data === undefined ? <CircularProgress /> : <></>}
                            Создать сводную группу
                        </Typography>
                    </Stack>

                    <Grid container spacing={2}>
                        <Grid xs={5}>
                            <Card>
                                <CardContent>
                                    <Stack spacing={2}>
                                        <Typography variant={"h6"}>Список студентов</Typography>

                                        <FormControl size={"small"} fullWidth>
                                            <InputLabel id="discipline-label">Дисциплина</InputLabel>
                                            <Select
                                                labelId="discipline-label"
                                                id="discipline-select"
                                                value={selectedDiscipline}
                                                label="Дисциплина"
                                                disabled={selectedDiscipline !== null && selectedDiscipline !== undefined}
                                                onChange={(event) => handleDisciplineChange(event)}
                                            >
                                                {disciplines.sort((left, right) => left.value > right.value ? 1 : -1)
                                                    .map((item) => {
                                                        return <MenuItem value={item} key={item.value}>{item.value}</MenuItem>
                                                    })}
                                            </Select>
                                        </FormControl>
                                        
                                        <Typography>{selectedDiscipline === null || selectedDiscipline === undefined
                                                     ? 'Выберите дисциплину'
                                                     : selectedDiscipline.value}</Typography>

                                        <FormControl size={"small"} fullWidth>
                                            <InputLabel id="group-label">Группа</InputLabel>
                                            <Select
                                                labelId="group-label"
                                                id="group-select"
                                                value={selectedGroup}
                                                label="Группа"
                                                disabled={selectedDiscipline === null || selectedDiscipline === undefined}
                                                onChange={(event) => handleGroupChange(event)}
                                            >
                                                {groups.sort((left, right) => left.value > right.value ? 1 : -1)
                                                    .map((item) => {
                                                        return <MenuItem value={item} key={item.value}>{item.value}</MenuItem>
                                                    })}
                                            </Select>
                                        </FormControl>
                                        
                                        <TextField size={"small"} 
                                                   placeholder={"Поиск по ФИО студента"}
                                                   onInput={(event) => setStudentFilter(event.target.value)}
                                                   disabled={selectedGroupId === null || selectedGroupId === undefined}/>
                                        
                                        <Table size={"small"}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>ФИО студента</TableCell>
                                                    <TableCell />
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {students?.filter((item) => item.fullName.toLowerCase().startsWith(studentFilter))
                                                    .map((item) => {
                                                    return <TableRow>
                                                        <TableCell>{item.fullName}</TableCell>
                                                        <TableCell>
                                                            {item.attachedToEmployee !== null && item.attachedToEmployee !== undefined && item.attachedToEmployee.length > 0
                                                            ? <small>{`Уже в подгруппе "${item.attachedToSubGroup}" преподавателя ${item.attachedToEmployee}`}</small>
                                                            : <Button size={"small"}
                                                                      startIcon={!item.accepted ? <AddIcon /> : <RemoveSharp />}
                                                                      onClick={(event) => addStudentToSubGroup(item)}
                                                                      disabled={selectedGroupId === null || selectedGroupId === undefined}>
                                                                    {item.accepted ? 'Убрать из группы' : 'Добавить в группу'}
                                                                </Button>}
                                                        </TableCell>
                                                    </TableRow>
                                                })}
                                            </TableBody>
                                        </Table>
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid xs={2} />
                        <Grid xs={5}>
                            <Card>
                                <CardContent>
                                    <Stack spacing={2}>
                                        <Typography variant={"h6"}>Ваша группа</Typography>
                                        
                                        <TextField placeholder={`Введите название группы`}
                                                   value={newGroupName}
                                                   onInput={(event) => setNewGroupName(event.target.value)}
                                                   size={'small'}/>
                                        
                                        <Table size={"small"}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>ФИО студента</TableCell>
                                                    <TableCell>Группа</TableCell>
                                                    <TableCell />
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {selectedStudents.map((item) => {
                                                    return <TableRow>
                                                        <TableCell>{item.fullName}</TableCell>
                                                        <TableCell>{item.groupName}</TableCell>
                                                        <TableCell>
                                                            <Button size={"small"} 
                                                                    startIcon={<RemoveSharp />}
                                                                    onClick={() => removeStudentFromSubgroup(item)}>Убрать из группы</Button>
                                                        </TableCell>
                                                    </TableRow>
                                                })}
                                            </TableBody>
                                        </Table>
                                        
                                        <Button variant={"contained"} 
                                                size={"small"}
                                                onClick={handleSave}
                                                startIcon={isSaving ? <CircularProgress size={15} /> : <></>}
                                                disabled={selectedStudents.length === 0 || isSaving}>Сохранить</Button>
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Grid>


                    </Grid>
                    <br />
                </Stack>
            </Container>
        </MainLayout>
    );
}