import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { prepareAuthHeaders } from '../../utils/prepareAuthHeaders';

export const sessionApi = createApi({
    reducerPath: 'sessionApi',
    keepUnusedDataFor: 0,
    baseQuery: fetchBaseQuery({
        baseUrl: '/Session',
        prepareHeaders: prepareAuthHeaders,
    }),
    endpoints: (builder) => ({
        getListSessionDisciplinesForEmployee: builder.query({
            query: () => ({
                url: `/ListSessionDisciplinesForEmployee`,
                method: 'GET',
            }),
        }),
        getListSessionDisciplineGroupsForEmployee: builder.query({
            query: ({disciplineId}) => ({
                url: `/ListSessionDisciplineGroupsForEmployee`,
                method: 'GET',
                params: {disciplineId}
            }),
        }),
        getMyStatements: builder.query({
            query: ({statementType}) => ({
                url: `/MyStatements`,
                method: 'GET',
                params: {statementType}
            }),
        }),
        getFacultyStatements: builder.query({
            query: ({sessionId, statementType}) => ({
                url: `/FacultyStatements`,
                method: 'GET',
                params: {sessionId,statementType}
            }),
        }),
        getListFacultySessions: builder.query({
            query: () => ({
                url: `/ListFacultySessions`,
                method: 'GET',
            }),
        }),
        listFacultySessionsById: builder.query({
            query: ({facultyId}) => ({
                url: `/ListFacultySessionsById`,
                method: 'GET',
                params: {facultyId}
            }),
        }),
        getRetakesDetailsForSession: builder.query({
            query: ({sessionId}) => ({
                url: `/RetakesDetailsForSession`,
                method: 'GET',
                params: {sessionId}
            }),
        }),
        getSessionForCourse: builder.query({
            query: ({courseId}) => ({
                url: `/GetSessionForCourse`,
                method: 'GET',
                params: {courseId}
            }),
        }),
        getSessionSchedule: builder.query({
            query: ({sessionId}) => ({
                url: `/GetSessionSchedule`,
                method: 'GET',
                params: {sessionId}
            }),
        }),
        getSessionRetakesSchedule: builder.query({
            query: ({sessionId}) => ({
                url: `/GetSessionRetakesSchedule`,
                method: 'GET',
                params: {sessionId}
            }),
        }),
        getSessionDataForApproving: builder.query({
            query: ({sessionId}) => ({
                url: `/GetSessionDataForApproving`,
                method: 'GET',
                params: {sessionId}
            }),
        }),
        getSessionStatements: builder.query({
            query: ({sessionId}) => ({
                url: `/GetSessionStatements`,
                method: 'GET',
                params: {sessionId}
            }),
        }),
        postSaveSession: builder.mutation({
            query: ({ session }) => ({
                url: `/SaveSession`,
                method: 'POST',
                body: session,
            }),
            invalidatesTags: ['Post'],
        }),
        postSaveSessionSchedule: builder.mutation({
            query: ({ data }) => ({
                url: `/SaveSessionSchedule`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Post'],
        }),
        postSaveSessionRetakesSchedule: builder.mutation({
            query: ({ data }) => ({
                url: `/SaveSessionRetakesSchedule`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Post'],
        }),
        postSendSessionForApproval: builder.mutation({
            query: ({ sessionId }) => ({
                url: `/SendSessionForApproval?sessionId=${sessionId}`,
                method: 'POST'
            }),
            invalidatesTags: ['Post'],
        }),
        getSessionDisciplineDetailsInfo: builder.query({
            query: ({sessionDisciplineDetailsId}) => ({
                url: `/GetSessionDisciplineDetailsInfo`,
                method: 'GET',
                params: {sessionDisciplineDetailsId}
            }),
        }),
        postCreateStatement: builder.mutation({
            query: ({ data }) => ({
                url: `/CreateStatement`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['Post'],
        }),
        sessionApproveSummary: builder.query({
            query: ({sessionId}) => ({
                url: `/SessionApproveSummary`,
                method: 'GET',
                params: {sessionId}
            }),
        }),
        setApprovalStatus: builder.mutation({
            query: ({ data }) => ({
                url: `/SetApprovalStatus`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['Post'],
        }),
        sessionGeneralInfo: builder.query({
            query: ({sessionId}) => ({
                url: `/SessionGeneralInfo`,
                method: 'GET',
                params: {sessionId}
            }),
        }),
        createSessionStopRequest: builder.mutation({
            query: ({ data }) => ({
                url: `/CreateSessionStopRequest`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['Post'],
        }),
        sessionStopRequests: builder.query({
            query: () => ({
                url: `/SessionStopRequests`,
                method: 'GET'
            }),
        }),
        sessionStopRequestDetails: builder.query({
            query: ({requestId}) => ({
                url: `/SessionStopRequestDetails`,
                method: 'GET',
                params: {requestId}
            }),
        }),
        approveSessionStopRequest: builder.mutation({
            query: ({ data }) => ({
                url: `/ApproveSessionStopRequest`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['Post'],
        }),
        resultsOfStudentsForOutsidePlanPassing: builder.query({
            query: ({individualSchedule, badGradesRetake, studentIds, sessionId}) => ({
                url: `/ResultsOfStudentsForOutsidePlanPassing`,
                method: 'GET',
                params: {individualSchedule, badGradesRetake, studentIds, sessionId}
            }),
        }),
        createOutsidePlanStatements: builder.mutation({
            query: ({ data }) => ({
                url: `/CreateOutsidePlanStatements`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['Post'],
        }),
        getOutsidePlanPassings: builder.query({
            query: ({sessionId}) => ({
                url: `/OutsidePlanPassings`,
                method: 'GET',
                params: {sessionId}
            }),
        }),
        getOutsidePlanPassingListForApproval: builder.query({
            query: () => ({
                url: `/OutsidePlanPassingListForApproval`,
                method: 'GET'
            }),
        }),
        getOutsidePlanPassingApprovalDetails: builder.query({
            query: ({id}) => ({
                url: `/OutsidePlanPassingApprovalDetails`,
                method: 'GET',
                params: {id}
            }),
        }),
        approveOutsidePlanPassing: builder.mutation({
            query: ({ data }) => ({
                url: `/ApproveOutsidePlanPassingRequest`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['Post'],
        }),
        getOutsidePlanPassingDetails: builder.query({
            query: ({id}) => ({
                url: `/OutsidePlanPassingDetails`,
                method: 'GET',
                params: {id}
            }),
        }),
        outsidePlanPassingDisciplineDetails: builder.query({
            query: ({id}) => ({
                url: `/OutsidePlanPassingDisciplineDetails`,
                method: 'GET',
                params: {id}
            }),
        }),
        editSessionDisciplineDetail: builder.mutation({
            query: ({ data }) => ({
                url: `/EditSessionDisciplineDetail`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['Post'],
        }),
        sendOutsidePlanPassingForApproval: builder.mutation({
            query: ({ data }) => ({
                url: `/SendOutsidePlanPassingForApproval`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['Post'],
        }),
    }),
});

export const {
    useGetListSessionDisciplinesForEmployeeQuery,
    useGetListSessionDisciplineGroupsForEmployeeQuery,
    useGetMyStatementsQuery,
    useGetFacultyStatementsQuery,
    useGetRetakesDetailsForSessionQuery,
    useGetListFacultySessionsQuery,
    useGetSessionForCourseQuery,
    usePostSaveSessionMutation,
    usePostSaveSessionScheduleMutation,
    useGetSessionScheduleQuery,
    useGetSessionRetakesScheduleQuery,
    usePostSaveSessionRetakesScheduleMutation,
    useGetSessionDataForApprovingQuery,
    usePostSendSessionForApprovalMutation,
    useGetSessionStatementsQuery,
    useGetSessionDisciplineDetailsInfoQuery,
    usePostCreateStatementMutation,
    useListFacultySessionsByIdQuery,
    useSessionApproveSummaryQuery,
    useSetApprovalStatusMutation,
    useSessionGeneralInfoQuery,
    useCreateSessionStopRequestMutation,
    useSessionStopRequestsQuery,
    useSessionStopRequestDetailsQuery,
    useApproveSessionStopRequestMutation,
    useResultsOfStudentsForOutsidePlanPassingQuery,
    useCreateOutsidePlanStatementsMutation,
    useGetOutsidePlanPassingsQuery,
    useGetOutsidePlanPassingListForApprovalQuery,
    useGetOutsidePlanPassingApprovalDetailsQuery,
    useApproveOutsidePlanPassingMutation,
    useGetOutsidePlanPassingDetailsQuery,
    useOutsidePlanPassingDisciplineDetailsQuery,
    useEditSessionDisciplineDetailMutation,
    useSendOutsidePlanPassingForApprovalMutation
} = sessionApi;
