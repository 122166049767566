import {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {
    Backdrop,
    Box, Button, ButtonGroup,
    Card,
    CardContent,
    CircularProgress,
    Container, Divider,
    Stack,
    Tab,
    Table, TableBody, TableCell,
    TableHead, TableRow,
    Typography
} from "@mui/material";
import {Alert, AlertTitle, TabContext, TabList, TabPanel} from "@mui/lab";
import PlainStatementsList from "../sections/@dashboard/sessions/PlainStatementsList";
import PlainPracticeStatementsList from "../sections/@dashboard/practice/PlainStatementsList";
import {GeneralLayout} from "../layouts/GeneralLayout";
import EditIcon from "@mui/icons-material/Edit";
import PrintIcon from "@mui/icons-material/Print";
import {useCloseStatementMutation, useMyPracticeStatementsQuery} from "../store/practice/practiceApi";
import CheckIcon from "@mui/icons-material/Check";
import {useLocation, useNavigate} from "react-router-dom";
import {FileOpen} from "@mui/icons-material";
import MainLayout from "./main";
import PageTitle from "../components/PageTitle";

export default function MyPracticeStatementsPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const [tabIndex, setTabIndex] = useState("0");
    const dataQuery = useMyPracticeStatementsQuery();
    const {data} = dataQuery;
    const [isSaving, setIsSaving] = useState(false);
    const [copyOfData, setCopyOfData] = useState([]);
    const [closeStatementRequest] = useCloseStatementMutation();

    useEffect(() => {
        if (data === null || data === undefined)
            return;

        setCopyOfData(data);
    }, [data]);

    function nameOfPracticeKind(practiceKind) {
        switch (practiceKind) {
            case 0:
                return 'Учебная';
            case 1:
                return 'Производственная';
            default:
                return '';
        }
    }

    function nameOfPassingType(passingType) {
        switch (passingType) {
            case 0:
                return 'Основная';
            case 1:
                return 'Дополнительная';
            case 2:
                return 'Комиссионная';
            default:
                return '';
        }
    }
    
    async function closeStatement(statementId) {
        setIsSaving(true);
        await closeStatementRequest({data: {
                statementId
            }}).unwrap().then((fulfilled) => {
            if (fulfilled?.success) {
                dataQuery.refetch()
                setIsSaving(false);
            } else {
                console.log(fulfilled.errors);
                fulfilled.errors.map((item) => {
                    alert(item);
                    return 1;
                });

                setIsSaving(false);
            }
        });
    }

    function handleTabChange(event, val) {
        setTabIndex(val);
    }

    return (<MainLayout>
            <PageTitle title={`Ведомости по практикам`} />

            <Container>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isSaving}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Stack direction="column"
                       padding="30px 0px 0px 0px"
                       spacing={2}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {data === null || data === undefined ? <CircularProgress/> : <></>}
                            Мои ведомости по практикам
                        </Typography>
                    </Stack>

                    <Alert color="info" variant="outlined">
                        <AlertTitle align="center">Внимание!<br/>(из Кодекса корпоративной этики Кубанского
                            ГАУ)</AlertTitle>
                        <p>Коррупцией считается злоупотребление служебным положением, дача взятки, получение взятки,
                            злоупотребление полномочиями, коммерческий подкуп либо иное незаконное использование
                            физическим лицом своего должностного положения вопреки законным интересам общества и
                            государства в целях получения выгоды в виде денег, ценностей, иного имущества или услуг
                            имущественного характера, иных имущественных прав для себя или для третьих лиц либо
                            незаконное предоставление такой выгоды указанному лицу другими физическими лицами, а также
                            совершение указанных деяний от имени или в интересах юридического лица. К коррупционным
                            деяниям относятся следующие преступления: злоупотребление должностными полномочиями (ст. 285
                            УК РФ), дача взятки (ст. 291 УК РФ), получение взятки (ст. 290 УК РФ), посредничество во
                            взяточничестве (ст. 291.1 УК РФ), мелкое взяточничество (ст. 291.2 УК РФ), злоупотребление
                            полномочиями (ст. 201 УК РФ), коммерческий подкуп (ст. 204 УК РФ), а также иные деяния,
                            попадающие под понятия «коррупция», указанное выше. За указанные преступления предусмотрено
                            наказание вплоть до лишения свободы на срок до пятнадцати лет со штрафом в размере до
                            семидесятикратной суммы взятки и с лишением права занимать определенные должности или
                            заниматься определенной деятельностью на срок до пятнадцати лет.</p>
                    </Alert>

                    <br/>

                    <Card>
                        <TabContext value={tabIndex}>
                            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                                <TabList onChange={(event, data) => handleTabChange(event, data)}>
                                    <Tab label="Открытые" value="0"/>
                                    <Tab label="Все ведомости" value="1"/>
                                </TabList>
                            </Box>
                        </TabContext>
                        <CardContent>
                            <Stack spacing={2}>
                                {copyOfData?.practices?.filter((practice) => {
                                    return tabIndex === "0" ? practice.statements.filter((s) => s.closingDate === null).length > 0 : true;
                                }).map((practice) => {
                                    return <>
                                        <Typography
                                            variant={'h6'}>{nameOfPracticeKind(practice.practiceKind)} практика {practice.practiceType} у
                                            группы {practice.groupName}, {practice.courseName}</Typography>
                                        <Table size={"small"}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Тип сдачи</TableCell>
                                                    <TableCell fullWidth>№ ведомости</TableCell>
                                                    <TableCell>Статус</TableCell>
                                                    <TableCell>Операции</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {practice.statements.filter((item) => {
                                                    return tabIndex === "0" ? item.closingDate === null : true;
                                                }).sort((left, right) => {
                                                    console.log(left);
                                                    console.log(right);
                                                    console.log('-----');
                                                    return left.creationDate >= right.creationDate ? 1 : -1;
                                                }).map((item, index) => {
                                                    return <TableRow key={item.statementId}>
                                                        <TableCell>{nameOfPassingType(index)}</TableCell>
                                                        <TableCell>{item.statementNumber}</TableCell>
                                                        <TableCell>{`Ведомость ${item.closingDate === null ? 'открыта' : 'закрыта'}`}</TableCell>
                                                        <TableCell>
                                                            <ButtonGroup orientation={"vertical"} variant={"outlined"}
                                                                         size={"small"}>
                                                                {item.closingDate === null || item.closingDate === undefined
                                                                    ? <>
                                                                        <Button startIcon={<EditIcon/>}
                                                                                href={`/edit-practice-statement/${item.statementId}`}>Редактировать</Button>
                                                                        <Button startIcon={<PrintIcon/>}>Справочная
                                                                            ведомость</Button>
                                                                    </>
                                                                    : <></>}
                                                                {item.canClose && (item.closingDate === null || item.closingDate === undefined)
                                                                ? <Button startIcon={<CheckIcon />}
                                                                          onClick={() => { closeStatement(item.statementId) }}>Закрыть ведомость</Button>
                                                                : <></>}
                                                                {item.closingDate !== null && item.closingDate !== undefined
                                                                ? <>
                                                                    <Button startIcon={<FileOpen />}
                                                                            href={`/practice-statement-results/${item.statementId}`}>Подробности</Button>
                                                                    <Button startIcon={<PrintIcon />}>Печать</Button>
                                                                </>
                                                                : <></>}
                                                            </ButtonGroup>
                                                        </TableCell>
                                                    </TableRow>
                                                })}
                                            </TableBody>
                                        </Table>

                                        <Divider/>
                                    </>
                                })}
                            </Stack>
                        </CardContent>
                    </Card>
                </Stack>
            </Container>
        </MainLayout>
    )
}
