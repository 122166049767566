import {Helmet} from 'react-helmet-async';
import {filter} from 'lodash';
import {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer, TableHead, CardContent, Button, Select, FormControl, InputLabel, MenuItem, Box, Tab, ButtonGroup,
} from '@mui/material';
// components
import Scrollbar from '../components/scrollbar';
import JournalListRow from "../sections/@dashboard/journal/JournalListRow";
import {GeneralLayout} from "../layouts/GeneralLayout";
import {
    useGetBindingsQuery,
    useGetEmployeeBindingsQuery,
    useGetFacultyBindingsQuery, useGetVirtualGroupsQuery
} from "../store/journal/journalApi";
import {useGetUserDetailsQuery} from "../store/user/userApi";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import TextField from "@mui/material/TextField";
import {Book, PanoramaFishEye, RemoveRedEyeSharp} from "@mui/icons-material";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import AddIcon from "@mui/icons-material/Add";
import PageTitle from "../components/PageTitle";
// sections

// ----------------------------------------------------------------------

export default function JournalMainOldPage() {
    const navigate = useNavigate();
    const [bindings, setBindings] = useState([]);
    const roles = localStorage.getItem("roles")?.split(',') ?? [];
    const isChair = roles.find((item) => item === "chair") !== undefined;
    const isEmployee = roles.find((item) => item === "employee") !== undefined;
    const isAdmin = roles.find((item) => item === "AttNewAdmin") !== undefined;
    const {data} = useGetBindingsQuery();
    const {data: userData} = useGetUserDetailsQuery();
    const {data: virtualGroupsData} = useGetVirtualGroupsQuery();
    const [tabIndex, setTabIndex] = useState("1");
    const [virtualGroups, setVirtualGroups] = useState([]);
    const [nameFilter, setNameFilter] = useState('');

    useEffect(() => {
        if (data === null || data === undefined)
            return;

        if (bindings.length === 0)
            setBindings(copyObject(data.bindings))

        if (virtualGroups.length === 0 && virtualGroupsData !== null && virtualGroupsData !== undefined) {
            setVirtualGroups(copyObject(virtualGroupsData.data));
        }
    }, [data]);

    function copyObject(source) {
        if (source === null || source === undefined)
            return {};

        return JSON.parse(JSON.stringify(source));
    }

    function handleClick(groupFlowTeamId) {
        console.log(roles);
        if (roles.find((item) => item === 'employee') === undefined) {
            return;
        }

        navigate(`/journaledit/${groupFlowTeamId}/0`,
            {
                replace: false,
                state: {
                    groupFlowTeamId
                }
            });
    }

    function handleTabChange(event, val) {
        setTabIndex(val);
    }

    return (
        <GeneralLayout>
            <PageTitle title={`Список курсов`} />

            <Container>
                <Stack
                    padding="120px 0px 0px 100px"
                    direction="column"
                    spacing={2}
                >
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            Журнал
                        </Typography>
                    </Stack>

                    <br/>

                    <TabContext value={tabIndex}>
                        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                            <TabList onChange={(event, val) => handleTabChange(event, val)}>
                                <Tab label="Список курсов" value="1"/>
                                {userData?.userFlags?.virtualGroupEnabled === true
                                    ? <Tab label="Сводные группы" value="2"/>
                                    : <></>}
                            </TabList>
                        </Box>
                        <TabPanel value={"1"} style={{"padding": "0px"}}>
                            <Card>
                                <Scrollbar>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Дисциплина и курс</TableCell>
                                                <TableCell>Лекции</TableCell>
                                                <TableCell>Семинары</TableCell>
                                                <TableCell>Лабораторные</TableCell>
                                                <TableCell>Операции</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {bindings.map((item) => {
                                                return (
                                                    <JournalListRow courseName={item.courseName}
                                                                    disciplineName={item.disciplineName}
                                                                    courseId={item.courseId}
                                                                    disciplineId={item.disciplineId}
                                                                    laboratory={item.flows.filter((flow) => flow.flowType === 1).sort((left, right) => left.groups[0] > right.groups[0] ? 1 : -1)}
                                                                    lectures={item.flows.filter((flow) => flow.flowType === 6)}
                                                                    seminars={item.flows.filter((flow) => flow.flowType === 3).sort((left, right) => left.groups[0] > right.groups[0] ? 1 : -1)}
                                                                    editAction={(groupFlowTeamId) => handleClick(groupFlowTeamId)}/>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </Scrollbar>
                            </Card>
                        </TabPanel>

                        {userData?.userFlags?.virtualGroupEnabled === true
                            ? <TabPanel value={"2"} style={{"padding": "0px"}}>
                                <Card>
                                    <CardContent>
                                        <Stack spacing={2}>
                                            <Stack direction={"row"}
                                                   spacing={1}
                                                   alignItems={"center"}
                                                   justifyContent={"space-between"}>
                                                {isEmployee
                                                    ? <Button startIcon={<AddIcon />}
                                                              variant={"contained"}
                                                              href={`/create-virtual-group`}>Создать сводную группу</Button>
                                                    : <></>
                                                }
                                                <Stack direction={"row"} spacing={1}>
                                                    <TextField size={"small"}
                                                               placeholder={"Наименование сводной группы"}
                                                               onInput={(event) => setNameFilter(event.target.value)}
                                                               value={nameFilter}/>
                                                </Stack>
                                            </Stack>

                                            {virtualGroups.map((vg) => {
                                                return <>
                                                    <Typography variant={"h5"}>{vg.disciplineName}</Typography>
                                                    <Table size={"small"}>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>#</TableCell>
                                                                <TableCell>Название группы</TableCell>
                                                                <TableCell>Преподаватель</TableCell>
                                                                <TableCell>Кол-во часов</TableCell>
                                                                <TableCell>Операции</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {vg.virtualGroups.filter((item) => item.virtualGroupName.toLowerCase().startsWith(nameFilter.toLowerCase()))
                                                                .map((item, index) => {
                                                                    return <TableRow>
                                                                        <TableCell>{index + 1}</TableCell>
                                                                        <TableCell>{item.virtualGroupName}</TableCell>
                                                                        <TableCell>{item.employeeFullName}</TableCell>
                                                                        <TableCell>{item.filledHours} из {item.totalHours}</TableCell>
                                                                        <TableCell>
                                                                            <ButtonGroup>
                                                                                <Button startIcon={<RemoveRedEyeSharp />}
                                                                                        href={`/journaledit/0/${item.virtualGroupId}`}>Начать перекличку</Button>
                                                                                <Button startIcon={<AutoStoriesOutlinedIcon />}
                                                                                        href={`/virtual-group-journal/${item.virtualGroupId}`}>Журнал</Button>
                                                                            </ButtonGroup>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                })}
                                                        </TableBody>
                                                    </Table>
                                                </>
                                            })
                                            }
                                        </Stack>
                                    </CardContent>
                                </Card>
                            </TabPanel>
                            : <></>}
                    </TabContext>
                    <br/>
                </Stack>
            </Container>
        </GeneralLayout>
    );
}
