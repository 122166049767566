import { Helmet } from 'react-helmet-async';
// @mui
import {
    Card,
    Stack,
    Container,
    Typography,
    TableContainer,
    CardContent,
    Button,
    CircularProgress,
    Box,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableHead,
    TextareaAutosize, ButtonGroup,
} from '@mui/material';

import {useNavigate, useParams} from 'react-router-dom';

// components
import Scrollbar from '../components/scrollbar';
// sections
import { StepByStepTable } from "../sections/@dashboard/attestations";
import {GeneralLayout} from "../layouts/GeneralLayout";
import Label from "../components/label";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {useGetNextAttestationNumberQuery, usePostCreateAttestationMutation} from "../store/attestation/attestationApi";
import SessionScheduleCategory from "../sections/@dashboard/sessions/SessionScheduleCategory";
import {Textarea} from "@mui/joy";
import {useSessionApproveSummaryQuery, useSetApprovalStatusMutation} from "../store/session/sessionApi";
import PageTitle from "../components/PageTitle";

export default function SessionApprovePage() {
    const {sessionId} = useParams();
    const navigate = useNavigate();
    const [isSaving, setIsSaving] = useState(false);
    const {data} = useSessionApproveSummaryQuery({sessionId});
    const [review, setReview] = useState('');
    const [setApprovalStatusRequest] = useSetApprovalStatusMutation();
    const [retakesGroups, setRetakesGroups] = useState([]);
    
    useEffect(() => {
        if (data === null || data === undefined)
            return;

        setRetakesGroups(data?.data?.retakes?.length > 0 ? data?.data?.retakes[0]?.groups : []);
    }, [data])

    function formatDate(dateObj) {
        return `${dateObj.getFullYear()}-${withZero(dateObj.getMonth() + 1)}-${withZero(dateObj.getDate())}`;
    }

    function withZero(num) {
        return num < 10 ? `0${num}` : num;
    }
    
    function controlTypeName(controlTypeId) {
        switch (controlTypeId) {
            case 0: return 'Экзамен';
            case 1: return 'Зачёт';
            case 2: return 'Дифф. зачёт';
            case 3: return 'Курсовая работа';
            case 4: return 'Курсовой проект';
            default: return '';
        }
    }

    const handleSave = async (approved) => {
        setIsSaving(true);

        const request = {
            approved,
            sessionId,
            review
        };
        
        console.log(`Отправляем запрос на изменение статуса`, request);
        
        await setApprovalStatusRequest({data: request}).unwrap().then((fulfilled) => {
            if (fulfilled?.isSuccess) {
                navigate('/session-approve-list');
            } else {
                alert(fulfilled.message);

                setIsSaving(false);
            }
        });
    };

    return (
        <GeneralLayout>
            <PageTitle title={`Утверждение сессии`} />

            <Container>
                <Stack direction="column"
                       padding="120px 0px 0px 100px"
                       spacing={2}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {data === null || data === undefined ? <CircularProgress/> : <></>}
                            Расписание сессии {data?.data?.courseName}
                        </Typography>
                    </Stack>
                    <Table size={"small"}>
                        <TableBody>
                            <TableRow>
                                <TableCell align={"right"}><strong>Зачётная неделя</strong></TableCell>
                                <TableCell
                                    width={200}>{data?.data?.checksStartDate} — {data?.data?.checksEndDate}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align={"right"}><strong>Экзаменационная неделя</strong></TableCell>
                                <TableCell
                                    width={200}>{data?.data?.examsStartDate} — {data?.data?.examsEndDate}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align={"right"}><strong>Каникулы</strong></TableCell>
                                <TableCell
                                    width={200}>{data?.data?.holidaysStartDate} — {data?.data?.holidaysEndDate}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                    <Card>
                        <CardContent>
                            <Stack spacing={5}>
                                <SessionScheduleCategory categoryName={'Экзамены'}
                                                         disciplines={data?.data?.passings.filter((p) => p.controlTypeId === 0)}/>
                                <SessionScheduleCategory categoryName={'Зачёты'}
                                                         disciplines={data?.data?.passings.filter((p) => p.controlTypeId === 1 || p.controlTypeId === 2)}/>
                                {data?.data?.courseWorks?.length > 0
                                    ? <Stack>
                                        <Typography variant={"h5"}>Курсовые работы (проекты)</Typography>
                                        <ol>
                                            {data?.data?.courseWorks.map((cw) => {
                                                return <li>{cw}</li>
                                            })}
                                        </ol>
                                    </Stack>
                                    : <></>}

                                {data?.data?.retakes?.length > 0
                                    ? <>
                                        <Typography variant={"h5"}>Пересдачи</Typography>
                                        <Card>
                                            <Stack spacing={2}>
                                                <Table size={"small"}>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell rowSpan={2}>Наименование дисциплины</TableCell>
                                                            <TableCell align={"center"} colSpan={retakesGroups.length}>Группа</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            {retakesGroups.map((g) => {
                                                                return <TableCell
                                                                    align={"center"}>{g.groupName}</TableCell>
                                                            })}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {data?.data?.retakes?.map((item) => {
                                                            return <TableRow>
                                                                <TableCell>
                                                                    <Typography>{item.disciplineName}</Typography>
                                                                    <Typography fontSize={'small'}>{controlTypeName(item.controlTypeId)}</Typography>
                                                                </TableCell>
                                                                {item.groups.map((g) => {
                                                                    return <TableCell
                                                                        align={"center"}>{g.eventDate}<br/>{g.eventPlace}
                                                                    </TableCell>
                                                                })}
                                                            </TableRow>
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </Stack>
                                        </Card>
                                    </>
                                    : <></>}

                                {data?.data?.sessionReviews?.length > 0
                                    ? <Stack>
                                        <Typography variant={"h5"}>История замечаний</Typography>
                                        <Card>
                                            <Table size={"small"}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>#</TableCell>
                                                        <TableCell>Дата</TableCell>
                                                        <TableCell>Замечание</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {data?.data?.sessionReviews?.map((item, index) => {
                                                        return <TableRow>
                                                            <TableCell>{index + 1}</TableCell>
                                                            <TableCell>{item.date}</TableCell>
                                                            <TableCell>{item.text}</TableCell>
                                                        </TableRow>
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </Card>
                                    </Stack>
                                    : <></>}

                                <Stack>
                                    <Typography variant={"h5"}>Замечания</Typography>
                                    <TextField style={{"backgroundColor": "white"}}
                                               value={review}
                                               onInput={(event) => setReview(event.target.value)}
                                               variant={"outlined"}
                                               minRows={2}
                                               multiline
                                               placeholder={"Замечания"}/>
                                </Stack>
                            </Stack>
                        </CardContent>
                    </Card>


                    <Stack justifyContent="space-between" direction={"row"} spacing={2}>
                        {
                            !isSaving
                                ? <ButtonGroup>
                                    <Button variant="contained"
                                            color={"success"}
                                            style={{"color": "white"}}
                                            disabled={data === null || data === undefined}
                                            onClick={() => handleSave(true)}>Утвердить</Button>

                                    <Button variant="contained"
                                            color={"error"}
                                            disabled={data === null || data === undefined}
                                            onClick={() => handleSave(false)}>Отклонить</Button>
                                </ButtonGroup>
                                : <CircularProgress/>
                        }
                    </Stack>
                    <br/>
                </Stack>
            </Container>
        </GeneralLayout>
    );
}