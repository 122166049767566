import {Helmet} from 'react-helmet-async';
// @mui
import {
    Card,
    Stack,
    Container,
    Typography,
    TableContainer,
    CardContent,
    Button,
    CircularProgress,
    Box,
    Menu,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Table, TableHead, TableRow, TableCell, TableBody, Link,
} from '@mui/material';

import {useNavigate, useParams} from 'react-router-dom';

// components
import Scrollbar from '../components/scrollbar';
// sections
import {StepByStepTable} from "../sections/@dashboard/attestations";
import {GeneralLayout} from "../layouts/GeneralLayout";
import Label from "../components/label";
import TextField from "@mui/material/TextField";
import React, {useEffect, useState} from "react";
import {useGetNextAttestationNumberQuery, usePostCreateAttestationMutation} from "../store/attestation/attestationApi";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import {
    ClosedCaption, Download,
    MenuBook,
    MenuOpen,
    MenuOpenSharp,
    OpenInNew,
    OpenWith,
    OpenWithOutlined
} from "@mui/icons-material";
import {useGetStudentDetailsBySemesterQuery, useGetStudentSemestersQuery} from "../store/student/studentApi";
import PageTitle from "../components/PageTitle";

export default function StudentCardPage() {
    const {id} = useParams();
    const navigate = useNavigate();
    const [selectedSemester, setSelectedSemester] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const data = null;
    const {data: semestersData} = useGetStudentSemestersQuery({studentId: id});
    const {data: studentData} = useGetStudentDetailsBySemesterQuery({
        studentId: id,
        semesterId: selectedSemester?.semesterId
    });

    useEffect(() => {
        if ((selectedSemester === null || selectedSemester === undefined) && semestersData !== null && semestersData !== undefined) {
            setSelectedSemester(semestersData.semesters[0])
        }

        if (selectedSemester !== null && selectedSemester !== undefined) {
            // todo: load data
        }
    }, [selectedSemester, semestersData]);

    function formatDate(dateObj) {
        return `${dateObj.getFullYear()}-${withZero(dateObj.getMonth() + 1)}-${withZero(dateObj.getDate())}`;
    }

    function toDateString(date) {
        return new Date(date).toLocaleDateString("ru-RU");
    }

    function withZero(num) {
        return num < 10 ? `0${num}` : num;
    }

    function getSessionDetailElements(details) {
        return <>
            <TableCell>{details.controlType}</TableCell>
            <TableCell>{details.employees.join(', ')}</TableCell>
            <TableCell>
                <Link href={`/statementresults/${details.statementId}`}
                      target={"_blank"}>Ведомость №{details.statementNumber}</Link>
                {details.closingDate !== null
                    ? <small><br/> Закрыта {toDateString(details.closingDate)}</small>
                    : <small><br/> Открыта</small>}
            </TableCell>
            <TableCell>
                {details.grade}
            </TableCell>
        </>
    }

    return (
        <GeneralLayout>
            <PageTitle title={`Карточка студента`}/>

            <Container>
                <Stack direction="column"
                       padding="120px 0px 0px 100px"
                       spacing={2}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {studentData === null || studentData === undefined ? <CircularProgress/> : <></>}
                            Карточка студента - {studentData?.details?.fullName}
                        </Typography>
                    </Stack>

                    <Card>
                        <CardContent>
                            <Stack spacing={2} direction={"row"} alignItems={"center"}>
                                <Typography>Семестр</Typography>
                                <Select size={"small"}
                                        fullWidth
                                        value={selectedSemester}
                                        onChange={(event) => setSelectedSemester(event.target.value)}
                                        placeholder={'Выберите семестр'}>
                                    {semestersData?.semesters.map((item) => {
                                        return <MenuItem value={item}
                                                         key={item.semesterName}>{item.semesterName}</MenuItem>
                                    })}
                                </Select>
                            </Stack>
                        </CardContent>
                    </Card>

                    <Card>
                        <CardContent>
                            <Stack spacing={2}>
                                <Typography><strong>Факультет: </strong>{studentData?.details?.facultyName}</Typography>
                                <Typography><strong>ОПОП ВО: </strong>{studentData?.details?.educationProgramName}
                                </Typography>
                                <Typography><strong>Группа: </strong>{studentData?.details?.primaryGroupName}
                                </Typography>
                                <Typography><strong>Курс: </strong>{studentData?.details?.courseName}</Typography>
                                <Typography><strong>Средний балл за весь период
                                    обучения: </strong>{studentData?.details?.totalAverageGrade}</Typography>

                                {data?.data?.previousGroups.length > 0
                                    ? <Typography><strong>Предыдущие
                                        группы: </strong>{studentData?.details?.previousGroups.join(', ')}</Typography>
                                    : <></>}

                                {data?.data?.previousNames.length > 0
                                    ? <Typography><strong>Предыдущие
                                        имена: </strong>{studentData?.details?.previousNames.join(', ')}</Typography>
                                    : <></>}
                            </Stack>
                        </CardContent>
                    </Card>

                    <Card>
                        <Accordion>
                            <AccordionSummary expandIcon={<MenuOpen/>}>
                                <Typography variant={"h6"}>Дисциплины</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Table size={"small"}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Наименование</TableCell>
                                            <TableCell>Тип / Пропущено часов</TableCell>
                                            <TableCell>Аттестация 1</TableCell>
                                            <TableCell>Аттестация 2</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {studentData?.details?.disciplinesResults?.map((disc) => {
                                            return <TableRow>
                                                <TableCell>
                                                    <strong>{disc.disciplineName}</strong>
                                                    <br/>
                                                    <small>Кафедра {disc.chairName}</small>
                                                </TableCell>
                                                <TableCell>
                                                    <Table size={"small"} align={"left"}>
                                                        <TableBody>
                                                            {disc.lessonTypeDetails.length === 0
                                                                ? <TableRow>
                                                                    <TableCell>Лабораторная работа
                                                                        <br/>
                                                                        Не распределен(а) в подгруппу
                                                                    </TableCell>
                                                                </TableRow>
                                                                : <></>}
                                                            {disc.lessonTypeDetails.map((ltd) => {
                                                                return <TableRow>
                                                                    <TableCell>{ltd.lessonType}
                                                                        <br/>
                                                                        <small>Пропущено {ltd.missedHours} / {ltd.totalHours}</small>
                                                                    </TableCell>
                                                                </TableRow>
                                                            })}
                                                        </TableBody>
                                                    </Table>
                                                </TableCell>
                                                <TableCell>
                                                    {disc.attestation1Grade !== null
                                                        ? `${disc.attestation1Grade}`
                                                        : ``}
                                                    <br/>
                                                    {disc.attestation1MissedHours !== null
                                                        ? <small>Пропущено часов: {disc.attestation1MissedHours}</small>
                                                        : <></>}
                                                </TableCell>
                                                <TableCell>
                                                    {disc.attestation2Grade !== null
                                                        ? `${disc.attestation2Grade}`
                                                        : ``}
                                                    <br/>
                                                    {disc.attestation2MissedHours !== null
                                                        ? <small>Пропущено часов: {disc.attestation2MissedHours}</small>
                                                        : <></>}
                                                </TableCell>
                                            </TableRow>
                                        })}
                                    </TableBody>
                                </Table>
                            </AccordionDetails>
                        </Accordion>
                    </Card>

                    <Card>
                        <Accordion>
                            <AccordionSummary expandIcon={<MenuOpen/>}>
                                <Typography variant={"h6"}>Сессия</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Table size={"small"}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Дисциплина</TableCell>
                                            <TableCell>Тип контроля</TableCell>
                                            <TableCell>Преподаватели</TableCell>
                                            <TableCell>Ведомость</TableCell>
                                            <TableCell>Оценка</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {studentData?.details?.sessionDetails?.map((disc, discIndex) => {
                                            return <>
                                                <TableRow>
                                                    <TableCell rowSpan={disc?.detailsByControlType?.length ?? 1}>
                                                        <strong>{disc.disciplineName}</strong>
                                                    </TableCell>
                                                    {disc?.detailsByControlType.map((details, index) => {
                                                        if (index >= 1)
                                                            return null;

                                                        return getSessionDetailElements(details)
                                                    })}
                                                </TableRow>

                                                {disc?.detailsByControlType.map((details, index) => {
                                                    if (index < 1)
                                                        return null;

                                                    return getSessionDetailElements(details)
                                                })}
                                            </>
                                        })}
                                    </TableBody>
                                </Table>
                                <br />
                                {studentData?.details?.averageSessionGradeBySemester !== null && studentData?.details?.averageSessionGradeBySemester !== undefined
                                    ? <Typography fontSize={'small'}><strong>Средняя оценка за семестр:</strong> {studentData.details.averageSessionGradeBySemester}</Typography>
                                    : <></>}

                                {studentData?.details?.averageSessionGradeByYear !== null && studentData?.details?.averageSessionGradeByYear !== undefined
                                    ? <Typography fontSize={'small'}><strong>Средняя оценка за год:</strong> {studentData.details.averageSessionGradeByYear}</Typography>
                                    : <></>}
                            </AccordionDetails>
                        </Accordion>
                    </Card>

                    <Card>
                        <Accordion>
                            <AccordionSummary expandIcon={<MenuOpen/>}>
                                <Typography variant={"h6"}>Практика</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Table size={"small"}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Вид, тип практики</TableCell>
                                            <TableCell>Тип контроля</TableCell>
                                            <TableCell>Преподаватели</TableCell>
                                            <TableCell>Ведомость</TableCell>
                                            <TableCell>Оценка</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {studentData?.details?.practiceDetails?.map((disc) => {
                                            return <TableRow>
                                                <TableCell>
                                                    <strong>
                                                        {disc.practiceType}<br/>
                                                        {disc.practiceKind === 1 ? 'Учебная' : 'Производственная'}
                                                    </strong>
                                                </TableCell>
                                                <TableCell>{disc.controlType === 1 ? 'Зачёт' : 'Зачёт с оценкой'}</TableCell>
                                                <TableCell>{disc.employees.join(', ')}</TableCell>
                                                <TableCell>
                                                    {disc.statementNumber}
                                                    {disc.closingDate !== null
                                                        ? <><br/> Закрыта {toDateString(disc.closingDate)}</>
                                                        : <><br/> Открыта</>}
                                                </TableCell>
                                                <TableCell>
                                                    {disc.gradeName}
                                                </TableCell>
                                            </TableRow>
                                        })}
                                    </TableBody>
                                </Table>
                            </AccordionDetails>
                        </Accordion>
                    </Card>

                    <Card>
                        <Accordion>
                            <AccordionSummary expandIcon={<MenuOpen/>}>
                                <Typography variant={"h6"}>Документы</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Table size={"small"}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Наименование</TableCell>
                                            <TableCell>Файл</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {studentData?.details?.documents?.map((doc) => {
                                            return <TableRow>
                                                <TableCell>{doc.documentName}</TableCell>
                                                <TableCell>
                                                    {doc.fileGuid !== null && doc.fileGuid !== undefined
                                                        ? <Button href={`https://fs.kubsau.ru/File/${doc.fileGuid}`}
                                                                  startIcon={<Download/>}
                                                                  target={'_blank'}>Скачать</Button>
                                                        : <></>}
                                                </TableCell>
                                            </TableRow>
                                        })}
                                    </TableBody>
                                </Table>
                            </AccordionDetails>
                        </Accordion>
                    </Card>
                    
                    <Typography fontSize={'small'}>* Для расчета средней оценки по сессии за семестр берутся оценки от 2 до 5 по закрытым ведомостям</Typography>
                    <Typography fontSize={'small'}>** Для расчета средней оценки по сессиям за год берутся оценки от 2 до 5 по закрытым ведомостям, после чего группируются по типу сдачи. Если в первом семестре по предмету был зачёт, а во втором семестре экзамен, то для расчета будет браться экзаменационная оценка</Typography>
                    <Typography fontSize={'small'}>*** Для расчета средней оценки за весь период обучения берутся оценки от 2 до 5 по сессиям, после чего группируются по типу сдачи. Если в первом семестре по предмету был зачёт, а во втором семестре экзамен, то для расчета будет браться экзаменационная оценка. Далее этот список оценок объединяется с оценками по практикам, после чего вычисляется средний балл по формуле - <em>"(ср. балл за все сессии + ср. балл за все практики) / 2"</em>. Если для студента не найдено ни одной оценки по практике, то считается средний балл лишь по сессиям</Typography>

                    <br/>
                </Stack>
            </Container>
        </GeneralLayout>
    );
}