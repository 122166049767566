import { Helmet } from 'react-helmet-async';
// @mui
import {
    Card,
    Stack,
    Container,
    Typography,
    TableContainer,
    CardContent,
    Button,
    CircularProgress,
    Box,
    TableCell,
    TableRow,
    TableHead,
    Table,
    TableBody,
    ListItemAvatar, Avatar, ListItemText, Chip,
} from '@mui/material';

import {useNavigate, useParams} from 'react-router-dom';

// components
import Scrollbar from '../components/scrollbar';
// sections
import { StepByStepTable } from "../sections/@dashboard/attestations";
import {GeneralLayout} from "../layouts/GeneralLayout";
import Label from "../components/label";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {useGetNextAttestationNumberQuery, usePostCreateAttestationMutation} from "../store/attestation/attestationApi";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import {Check, Remove} from "@mui/icons-material";
import {useSessionStopRequestsQuery} from "../store/session/sessionApi";
import PageTitle from "../components/PageTitle";

export default function SessionStopRequestListPage() {
    const navigate = useNavigate();
    const roles = localStorage.getItem('roles').split(',');
    const isUmu = roles.find((item) => item === 'AttNewUmu') !== undefined;
    const isDispatcher = roles.find((item) => item === 'AttNewDispatcher') !== undefined;
    const [isSaving, setIsSaving] = useState(false);
    const {data} = useSessionStopRequestsQuery();


    function formatDate(dateObj) {
        return `${dateObj.getFullYear()}-${withZero(dateObj.getMonth() + 1)}-${withZero(dateObj.getDate())}`;
    }

    function withZero(num) {
        return num < 10 ? `0${num}` : num;
    }
    
    function getApprovalChip(labelText, state) {
        return <Chip size={"small"} 
                     label={labelText}
                     variant={"plain"} 
                     icon={state === null || state === undefined ? <HourglassBottomIcon /> : state === true ? <Check /> : <Remove />}
                     color={state === null || state === undefined ? 'default' : state === true ? 'success' : 'error'}/>
    }

    return (
        <GeneralLayout>
            <PageTitle title={`Заявки на прерывание сессии`} />

            <Container>
                <Stack direction="column"
                       padding="120px 0px 0px 100px"
                       spacing={2}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {data === null || data === undefined ? <CircularProgress /> : <></>}
                            Заявки на прерывание сессии
                        </Typography>
                    </Stack>

                    <Card>
                        {data?.requests?.length === 0
                        ? <Typography align={"center"}>Список пуст</Typography>
                        : <>
                                <Table size={"small"}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Курс</TableCell>
                                            <TableCell>Дата создания заявки</TableCell>
                                            <TableCell>Статус</TableCell>
                                            <TableCell>Часть текста заявки</TableCell>
                                            <TableCell align={"center"}>Решение</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data?.requests.map((item) => {
                                            return <TableRow>
                                                <TableCell>{item.courseName}</TableCell>
                                                <TableCell>{item.requestDate}</TableCell>
                                                <TableCell>
                                                    <Stack spacing={1}>
                                                        {item.umuRelatedChanges
                                                            ? getApprovalChip('УМУ', item.approvedByUmu)
                                                            : <></>}

                                                        {item.dispatcherRelatedChanges
                                                            ? getApprovalChip('Диспетчерская', item.approvedByDispatcher)
                                                            : <></>}
                                                    </Stack>
                                                </TableCell>
                                                <TableCell>{item.shortText}</TableCell>
                                                <TableCell align={"center"}>
                                                    <Stack spacing={1}>
                                                        {item.approved
                                                            ? <Typography color={"green"}>Одобрена {item.approvalDate}</Typography>
                                                            : <></>}

                                                        {item.approved === false && item.approved !== null
                                                            ? <Typography color={"red"}>Отказано</Typography>
                                                            : <></>}

                                                        {item.approved === null
                                                            ? <Typography>Решение не принято</Typography>
                                                            : <></>}

                                                        {(item.approvedByUmu === null && item.umuRelatedChanges && isUmu
                                                            || item.approvedByDispatcher === null && item.dispatcherRelatedChanges && isDispatcher)
                                                        && item.approved === null
                                                            ?  <Button size={"small"}
                                                                       variant={"outlined"}
                                                                       href={`/session-stop-request-approval/${item.sessionStopRequestId}/${item.sessionId}`}>Открыть заявку</Button>
                                                            : <></>}
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        })}
                                    </TableBody>
                                </Table>
                            </>}
                        
                    </Card>
                </Stack>
            </Container>
        </GeneralLayout>
    );
}