import {useState, useEffect} from "react"
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded"
import {store} from "../../../../store/store"
import Autocomplete from "@mui/material/Autocomplete"
import {TextField} from "@mui/material"
import "./styles.scss"
import {useGetUserDetailsQuery} from "../../../../store/user/userApi";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {useNavigate} from "react-router-dom";

const Semester = () => {
    const navigate = useNavigate();
    const selectedSemester = localStorage.getItem("selectedSemesterId");
    const [semester, setSemester] = useState("Выберите семестр");
    const {data} = useGetUserDetailsQuery();

    const [menuItems, setMenuItems] = useState([])
    let searchInput

    useEffect(() => {
        if (data === null || data === undefined)
            return;

        console.log('Текущий семестр', selectedSemester)
        setMenuItems(copyObject(data.availableSemesters).sort((left, right) => -1).map((item) => item.value))
        setSemester((data.availableSemesters.find((item) => item?.key?.toString() === selectedSemester?.toString()))?.value)
    }, [data])
    
    function getSemester(semesterName) {
        console.log('ищем семестр', semesterName)
        return data.availableSemesters.find((item) => item?.value?.toString() === semesterName?.toString());
    }

    function copyObject(source) {
        if (source === null || source === undefined)
            return {};

        return JSON.parse(JSON.stringify(source));
    }

    const handleChange = () => {
    }

    const handleInputChange = (e, value) => {
        // store.dispatch({type: "write", payload: value})
        const semesterObject = getSemester(value);
        if (semesterObject === null || semesterObject === undefined) {
            console.log('can not find a semester')
            return;
        }
        
        if (semester === value)
            return;
        
        // localStorage.setItem('selectedSemesterId', value)
        localStorage.setItem("selectedSemesterId", semesterObject.key);
        console.log('Установили семестр', semesterObject.key)
        setSemester(value)
        console.log('Текущий семестр', selectedSemester)
        window.location.replace('/journalmain')
    }

    useEffect(() => {
        return () => {
            unsubscribe()
        }
    })

    const unsubscribe = store.subscribe(handleChange)

    return (
        <div className="semester">
            <div>
                <Autocomplete
                    disablePortal
                    options={menuItems}
                    id="selectSemester"
                    value={semester}
                    disableClearable
                    blurOnSelect
                    sx={{
                        width: 250,
                        fontFamily: "Wix Madefor Text"
                    }}
                    onChange={handleInputChange}
                    renderInput={params => (
                        <TextField
                            {...params}
                            inputRef={searchInput}
                            variant="standard"
                            InputProps={{
                                ...params.InputProps,
                                disableUnderline: true,
                                startAdornment: (
                                    <CalendarMonthRoundedIcon sx={{marginRight: "10px"}}/>
                                )
                            }}
                        />
                    )}
                />
            </div>
        </div>
    )
}

export default Semester
