import { Helmet } from 'react-helmet-async';
// @mui
import {
    Card,
    Stack,
    Container,
    Typography,
    TableContainer, CardContent, Button, CircularProgress, Box, ButtonGroup,
} from '@mui/material';

import {useNavigate, useParams} from 'react-router-dom';

// components
import Scrollbar from '../components/scrollbar';
// sections
import { StepByStepTable } from "../sections/@dashboard/attestations";
import {GeneralLayout} from "../layouts/GeneralLayout";
import Label from "../components/label";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {useGetNextAttestationNumberQuery, usePostCreateAttestationMutation} from "../store/attestation/attestationApi";
import {useGetPracticeQuery, useSetApprovalStatusMutation} from "../store/practice/practiceApi";
import PageTitle from "../components/PageTitle";

export default function PracticeApproveInfoPage() {
    const {practiceId} = useParams();
    const navigate = useNavigate();
    const [isSaving, setIsSaving] = useState(false);
    const {data} = useGetPracticeQuery({practiceId});
    const [setApprovalStatusRequest] = useSetApprovalStatusMutation();

    function formatDate(dateObj) {
        return `${dateObj.getFullYear()}-${withZero(dateObj.getMonth() + 1)}-${withZero(dateObj.getDate())}`;
    }

    function toDateString(date) {
        if (date === null || date === undefined)
            return '';
        
        return new Date(date).toLocaleDateString("ru-RU");
    }

    function withZero(num) {
        return num < 10 ? `0${num}` : num;
    }
    
    function practiceKindName(kind) {
        switch (kind) {
            case 1: return 'Учебная';
            case 2: return 'Производственная';
            default: return '';
        }
    }
    
    function formControlName(controlTypeId) {
        switch (controlTypeId) {
            case 1: return 'Зачёт';
            case 2: return 'Зачёт с оценкой';
            default: return '';
        }
    }

    const handleSave = async (approved) => {
        setIsSaving(true);

        await setApprovalStatusRequest({data: {practiceId, approved}}).unwrap().then((fulfilled) => {
            if (fulfilled?.success) {
                navigate('/practice-approve-list');
            } else {
                fulfilled.errors.map((item) => {
                    alert(item);
                    return 1;
                });

                setIsSaving(false);
            }
        });
    };

    return (
        <GeneralLayout>
            <PageTitle title={`Просмотр заявки на утверждение практики`} />

            <Container>
                <Stack direction="column"
                       padding="120px 0px 0px 100px"
                       spacing={2}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {data === null || data === undefined ? <CircularProgress /> : <></>}
                            Просмотр заявки
                        </Typography>
                    </Stack>

                    <Card>
                        <CardContent>
                            <Stack spacing={2}>
                                <Typography><strong>Группа: </strong>{data?.practice.groupName}</Typography>
                                <Typography><strong>Вид, тип практики: </strong>{practiceKindName(data?.practice.practiceKind)}, {data?.practice.practiceType}</Typography>
                                <Typography><strong>Приказ: </strong>{data?.practice.orderNumber}</Typography>
                                <Typography><strong>Форма контроля: </strong>{formControlName(data?.practice.controlType)}</Typography>
                                
                                <hr />

                                <Typography><strong>Сроки проведения: </strong>{data?.practice.timeSpans.map((ts) => {
                                    return `с ${ts.from} по ${ts.to}`;
                                }).join(', ')}</Typography>
                                <Typography><strong>Сроки защиты: </strong>с {toDateString(data?.practice.defenceFrom)} по {toDateString(data?.practice.defenceTo)}</Typography>
                                
                                <hr />

                                <Typography><strong>Состав комиссии по защите отчетов: </strong></Typography>
                                <ol>
                                    {data?.practice.commission.map((employee) => {
                                        return <li>{employee.lastName} {employee.firstName} {employee.middleName}</li>
                                    })}
                                </ol>
                                
                            </Stack>
                        </CardContent>
                    </Card>
                    <>
                        {
                            !isSaving && data !== null && data !== undefined
                                ? <Stack direction={"row"} spacing={1}>
                                    <Button color={"error"}
                                            variant={"contained"}
                                            onClick={() => {handleSave(false)}}>Отклонить</Button>
                                    <Button color={"success"}
                                            variant={"contained"}
                                            style={{"color": "white"}}
                                            onClick={() => {handleSave(true)}}>Утвердить</Button>
                                </Stack>
                                : <CircularProgress/>
                        }
                    </>
                    <br />
                </Stack>
            </Container>
        </GeneralLayout>
    );
}