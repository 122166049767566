import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {prepareAuthHeaders} from "../../utils/prepareAuthHeaders";

export const courseApi = createApi({
    reducerPath: 'courseApi',
    keepUnusedDataFor: 1,
    baseQuery: fetchBaseQuery({
        baseUrl: '/Course',
        prepareHeaders: prepareAuthHeaders,
    }),
    endpoints: (builder) => ({
        getCourseGroups: builder.query({
            query: ({courseId}) => ({
                url: `/GroupsOfCourse`,
                method: 'GET',
                params: {courseId}
            }),
        }),
    }),
});

export const {
    useGetCourseGroupsQuery
} = courseApi;
