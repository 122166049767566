import { Helmet } from 'react-helmet-async';
// @mui
import {
    Card,
    Stack,
    Container,
    Typography,
    TableContainer, CardContent, Button, CircularProgress, Box, Table, TableHead, TableRow, TableCell, TableBody, Link,
} from '@mui/material';

import {useNavigate, useParams} from 'react-router-dom';

// components
import Scrollbar from '../components/scrollbar';
// sections
import { StepByStepTable } from "../sections/@dashboard/attestations";
import {GeneralLayout} from "../layouts/GeneralLayout";
import Label from "../components/label";
import TextField from "@mui/material/TextField";
import React, {useEffect, useState} from "react";
import {useGetNextAttestationNumberQuery, usePostCreateAttestationMutation} from "../store/attestation/attestationApi";
import PageTitle from "../components/PageTitle";

export default function StudentSearchPage() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [studentPattern, setStudentPattern] = useState('');
    const [data, setData] = useState([]);
    const [firstSearch, setFirstSearch] = useState(true);

    document.onkeyup = async (event) => {
        if (studentPattern.length === 0)
            return;
        
        if (event.key === 'Enter') {
            await findStudents();
        }
    };
    
    async function findStudents() {
        setIsLoading(true);
        const studentsJson = await fetch(`/Student/GetStudentsByPattern?pattern=${studentPattern}`,
            {
                method: "GET",
                headers: { "semesterid": localStorage.getItem("selectedSemesterId"), "Content-Type": "application/json", "Authorization": `Bearer ${localStorage.getItem('token')}`}
            }).then(response => {
            setFirstSearch(false);
            setIsLoading(false);
            return response.json();
        });
        
        console.log('We got a json', studentsJson);
        setData(studentsJson.students);
    }

    function formatDate(dateObj) {
        return `${dateObj.getFullYear()}-${withZero(dateObj.getMonth() + 1)}-${withZero(dateObj.getDate())}`;
    }

    function withZero(num) {
        return num < 10 ? `0${num}` : num;
    }

    return (
        <GeneralLayout>
            <PageTitle title={`Поиск студента`}/>

            <Container>
                <Stack direction="column"
                       padding="120px 0px 0px 100px"
                       spacing={2}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {data === null || data === undefined ? <CircularProgress /> : <></>}
                            Поиск студента
                        </Typography>
                    </Stack>

                    <Card>
                        <CardContent>
                            <Stack spacing={2}>
                                <Stack direction={"row"} spacing={1}>
                                    <TextField placeholder={'ФИО студента'} 
                                               value={studentPattern} 
                                               fullWidth
                                               onInput={(event)=>setStudentPattern(event.target.value)}
                                               size={"small"} />
                                    <Button variant={"contained"}
                                            disabled={studentPattern.length === 0}
                                            onClick={()=>findStudents()}
                                            size={"small"}>Поиск</Button>
                                </Stack>

                                {isLoading ? <CircularProgress /> : <></>}
                                {data?.length > 0
                                ? <Table size={"small"}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell width={50}>#</TableCell>
                                                <TableCell>ФИО</TableCell>
                                                <TableCell>Группа</TableCell>
                                                <TableCell>ОПОП ВО</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data?.map((item, index) => {
                                                return <TableRow>
                                                    <TableCell>{index + 1}</TableCell>
                                                    <TableCell>
                                                        <Link href={`/student-card/${item.studentId}`}>{item.lastName} {item.firstName} {item.middleName}</Link>
                                                    </TableCell>
                                                    <TableCell>
                                                        {item.groupName}
                                                        {item.isFired ? <><br />{`(отчислен)`}</> : <></>}
                                                    </TableCell>
                                                    <TableCell>{item.educationProgramName}</TableCell>
                                                </TableRow>
                                            })}
                                        </TableBody>
                                    </Table>
                                : firstSearch ? <></> : <Typography>Нет результатов</Typography>}
                            </Stack>
                        </CardContent>
                    </Card>
                    
                    <br />
                </Stack>
            </Container>
        </GeneralLayout>
    );
}