import PropTypes from "prop-types";
import {Button, Typography} from "@mui/material";
import {Downloading, RemoveCircleOutline, Upload} from "@mui/icons-material";
import {useState} from "react";

FileUploadButton.propTypes = {
    maxFileSize: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    existingFileUrl: PropTypes.string,
    existingFileGuid: PropTypes.string,
    onFileUploaded: PropTypes.func,
    onFileDelete: PropTypes.func,
    uploadingEnabled: PropTypes.bool.isRequired,
    align: PropTypes.string
}

export default function FileUploadButton(props) {
    const fsHost = "https://fs.kubsau.ru/";
    const [refresh, setRefresh] = useState(false);

    async function onFileSelect(event) {
        if (event.target.files.length === 0)
            return;

        if (event.target.files[0].size > props.maxFileSize) {
            alert(`Максимально допустимый размер файла - ${fileSizeName(props.maxFileSize)}`)
            return;
        }

        // row.attestationListContent = event.target.files[0];
        // props.existingFileUrl = event.target.value;
        // const reader = new FileReader();
        // reader.onloadend = (() => {
        //     console.log(reader.result);
        //     row.attestationListContent = reader.result;
        //    
        //     console.log(row);
        // });
        //
        // reader.readAsArrayBuffer(event.target.files[0]);

        const formData = new FormData();
        formData.append("file", event.target.files[0]);

        try {
            const result = await fetch(`${fsHost}uploadfile`, {
                method: "POST",
                body: formData,
            });

            const jsonReply = await result.text();
            const fileGuid = JSON.parse(jsonReply).FileGuid;
            props.onFileUploaded(`${fsHost}File/${fileGuid}`, fileGuid);
        }
        catch (error) {
            console.error(error);
        }

        applyVisualChanges();
    }

    function applyVisualChanges() {
        setRefresh(!refresh);
    }
    
    function fileSizeName(size) {
        const names = ["б", "кб", "мб", "гб"];
        let decimalPoints = 0;
        let currentSize = size;
        while (currentSize >= 1024) {
            currentSize /= 1024;
            decimalPoints += 1;
        }
        
        return `${(size / (1024 ** decimalPoints))} ${names[decimalPoints]}`;
    }
    
    return <Typography align={props.align ?? "right"} fontSize={"small"}>{props.title}:
        {(props.existingFileUrl !== null && props.existingFileUrl !== undefined || props.existingFileGuid !== null && props.existingFileGuid !== undefined)
        || !props.uploadingEnabled
            ? <>
                <Button size={"small"} startIcon={<Downloading />} href={props.existingFileUrl ?? `${fsHost}/File/${props.existingFileGuid}`} target={'_blank'}>Просмотр</Button>
                {props.uploadingEnabled
                    ? <Button size={"small"} startIcon={<RemoveCircleOutline />} onClick={() => { props.onFileDelete() }}>Удалить</Button>
                    : <></>}
            </>
            : <>
                <Button size={"small"}
                        component="label"
                        startIcon={<Upload />}
                        role={undefined}>
                    Загрузить({fileSizeName(props.maxFileSize)} макс)

                    <input type="file" hidden onInput={(event) => onFileSelect(event)}/>
                </Button>
            </>
        }
    </Typography>
}