import {Helmet} from 'react-helmet-async';
// @mui
import {
    Card,
    Stack,
    Container,
    Typography,
    TableContainer, CardContent, Button, CircularProgress, Box, Tab, FormLabel, Checkbox,
} from '@mui/material';

import {useNavigate, useParams} from 'react-router-dom';

// components
import Scrollbar from '../components/scrollbar';
// sections
import {StepByStepTable} from "../sections/@dashboard/attestations";
import {GeneralLayout} from "../layouts/GeneralLayout";
import Label from "../components/label";
import TextField from "@mui/material/TextField";
import React, {useEffect, useState} from "react";
import {useGetNextAttestationNumberQuery, usePostCreateAttestationMutation} from "../store/attestation/attestationApi";
import {TabContext, TabList} from "@mui/lab";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import IconButton from "@mui/material/IconButton";
import {
    Close,
    ClosedCaption,
    Remove,
    RemoveCircleOutlineSharp,
    Save,
    SaveAlt,
    SaveAsRounded
} from "@mui/icons-material";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import AddIcon from "@mui/icons-material/Add";
import {useSaveDisciplineSubgroupsMutation, useSubGroupsByDisciplineBindingQuery} from "../store/chair/chairApi";
import PageTitle from "../components/PageTitle";

export default function EditBindingsPage() {
    const roles = localStorage.getItem("roles")?.split(',') ?? [];
    const {courseId, disciplineId} = useParams();
    const navigate = useNavigate();
    const [refresh, setRefresh] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [commonHours, setCommonHours] = useState(0);
    const [tabIndex, setTabIndex] = useState(6);
    const {data} = useSubGroupsByDisciplineBindingQuery({courseId, disciplineId});
    const [availableEmployees, setAvailableEmployees] = useState([]);
    const [groupFlowBindings, setGroupFlowBindings] = useState([]);
    const [saveDisciplineSubgroupsRequest] = useSaveDisciplineSubgroupsMutation();
    const isAdmin = roles.find((item) => item === "AttNewAdmin") !== undefined;
    
    useEffect(() => {
        if (data === null || data === undefined) {
            return;
        }
        
        // временная мера по задаче 367
        if (!isAdmin) {
            alert("Доступ запрещен")
            return;
        }
        
        if (groupFlowBindings.length === 0 && data?.data?.groupFlowBindings !== null && data?.data?.groupFlowBindings !== undefined) {
            setGroupFlowBindings(copyObject(data?.data.groupFlowBindings));
            console.log('Установили биндинги', data?.data.groupFlowBindings);
        }
        
        if (availableEmployees.length === 0) {
            setAvailableEmployees(copyObject(data?.availableEmployees));
        }
    }, [data])


    function formatDate(dateObj) {
        return `${dateObj.getFullYear()}-${withZero(dateObj.getMonth() + 1)}-${withZero(dateObj.getDate())}`;
    }
    
    function applyVisualChanges() {
        setRefresh(!refresh);
    }

    function copyObject(obj) {
        return JSON.parse(JSON.stringify(obj));
    }

    function withZero(num) {
        return num < 10 ? `0${num}` : num;
    }
    
    function addSubGroup(groupFlowBinding) {
        const binding = groupFlowBindings.find((item) => item === groupFlowBinding);
        const newObj = {
            groupFlowTeamName: `подгр ${groupFlowBinding.groupFlowTeams.length + 1}.`,
            employeeId: null
        };
        
        binding.groupFlowTeams = binding.groupFlowTeams.concat(newObj);
        
        console.log(binding);
        
        applyVisualChanges();
    }
    
    function removeSubGroup(groupFlowBinding, groupFlowTeam) {
        if (groupFlowBinding.groupFlowTeams.length === 1)
            return;
        
        groupFlowBinding.groupFlowTeams.splice(groupFlowBinding.groupFlowTeams.indexOf(groupFlowTeam), 1);
        applyVisualChanges();
    }

    const handleSave = async () => {
        setIsSaving(true);
        const request = {
            courseId,
            disciplineId,
            groupFlowBindings
        };
        
        await saveDisciplineSubgroupsRequest({data: request}).unwrap().then((fulfilled) => {
            if (fulfilled?.success) {
                navigate('/journalmainold');
            } else {
                fulfilled.errors.map((item) => {
                    alert(item);
                    return 1;
                });

                setIsSaving(false);
            }
        });
    };

    const handleTabChange = (event, value) => {
        console.log(value);
        setTabIndex(value)
    }
    
    function setHoursForAll() {
        const gfb = activeGroupFlowBindings();
        for (let i = 0; i < gfb.length; i+=1) {
            gfb[i].hours = commonHours;
        }
        
        applyVisualChanges();
    }

    function toDateString(date) {
        return new Date(date).toLocaleDateString("ru-RU");
    }
    
    function activeGroupFlowBindings() {
        return groupFlowBindings.filter((item) => (item.lessonType === 4 ? 3 : item.lessonType) === tabIndex);
    }

    return (
        <GeneralLayout>
            <PageTitle title={`Редактировать распределение групп`} />

            <Container>
                <Stack direction="column"
                       padding="120px 0px 0px 100px"
                       spacing={2}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {data === null || data === undefined ? <CircularProgress/> : <></>}
                            Редактировать распределение групп
                        </Typography>
                    </Stack>

                    <Typography variant={"h5"}>{data?.data?.disciplineName}</Typography>

                    <Card>
                        <TabContext value={tabIndex}>
                            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                                <TabList onChange={(event, data) => handleTabChange(event, data)}>
                                    <Tab label="Лекции" value={6}/>
                                    <Tab label="Практические занятия" value={3}/>
                                    <Tab label="Лабораторные занятия" value={1}/>
                                </TabList>
                            </Box>
                        </TabContext>

                        <CardContent>
                            <Stack spacing={2}>
                                <Stack direction={"row"} alignItems={"center"} spacing={1}>
                                    <Typography>Одинаковое количество часов для всех групп</Typography>
                                    
                                    <TextField size={"small"}
                                               type={"number"}
                                               value={commonHours}
                                               onInput={(event) => setCommonHours(event.target.value)}/>
                                    <Typography>ч.</Typography>
                                    <Button size={"small"} variant={"contained"} onClick={()=>setHoursForAll()}>Установить</Button>
                                </Stack>

                                {activeGroupFlowBindings()
                                    .sort((left, right) => left.groupName > right.groupName ? 1 : -1)
                                    .map((item) => {
                                        return <Card variant={"outlined"}>
                                            <CardContent>
                                                <Stack spacing={2}>
                                                    <Stack direction={"row"} 
                                                           alignItems={"center"}
                                                           justifyContent={"space-between"}
                                                           spacing={2}>
                                                        <Typography variant={"h6"}>{item.groupName}</Typography>
                                                        
                                                        <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                                            <FormGroup>
                                                                <TextField size={"small"}
                                                                           placeholder={"Кол-во часов"}
                                                                           value={item.hours}
                                                                           onInput={(event) => {item.hours = event.target.value; applyVisualChanges()}}
                                                                           type={"number"}/>
                                                            </FormGroup>

                                                            <Typography>ч.</Typography>
                                                        </Stack>
                                                    </Stack>

                                                    {item.groupFlowTeams
                                                        .map((gft, index) => {
                                                        return <Card variant={"outlined"}>
                                                            <CardContent>
                                                                <Stack spacing={2}>
                                                                    <Stack direction={"row"}
                                                                           alignItems={"center"}
                                                                           justifyContent={"space-between"}
                                                                           spacing={2}>
                                                                        <Typography variant={"h6"}>Подгруппа {index+1}</Typography>
                                                                        
                                                                        <Stack direction={"row"} spacing={2}>
                                                                            <TextField value={gft.groupFlowTeamName}
                                                                                       onInput={(event) => {gft.groupFlowTeamName = event.target.value; applyVisualChanges()}}
                                                                                       placeholder={"Наименование"}
                                                                                       size={"small"}/>
                                                                            
                                                                            <IconButton disabled={tabIndex !== 1}
                                                                                        color={"error"} 
                                                                                        onClick={()=>removeSubGroup(item, gft)}>
                                                                                <Close />
                                                                            </IconButton>
                                                                        </Stack>
                                                                    </Stack>
                                                                    
                                                                    <FormControl size={"small"}>
                                                                        <InputLabel id="employee-label">Преподаватель</InputLabel>
                                                                        <Select label={"Преподаватель"}
                                                                                value={gft.jobId}
                                                                                onChange={(event) => { gft.jobId = event.target.value; applyVisualChanges() }}
                                                                                labelId={"employee-label"}>
                                                                            <MenuItem value={0} key={0}>Не выбран</MenuItem>
                                                                            {availableEmployees
                                                                                .map((employee) => {
                                                                                    return <MenuItem key={employee.jobId} value={employee.jobId}>{employee.fullName} ({employee.activeTo === null || employee.activeTo === undefined ? 'активная ставка' : `ставка с ${toDateString(employee.activeFrom)} по ${toDateString(employee.activeTo)}`})</MenuItem>
                                                                                })}
                                                                        </Select>
                                                                    </FormControl>
                                                                </Stack>
                                                            </CardContent>
                                                        </Card>
                                                    })}
                                                    
                                                    <Button disabled={tabIndex !== 1}
                                                            startIcon={<AddIcon />} 
                                                            onClick={()=> addSubGroup(item)}>Добавить подгруппу</Button>
                                                </Stack>
                                            </CardContent>
                                        </Card>
                                    })}
                            </Stack>
                        </CardContent>
                    </Card>
                    <Stack justifyContent="space-between">
                        <Button variant="contained"
                                startIcon={isSaving ? <CircularProgress size={15}/> :<SaveAsRounded />}
                                disabled={data === null || data === undefined || isSaving}
                                onClick={handleSave}>{isSaving ? 'Изменения сохраняются...' : 'Сохранить'}</Button>
                    </Stack>
                    <br />
                </Stack>
            </Container>
        </GeneralLayout>
    );
}