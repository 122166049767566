import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import {Navigate, useNavigate} from "react-router-dom";
import {useEffect} from "react";

export default function HomePage() {
	const navigate = useNavigate();
	
	useEffect(() => {
		const roles = localStorage.getItem("roles") ?? '';
		const isEmployee = roles.split(',').find((item) => item === "employee") !== undefined
		let navigateTo = '/auth'
		console.log(roles);
		if (roles === null || roles === undefined || roles.length === 0 || roles === 'null') {
			navigateTo = '/auth';
		}
		else {
			const isUmu = isRole("AttNewUmu", roles.split(','));
			if (isUmu)
				navigateTo = '/umu-main';
			else
				navigateTo = isEmployee ? '/journalmain' : '/journalmainold';
		}

		console.log(`Переадресация на ${navigateTo}`);
		navigate(navigateTo, { replace: true });	
	});

	function isRole(name, roles) {
		return roles.find((item) => item === name) !== undefined;
	}
	
	return <Box>
		Загрузка...
	</Box>
}
