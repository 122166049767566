import {Helmet} from 'react-helmet-async';
import {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {
    ChevronLeftRounded,
    AddRounded,
    AddCommentRounded,
    CloseRounded,
    EditRounded
} from "@mui/icons-material"
// @mui
import {
    Card,
    CardContent,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Button,
    IconButton,
    List,
    ListItemButton,
    Switch, Snackbar, Grid, Tooltip, CircularProgress, Typography
} from "@mui/material"
// components
import {Alert} from "@mui/lab";
import {DateCalendar} from "@mui/x-date-pickers";
import moment from "moment";
import Scrollbar from '../components/scrollbar';
import StudentGradeEditTable from "../sections/@dashboard/common/StudentGradeEditTable";
import {GeneralLayout} from "../layouts/GeneralLayout";
import {useGetLessonCreationDataQuery, usePostSaveOrUpdateLessonMutation} from "../store/journal/journalApi";
// sections
import "./editjournal/styles.scss"
import styled from "@emotion/styled"
import MainLayout from "./main";
import CommentModal from "./editjournal/components/comment_modal";
import ValueMenu from "./editjournal/components/value_menu";
import GroupFilters from "./editjournal/components/group_filters";
import InfoPanel from "../components/info_panel";
import PageTitle from "../components/PageTitle";

const StyledSwitch = styled(Switch)(({theme}) => ({
    "& .MuiSwitch-switchBase": {
        "&.Mui-checked": {
            color: "#fff",
            "& + .MuiSwitch-track": {
                backgroundColor: "#00BFA5",
                opacity: 1
            }
        }
    },
    "& .MuiSwitch-track": {
        backgroundColor: "#FF1744",
        opacity: 1,
        "&::before, &::after": {
            content: '""',
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            width: 16,
            height: 16,
            backgroundSize: "contain"
        }
    },
    "& .MuiSwitch-thumb": {
        backgroundColor: "#D9D9D9"
    }
}))


export default function JournalEditPage() {
    const {groupFlowTeamId, virtualGroupId} = useParams();
    // moment().locale("ru");
    // const navigate = useNavigate();
    const [refresh, setRefresh] = useState(false);
    // const [isSaving, setIsSaving] = useState(false);
    const [saveOrUpdateLesson] = usePostSaveOrUpdateLessonMutation();
    //
    // const {data} = useGetLessonCreationDataQuery({groupFlowTeamId, virtualGroupId});
    //
    // const [selectedDate, setSelectedDate] = useState(null)
    // const [validationMessage, setValidationMessage] = useState(null);
    // const action = useRef(null);
    // const isEditMode = false;
    // const [lessonTheme, setLessonTheme] = useState('');
    
    const [formData, setFormData] = useState({
        lessonDate: null,
        lessonGuid: null,
        pairNumber: 1,
        theme: null,
        groupFlowTeams: []
    });
    const [availablePairs, setAvailablePairs] = useState([]);
    const [panelOpen, setPanelOpen] = useState(true)
    const [theme, setTheme] = useState("")
    const [date, setDate] = useState(null)
    const [pair, setPair] = useState(0)
    const [data, setData] = useState(null)
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState("")
    const [commentModalOpen, setCommentModalOpen] = useState("")
    const [comment, setComment] = useState("")
    const [studentId, setStudentId] = useState(-1)
    const [groupId, setGroupId] = useState(-1)
    const navigate = useNavigate()
    const {data: lessonData} = useGetLessonCreationDataQuery({groupFlowTeamId, virtualGroupId});
    const [copyOfLessonData, setCopyOfLessonData] = useState(null);
    const [isSaving, setIsSaving] = useState(false);

    function applyVisualChanges() {
        setRefresh(!refresh);
    }
    
    function findStudent(id) {
        console.log('trying to find a student', id)
        const group = copyOfLessonData.lesson.groups.find((item) => item.students.filter((stud) => stud.studentId === id).length > 0);
        console.log('group for student', group)
        if (group !== undefined) {
            return group.students.find((item) => item.studentId === id);
        }
        
        return undefined;
    }

    // Переключение студента по клику на строке таблицы
    const toggleStudentHere = (e, student) => {
        // const path = Array.from(e.nativeEvent.composedPath());
        //
        // const buttons = path.filter((e) => {
        //     return e.tagName === "A" || e.tagName === "BUTTON" || e.tagName === "LI"
        // });
        //
        // if (!buttons.length) {
        //     // student.attended = !student.attended;
        //     const stud = findStudent(student.studentId);
        //     // stud.attended = !stud.attended;
        //    
        //     // applyVisualChanges();
        // }
    }

    // Прокрутка до группы
    const handleGroupClick = e => {
        const groupIndex = e.target.dataset.group;
        const link = `#group-${groupIndex}`
        const element = document.querySelector(link)
        const top = element.offsetTop
        window.scrollTo({top: top - 110, behavior: "smooth"})
    }

    // Закрытие модального окна комментария
    const closeCommentModal = () => {
        setCommentModalOpen("")
    }

    // Задание текста комментария в данных
    const setCommentField = (groupId, studentId, comment) => {
        const student = findStudent(studentId);
        const formStudent = findFormStudent(studentId);
        student.comment = comment
        formStudent.comment = comment;
        console.log(formStudent.comment)
        setComment("");

        setCommentModalOpen("")
        setSnackbarMessage("Комментарий изменён!")
        setSnackbarOpen(true)

        // Закрываем уведомление через 2 секунды
        setTimeout(() => {
            setSnackbarOpen(false)
        }, 2000)
    }

    // Открытие модального окна комментария
    const openCommentModal = e => {
        const button = e.currentTarget
        const studentId = button.dataset.studentid
            ? parseInt(button.dataset.studentid, 10)
            : -1
        const groupId = button.dataset.groupid
            ? parseInt(button.dataset.groupid, 10)
            : -1

        setGroupId(groupId)
        setStudentId(studentId)

        console.log(studentId)
        const student = findStudent(studentId);

        setComment(student.comment ?? '')

        setCommentModalOpen(" open")
    }

    // Удаление оценки
    const valueRemover = (sectionId, studentId, valueId) => {
        const student = findStudent(studentId)
        const formStudent = findFormStudent(studentId);

        student.grades.splice(valueId, 1)
        formStudent.grades.splice(valueId, 1)
        setSnackbarMessage("Оценка удалена!")
        setSnackbarOpen(true)

        setTimeout(() => {
            setSnackbarOpen(false)
        }, 2000)
    }

    // Откат изменений
    const handleCancel = () => {
        const prevGroupsData = localStorage.getItem("prevGroupsData") || ""
        const prevData = JSON.parse(prevGroupsData)
        setData(prevData)
        setSnackbarOpen(false)
    }

    const cancelAction = (
        <>
            <Button
                onClick={handleCancel}
                sx={{color: "yellow", marginRight: "10px"}}
            >
                Отмена
            </Button>
            <IconButton onClick={() => setSnackbarOpen(false)}>
                <CloseRounded sx={{color: "#ffffff"}}/>
            </IconButton>
        </>
    )

    // Установка оценки
    const valueSetter = (sectionId, studentId, valueId, val, name) => {
        localStorage.setItem("prevGroupsData", JSON.stringify(data))
        // const newData = [...data]
        // const section = newData[sectionId]
        // const student = section.students[studentId]
        const student = findStudent(studentId);
        const formStudent = findFormStudent(studentId);
        if (student.grades === undefined)
            student.grades = [];
        
        const grade = student.grades[valueId]
        console.log('valueSetter', grade, val, name)

        // Если оценка была, меняем её
        if (grade) {
            grade.grade = val
            grade.gradeType = name;
            formStudent.grades[valueId].grade = val;
            formStudent.grades[valueId].gradeType = name;

            setSnackbarMessage("Оценка изменена!")
        } else if (name != null) {
            // Иначе создаём её
            const value = {
                gradeType: name,
                grade: val
            }

            setSnackbarMessage("Оценка проставлена!")

            // Закрываем уведомление через 2 секунды
            setTimeout(() => {
                setSnackbarOpen(false)
            }, 2000)

            student.grades.push(value)
            formStudent.grades.push(value);
        } else {
            alert("Укажите тип оценки!")
        }

        setSnackbarOpen(true)

        applyVisualChanges();
    }

    // Отработка кнопки "Назад"
    const back = () => {
        navigate(-1)
    }

    // Установка состояния панели
    const setter = (value, id) => {
        const state = {
            panelId: id,
            opened: value
        }

        const stateString = JSON.stringify(state)
        localStorage.setItem("panelState", stateString)
        setPanelOpen(value)
    }

    // Тема
    const themeSetter = newVal => {
        // setTheme(newVal)
        formData.theme = newVal
        // Вывод фильтров в консоль
        //   showFilters()
    }

    function isSameDate(date1, date2) {
        return date1.date() === date2.date() && date1.month() === date2.month() && date1.year() === date2.year();
    }

    // Дата
    const dateSetter = newVal => {
        setDate(newVal)
        formData.lessonDate = newVal
        
        pairSetter(0);
        const pairs = [];
        const daysInSchedule = copyOfLessonData.availableLessonDates.filter((item) => isSameDate(moment(item.dateTime), newVal));
        if (daysInSchedule.length > 0) {
            daysInSchedule.forEach((item) => {
                if (pairs.find((pair) => pair === item.pairNumber) === undefined)
                    pairs.push(item.pairNumber)
            })
        }
        
        setAvailablePairs(pairs);
        if (pairs.length === 1) {
            pairSetter(pairs[0])
        }

        // Вывод фильтров в консоль
        // showFilters()
    }

    /**
     * Пара
     * @param {number} newVal Новое значение
     */
    const pairSetter = newVal => {
        setPair(newVal)
        formData.pairNumber = newVal

        // Вывод фильтров в консоль
        showFilters()
    }

    /**
     * Вывод фильтров в консоль
     */
    const showFilters = () => {
        const data = {
            theme,
            date,
            pair
        }

        console.table(data)
    }

    /**
     * Монтаж компонента
     */
    useEffect(() => {
        // Состояние информационной панели
        const stateString = localStorage.getItem("panelState")

        // Чтение состояния информационной панели из localStorage
        if (stateString) {
            const state = JSON.parse(stateString)
            setter(state.opened, state.panelId)
        } else {
            setter(true, "statement-page-info")
        }

        if (lessonData !== null && lessonData !== undefined && copyOfLessonData === null) {
            setCopyOfLessonData(copyObject(lessonData));
            const groupFlowTeams = [];
            for (let i = 0; i < lessonData.lesson.groups.length; i += 1) {
                groupFlowTeams.push(convertToGroupFlowTeam(lessonData.lesson.groups[i]));
            }

            formData.groupFlowTeams = groupFlowTeams;
        }
    }, [lessonData])

    function copyObject(obj) {
        return JSON.parse(JSON.stringify(obj));
    }

    const hereSwitcher = (e, checked, student) => {
        student.attended = !student.attended;
        const formStudent = findFormStudent(student.studentId);
        if (formStudent === null) {
            alert('Произошла ошибка. Студент не найден')
        } else {
            formStudent.attended = student.attended;
        }
        
        applyVisualChanges();
    }
    
    function findFormStudent(id) {
        const gft = formData.groupFlowTeams.find((item) => 
            item.students.find((stud) => stud.studentId === id) !== undefined);
        
        if (gft === undefined)
            return null;
        
        return gft.students.find((stud) => stud.studentId === id);
    }

    function switchAll(e, group) {
        group.students.forEach((item) => {
            item.attended =  !item.attended; // e.target.checked
            const formStudent = findFormStudent(item.studentId); // TODO: improve
            formStudent.attended = item.attended;
        });
        
        applyVisualChanges();
    }
    
    function canSave() {
        return formData.lessonDate !== null && formData.lessonDate !== undefined
            && formData.pairNumber >= 1 && formData.pairNumber <= 6
            // && formData.theme !== null && formData.theme !== undefined && formData.theme.length > 0
    }

    const handleSave = async () => {
        if (formData.theme === null || formData.theme === undefined || formData.theme.length === 0) {
            alert('Заполните тему занятия')
            return;    
        }
        
        setIsSaving(true);
        console.log(formData);

        // setTimeout(() => {
        //     setIsSaving(false);
        // }, 2500)
        
        await saveOrUpdateLesson({formData}).unwrap().then((fulfilled) => {
            if (fulfilled?.success) {
                navigate('/journalmain');
            } else {
                fulfilled.errors.map((item) => {
                    alert(item);
                    return 1;
                });

                setIsSaving(false);
            }
        }).catch((error) => {
            console.log(error);
            alert('Ошибка');
            setIsSaving(false);
        })
    };
    
    function convertToGroupFlowTeam(obj) {
        return {
            groupFlowTeamId: obj.groupId,
            groupFlowTeamName: obj.groupName,
            students: obj.students.map((item) => {
                return {
                    studentId: item.studentId,
                    lastName: item.lastName,
                    firstName: item.firstName,
                    middleName: item.middleName,
                    attended: item.attended,
                    grade: item.grade,
                    extraGrade: item.extraGrade,
                    extraGradeType: item.extraGradeType,
                    skippedHours: item.skippedHours,
                    totalHours: item.totalHours,
                    certificatesOfSkipping: item.certificatesOfSkipping,
                    grades: [],
                    comment: item.comment
                }
            })
        };
    }

    function certificateTypeName(id) {
        switch (id) {
            case 0: return 'Справка';
            case 1: return 'Распоряжение деканата';
            case 2: return 'Заявление';
            case 3: return 'График дежурств';
            default: return '-';
        }
    }

    function toDateString(date) {
        if (date === null)
            return '';

        return new Date(date).toLocaleDateString("ru-RU");
    }

    function lessonTypeName(typeId) {
        switch (typeId) {
            case 1:
                return 'Лабораторная работа';
            case 3:
            case 4:
                return 'Семинар';
            case 6:
                return 'Лекция';
            default:
                return '';
        }
    }

    return (
        <MainLayout>
            <PageTitle title={`Заполнить занятие`} />
            <main>
                <section>
                    <div className="container">
                        <a href="#!" onClick={back} className="icon-block">
                            <ChevronLeftRounded/>
                            Назад
                        </a>
                        <InfoPanel
                            id="groups-info-panel"
                            title={copyOfLessonData?.lesson.disciplineName}
                            open={panelOpen}
                            message={
                                <div>
                                    <ol>
                                        <li>
                                            <strong>Основная оценка</strong> предназначена для отражения
                                            результатов работы обучающегося на занятии (устный ответ,
                                            защита доклада/реферата, работа на занятии). Выставляется
                                            непосредственно во время переклички в период 7-ми
                                            дней. Исправление или удаление оценки осуществляется ТОЛЬКО
                                            сотрудниками Центра ИТ через служебную записку, подписанную
                                            начальником УМУ.
                                        </li>
                                        <li>
                                            <strong>Дополнительная оценка</strong> предназначена для
                                            отражения результатов работы всей группы обучающихся
                                            (проведение тестирования, защиты лабораторных работ,
                                            контрольных работ, домашнего задания, расчетно-графических
                                            работ и другого). Если дополнительная оценка выставлена хотя
                                            бы одному обучающемуся, то всем остальным также должна быть
                                            выставлена оценка. Оценка может быть выставлена в период
                                            14-ти дней даже студентам, которые отсутствовали на
                                            занятии. Исправление или удаление оценки осуществляется
                                            ТОЛЬКО сотрудниками Центра ИТ через служебную записку,
                                            подписанную начальником УМУ.
                                        </li>
                                    </ol>
                                </div>
                            }
                            type={'info'}
                            setter={setter}
                            subtitle={
                                <>
                                    {lessonTypeName(copyOfLessonData?.lesson?.lessonType ?? 0)} №{copyOfLessonData?.lesson.filledLessons + 1}
                                    <span className="fogged">(из {copyOfLessonData?.lesson.totalNumberOfLessons})</span>
                                </>
                            }
                        />
                    </div>
                    <div className="container">
                        <Grid
                            container
                            className="filters-wrapper"
                            sx={{alignItems: "unset"}}
                        >
                            <Grid item lg={9}>
                                <GroupFilters
                                    theme={theme}
                                    date={date}
                                    pair={pair}
                                    themeSetter={themeSetter}
                                    dateSetter={dateSetter}
                                    pairSetter={pairSetter}
                                    availableLessonDates={copyOfLessonData?.availableLessonDates}
                                    availablePairs={availablePairs}
                                />

                                {copyOfLessonData?.lesson.groups.sort((left,right) => left.groupName > right.groupName ? 1 : -1)
                                    .map((group, sectionIndex) => {
                                    return (
                                        <Card key={sectionIndex} id={`group-${(sectionIndex + 1)}`}>
                                            <CardContent>
                                                <h2 style={{marginTop: 0}}>{group.groupName}</h2>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell colSpan={3}>ФИО</TableCell>
                                                            <TableCell data-group={sectionIndex}>
                                                                <StyledSwitch onChange={(e) => switchAll(e, group)}/>
                                                            </TableCell>
                                                            <TableCell>Пропуски</TableCell>
                                                            <TableCell>Процент пропусков</TableCell>
                                                            <TableCell>Оценка</TableCell>
                                                            <TableCell sx={{textAlign: "right"}}>
                                                                Добавить оценку
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {group?.students?.map((student, studentIndex) => {
                                                            let skipping = (
                                                                <TableCell>
                                                                    {student.skippedHours}/
                                                                    {student.totalHours}
                                                                </TableCell>
                                                            )
                                                            let skippingPercent = (
                                                                <TableCell>
                                                                    {(student.skippedHours * 100 / student.totalHours).toFixed(2)}%
                                                                </TableCell>
                                                            )
                                                            const commentTooltip =
                                                                student.comment === "" || student.comment === undefined
                                                                    ? "Добавить комментарий"
                                                                    : "Изменить комментарий"
                                                            const commentIcon =
                                                                student.comment === "" || student.comment === undefined ? (
                                                                    <AddCommentRounded/>
                                                                ) : (
                                                                    <EditRounded/>
                                                                )
                                                            if ((student.skippedHours * 100 / student.totalHours) >= 50) {
                                                                skipping = (
                                                                    <TableCell sx={{color: "#FF1744"}}>
                                                                        {student.skippedHours}/
                                                                        {student.totalHours}{" "}
                                                                    </TableCell>
                                                                )
                                                                skippingPercent = (
                                                                    <TableCell sx={{color: "#FF1744"}}>
                                                                        {(student.skippedHours * 100 / student.totalHours).toFixed(2)}%
                                                                    </TableCell>
                                                                )
                                                            }

                                                            const filteredCertificates = student.certificatesOfSkipping?.filter((item) => item.fromDate <= date && item.toDate >= date);

                                                            return (
                                                                <TableRow key={studentIndex}
                                                                          data-group-id={sectionIndex}
                                                                          data-id={studentId} hover
                                                                          onClick={(event) => toggleStudentHere(event, student)}>
                                                                    <TableCell>{studentIndex + 1}</TableCell>
                                                                    <TableCell>
                                                                        <Tooltip
                                                                            title={commentTooltip}
                                                                            placement="top"
                                                                        >
                                                                            <IconButton
                                                                                onClick={openCommentModal}
                                                                                data-studentid={student.studentId}
                                                                                data-groupid={sectionIndex}
                                                                            >
                                                                                {commentIcon}
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <div
                                                                            className="name">{student.lastName} {student.firstName} {student.middleName}</div>
                                                                        <div className="comment">
                                                                            {student.comment}
                                                                            {filteredCertificates !== undefined && filteredCertificates !== null && filteredCertificates.length > 0
                                                                                ? filteredCertificates
                                                                                    .map((item) => {
                                                                                        return <Typography fontSize={"small"} color={"orange"}>Имеется {certificateTypeName(item.documentType).toLowerCase()} с {toDateString(item.fromDate)} ({item.fromPair} пара) по {toDateString(item.toDate)} ({item.toPair} пара)</Typography>
                                                                                    })
                                                                                : <></>}

                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <div
                                                                            data-group={sectionIndex}
                                                                            data-student={studentIndex}
                                                                        >
                                                                            <StyledSwitch
                                                                                onChange={(event, checked) => hereSwitcher(event, checked, student)}
                                                                                checked={student.attended}
                                                                            />
                                                                        </div>
                                                                    </TableCell>
                                                                    {skipping}
                                                                    {skippingPercent}
                                                                    <TableCell>
                                                                        <div className="values">
                                                                            {student.grades?.map((value, valueIndex) => {
                                                                                return (
                                                                                    <ValueMenu
                                                                                        valueId={valueIndex}
                                                                                        studentId={student.studentId}
                                                                                        key={valueIndex}
                                                                                        grade={value.grade}
                                                                                        gradeType={value.gradeType}
                                                                                        type={`update`}
                                                                                        content={
                                                                                            <span>{value.grade}</span>}
                                                                                        changeHandler={valueSetter}
                                                                                        removeHandler={valueRemover}
                                                                                    />
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell sx={{textAlign: "right"}}>
                                                                        <ValueMenu
                                                                            sectionId={sectionIndex}
                                                                            studentId={student.studentId}
                                                                            valueId={-1}
                                                                            type={`create`}
                                                                            grade={-1}
                                                                            gradeType={null}
                                                                            content={<AddRounded/>}
                                                                            changeHandler={valueSetter}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </CardContent>
                                        </Card>
                                    )
                                })}
                                <div className="save-wrapper">
                                    <Button variant="contained" 
                                            onClick={handleSave}
                                            disabled={isSaving || !canSave()}
                                            startIcon={isSaving ? <CircularProgress size={15}/> : <></>}>Сохранить</Button>
                                </div>
                            </Grid>
                            <Grid item lg={1}/>
                            <Grid item lg={2}>
                                <div className="pin">
                                    <h2>Группы</h2>
                                    <List>
                                        {copyOfLessonData?.lesson?.groups?.map((group, groupIndex) => {
                                            return (
                                                <ListItemButton
                                                    key={groupIndex}
                                                    data-group={groupIndex + 1}
                                                    onClick={handleGroupClick}
                                                >
                                                    {group.groupName}
                                                </ListItemButton>
                                            )
                                        })}
                                    </List>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </section>
                <Snackbar
                    open={snackbarOpen}
                    anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                    message={snackbarMessage}
                />
                <CommentModal
                    openClass={commentModalOpen}
                    comment={comment}
                    groupId={groupId}
                    studentId={studentId}
                    setter={setCommentField}
                    closeSetter={closeCommentModal}
                />
            </main>
        </MainLayout>
    );
}