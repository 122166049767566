import { MenuRounded } from "@mui/icons-material"
import { Menu, MenuItem, Divider, Button } from "@mui/material"
import {useEffect, useState} from "react"
import { Link, useNavigate } from "react-router-dom"
import "./styles.scss"
import {store} from "../../../../store/store"
import {useGetUserDetailsQuery} from "../../../../store/user/userApi";

const UserMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const navigate = useNavigate()
  const {data} = useGetUserDetailsQuery();
  const [user, setUser] = useState({
    fullname: "загрузка",
    login: "",
    logged: false
  });
  
  useEffect(() => {
    if (data === null || data === undefined)
      return;
    
    setUser({
      fullname: data.fullName
    })
    
    if (data.maintenanceMode === true) {
      window.location.replace('/maintenance');
    }
  }, [data])
  
  const handleClick = e => {
    setAnchorEl(e.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const loggedUser = localStorage.getItem("loggedUser")

  let menu = (
    <Button variant="text">
      Anonymous <MenuRounded />
    </Button>
  )

  const handleLogout = () => {
    localStorage.setItem("token", null);
    localStorage.setItem("roles", null);
    store.dispatch({type: "LOGOUT"})

    window.location.replace('https://auth.kubsau.ru')
  }

  if (loggedUser || true) {
    menu = (
      <>
        <Button
          variant="text"
          aria-controls={open ? "user-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          sx={{ marginRight: "0 !important", color: "black" }}
          onClick={handleClick}
        >
          {user?.fullname}{" "}
          <MenuRounded sx={{ marginLeft: "10px" }} />
        </Button>
        <Menu
          id="user-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}>
            <Link to={"/journalmain"}>Главная страница</Link>
          </MenuItem>
          <Divider />
          <MenuItem onClick={handleClose}>
            <Link to={"/attestations"}>Аттестации</Link>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Link to={"/mystatements"}>
              Мои ведомости
            </Link>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Link to={"/my-practice-statements"}>Практики</Link>
          </MenuItem>
          <Divider />
          <MenuItem onClick={handleLogout}>Выход</MenuItem>
        </Menu>
      </>
    )
  }

  return <div className="menu-wrapper right-align">{menu}</div>
}

export default UserMenu
