import {Link, Outlet, useNavigate} from "react-router-dom";
import './styles/main.scss';
import Navbar from "../components/navbar";
import PropTypes from "prop-types";
import '../scss/master.scss';

/** Главная страница (оболочка)  */
export default function MainLayout({children}) {
    const navigate = useNavigate();
    
    const token = localStorage.getItem("token");
    if (token === null || token === "null" || token === undefined || token.length === 0) {
        navigate('/auth');
        // return <Link to={'/auth'} />
    }
    
    return(
        <>
            <Navbar /> {/** Навигационное меню  */}
            <Outlet /> {/** Вывод страниц */}
            {children}
        </>
    )
}

// export default MainPage;