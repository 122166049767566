import {Button, Card, CardActions, CardContent, CardHeader, Divider, Stack, Typography} from "@mui/material";
import {Add, Close, Remove} from "@mui/icons-material";
import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {disciplines} from "../../../_mock/disciplines";

OutsidePlanDisciplineInfoBlock.propTypes = {
    discipline: PropTypes.object,
    selectedStudents: PropTypes.array,
    chairEmployees: PropTypes.array,
    individualSchedule: PropTypes.bool,
    badGradesRetake: PropTypes.bool,
    onNewStatementCreated: PropTypes.func,
    onNewStatementRemoved: PropTypes.func,
    onNewStatementUpdated: PropTypes.func,
    editableDateAndPlace: PropTypes.bool,
    newStatement: PropTypes.object
}

export default function OutsidePlanDisciplineInfoBlock(props) {
    const [newStatement, setNewStatement] = useState(null);
    const [refresh, setRefresh] = useState(false);
    
    useEffect(() => {
        props.onNewStatementUpdated(newStatement);
    }, [newStatement])
    
    function gradeColor(gradeId) {
        switch (gradeId) {
            case 1: return '';
            case 2: return 'red';
            case 3: return 'orange';
            case 4: return 'green';
            case 5: return 'green';
            default: return 'red';
        }
    }
    
    function addEmployee(employee) {
        if (newStatement === null || newStatement === undefined)
            return;
        
        if (newStatement.employees.find((item) => item === employee) !== undefined)
            return;
        
        newStatement.employees.push(employee);
        applyVisualChanges()
    }
    
    function removeEmployee(employee) {
        if (newStatement === null || newStatement === undefined)
            return;
        
        newStatement.employees.splice(newStatement.employees.indexOf(employee), 1);
        applyVisualChanges();
    }
    
    function applyVisualChanges() {
        setRefresh(!refresh);
    }

    function copyObject(obj) {
        return JSON.parse(JSON.stringify(obj));
    }
    
    function createStatement() {
        console.log('Создаем новую ведомость');
        const obj = {
            sessionDisciplineId: props.discipline.sessionDisciplineId,
            statementNumber: '',
            students: notPassedStudents(props.discipline),
            eventDateTime: null,
            eventPlace: '',
            employees: []
        };
        
        setNewStatement(obj);
        
        props.onNewStatementCreated(obj);
        console.log('Создали новую ведомость', obj);
    }
    
    function removeStatement() {
        props.onNewStatementRemoved(copyObject(newStatement))
        setNewStatement(null);
    }

    function notPassedStudents(discipline) {
        if (discipline.noStatements) {
            return props.selectedStudents.map((item) => {
                return {
                    studentId: parseInt(item.key, 10),
                    gradeId: 1,
                    fullName: item.value
                }
            });
        }
        
        const notPassed = [];
        for (let j = 0; j < discipline.statements.length; j+=1) {
            const badStudents = discipline.statements[j].students.filter((item) => !props.badGradesRetake 
                ? item.gradeId === 2 || item.gradeId >= 6 || (props.individualSchedule ? item.gradeId === 1 : false) 
                : item.gradeId === 3 || item.gradeId === 4);
            for (let i = 0; i < badStudents.length; i += 1) {
                notPassed.push(badStudents[i]);
            }
        }

        return notPassed;
    }
    
    return <>
        <Typography variant={"h5"}>{props.discipline?.disciplineName}</Typography>
        <Stack direction={"row"} alignItems={"center"} spacing={2}>
            {props.discipline?.statements.length === 0
                ? <><Divider />
                    {props.discipline.noStatements ? <Typography>На студентов еще не выписывались ведомости</Typography> : <></>}
                    <>{(newStatement === null || newStatement === undefined) && (props.individualSchedule || !props.discipline.noStatements && props.discipline.statements.length > 0) 
                        ? <Button startIcon={<Add />} variant={"contained"} onClick={() => createStatement()}>Создать ведомость по дисциплине</Button> 
                        : (newStatement === null
                            ? <>
                                <Typography>Невозможно создать ведомость для выбранных студентов</Typography>
                            </>
                            : <></>)}
                    </>
                </>
                : <>
                    {props.discipline?.statements.map((statement) => {
                        return <>
                            <Card variant={"outlined"}>
                                <CardContent>
                                    <Stack spacing={1}>
                                        <Typography>Ведомость <Button size={"small"} href={`/statementresults/${statement.statementId}`} target={"_blank"}>№{statement.statementNumber}</Button></Typography>
                                        <Typography color={statement.isClosed ? '' : 'red'}>{statement.isClosed ? 'Ведомость закрыта' : 'Ведомость не закрыта'}</Typography>
                                        <Divider />
                                        {statement.students.map((student) => {
                                            return <Typography color={gradeColor(student.gradeId)}>{student.fullName} - {student.gradeName}</Typography>
                                        })}
                                    </Stack>
                                </CardContent>
                            </Card>
                        </>
                    })}

                    {notPassedStudents(props.discipline).length === 0
                        ? <Typography fontSize={"small"}>
                            Ведомость не может быть создана<br />
                            т.к. среди выбранных студентов нет не сдавших студентов<br />
                            по данной дисциплине
                        </Typography>
                        : <>{newStatement === null || newStatement === undefined ? <Button startIcon={<Add />} variant={"contained"} onClick={() => createStatement()}>Создать ведомость по дисциплине</Button> : <></>}</>}

                    
                </>}

            {newStatement === null || newStatement === undefined
                ? <></>
                : <>
                    <Card>
                        <CardHeader title={'Формируемая ведомость'}
                                    action={<IconButton
                                        color={"error"}
                                        onClick={() => removeStatement()}><Close /></IconButton>}/>
                        <CardContent>
                            <Stack alignItems={"center"} spacing={2}>
                                <Stack>
                                    <Typography>№ Ведомости</Typography>
                                    <TextField size={"small"}
                                               fullWidth
                                               value={newStatement.statementNumber}
                                               onInput={(event) =>{ newStatement.statementNumber = event.target.value; applyVisualChanges() }}/>
                                </Stack>

                                {props.editableDateAndPlace
                                    ? <Stack>
                                        <Typography>Дата и время проведения</Typography>
                                        <TextField size={"small"}
                                                   fullWidth
                                                   type={"datetime-local"}
                                                   value={newStatement.eventDateTime}
                                                   onInput={(event) =>{ newStatement.eventDateTime = event.target.value; applyVisualChanges() }}/>
                                    </Stack>
                                    : <></>}

                                {props.editableDateAndPlace
                                    ? <Stack>
                                        <Typography>Место проведения</Typography>
                                        <TextField size={"small"}
                                                   fullWidth
                                                   value={newStatement.eventPlace}
                                                   onInput={(event) =>{ newStatement.eventPlace = event.target.value; applyVisualChanges() }}/>
                                    </Stack>
                                    : <></>}

                                <Stack>
                                    <Typography>Список преподавателей</Typography>
                                    <Select size={"small"} fullWidth onChange={(event) => {
                                        addEmployee(event.target.value);
                                    }}>
                                        {props.chairEmployees?.find((item) => item.chairId === props.discipline.chairId)?.employees?.map((item) => {
                                            return <MenuItem value={item} key={item.employeeId}>{item.lastName} {item.firstName} {item.middleName}</MenuItem>
                                        })}
                                    </Select>

                                    <ol>
                                        {newStatement.employees.map((e) => {
                                            return <li>{e.lastName} {e.firstName} {e.middleName} <IconButton color={"error"} size={"small"} onClick={() => removeEmployee(e)}><Remove /></IconButton></li>
                                        })}
                                    </ol>
                                </Stack>

                                <Divider width={100}/>

                                <Typography>Список студентов, вносимых в ведомость</Typography>
                                <ol>
                                    {notPassedStudents(props.discipline).map((notPassed) => {
                                        return <li key={notPassed.studentId}>{notPassed.fullName}</li>
                                    })}
                                </ol>
                            </Stack>
                        </CardContent>
                    </Card>
                </>}
        </Stack>
    </>
}