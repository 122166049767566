import {Helmet} from "react-helmet-async";
import PropTypes from "prop-types";

PageTitle.propTypes = {
    title: PropTypes.string.isRequired
}

export default function PageTitle(props) {
    return <Helmet>
        <title> {props.title} | АИС "Успеваемость" </title>
    </Helmet>
}