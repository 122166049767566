import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { prepareAuthHeaders } from '../../utils/prepareAuthHeaders';

export const attestationApi = createApi({
    reducerPath: 'attestationApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/Attestation',
        prepareHeaders: prepareAuthHeaders,
    }),
    keepUnusedDataFor: 0,
    endpoints: (builder) => ({
        getNextAttestationNumber: builder.query({
            query: ({courseId}) => ({
                url: `/NextAttestationNumber`,
                method: 'GET',
                params: {courseId}
            }),
        }),
        postCreateAttestation: builder.mutation({
            query: ({ courseId, startDate, endDate }) => ({
                url: `/CreateAttestation?courseId=${courseId}&startDate=${startDate}&endDate=${endDate}`,
                method: 'POST'
            }),
            invalidatesTags: ['Post'],
        }),
        getFacultyAttestations: builder.query({
            query: ({courseId}) => ({
                url: `/GetFacultyAttestations?courseId=${courseId}`,
                method: 'GET'
            }),
        }),
        getAttestationDetails: builder.query({
            query: ({attestationId}) => ({
                url: `/GetAttestationDetails?attestationId=${attestationId}`,
                method: 'GET'
            }),
        }),
        postEditAttestation: builder.mutation({
            query: ({ data }) => ({
                url: `/EditAttestation`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['Post'],
        }),
        getAttestationStatementDetails: builder.query({
            query: ({attestationId, groupId}) => ({
                url: `/GetAttestationStatementDetails`,
                method: 'GET',
                params: {attestationId, groupId}
            }),
        }),
        getEmployeeAttestations: builder.query({
            query: () => ({
                url: `/EmployeeAttestations`,
                method: 'GET'
            }),
        }),
        getEmployeeAttestationStatementDetails: builder.query({
            query: ({attestationStatementId}) => ({
                url: `/EmployeeAttestationStatementDetails`,
                method: 'GET',
                params: {
                    attestationStatementId
                }
            }),
        }),
        postEmployeeEditAttestationStatement: builder.mutation({
            query: ({ attestationStatementId, students }) => ({
                url: `/EmployeeEditAttestationStatement`,
                method: 'POST',
                body: {
                    attestationStatementId,
                    students
                }
            }),
            invalidatesTags: ['Post'],
        }),
    }),
});

export const {
    useGetNextAttestationNumberQuery,
    usePostCreateAttestationMutation,
    useGetFacultyAttestationsQuery,
    useGetAttestationDetailsQuery,
    usePostEditAttestationMutation,
    useGetAttestationStatementDetailsQuery,
    useGetEmployeeAttestationsQuery,
    useGetEmployeeAttestationStatementDetailsQuery,
    usePostEmployeeEditAttestationStatementMutation
} = attestationApi;
