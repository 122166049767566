import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { prepareAuthHeaders } from '../../utils/prepareAuthHeaders';

export const practiceApi = createApi({
  reducerPath: 'practiceApi',
  keepUnusedDataFor: 1,
  baseQuery: fetchBaseQuery({
    baseUrl: '/Practice',
    prepareHeaders: prepareAuthHeaders,
  }),
  endpoints: (builder) => ({
    createPractice: builder.mutation({
      query: ({ data }) => ({
        url: `/CreatePractice`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Post'],
    }),
    editPractice: builder.mutation({
      query: ({ data }) => ({
        url: `/EditPractice`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Post'],
    }),
    getPracticesOfGroup: builder.query({
      query: ({groupId}) => ({
        url: `/PracticesOfGroup`,
        method: 'GET',
        params: {groupId}
      }),
    }),
    setNextApprovalStatus: builder.mutation({
      query: ({ practiceId }) => ({
        url: `/SetNextApprovalStatus?practiceId=${practiceId}`,
        method: 'POST'
      }),
      invalidatesTags: ['Post'],
    }),
    getPractice: builder.query({
      query: ({practiceId}) => ({
        url: `/GetPractice`,
        method: 'GET',
        params: {practiceId}
      }),
    }),
    getNextPracticeStatementCreationDetails: builder.query({
      query: ({practiceId}) => ({
        url: `/GetNextPracticeStatementCreationDetails`,
        method: 'GET',
        params: {practiceId}
      }),
    }),
    createNextPracticeStatement: builder.mutation({
      query: ({ data }) => ({
        url: `/CreateNextPracticeStatement`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Post'],
    }),
    practiceStatementResults: builder.query({
      query: ({id}) => ({
        url: `/PracticeStatementResults`,
        method: 'GET',
        params: {id}
      }),
    }),
    myPracticeStatements: builder.query({
      query: () => ({
        url: `/MyPracticeStatements`,
        method: 'GET'
      }),
    }),
    employeePracticeStatementDetails: builder.query({
      query: ({id}) => ({
        url: `/EmployeePracticeStatementDetails`,
        method: 'GET',
        params: {id}
      }),
    }),
    editPracticeStatement: builder.mutation({
      query: ({ data }) => ({
        url: `/EditPracticeStatement`,
        method: 'POST',
        type: 'application/json',
        body: data
      }),
      invalidatesTags: ['Post'],
    }),
    closeStatement: builder.mutation({
      query: ({ data }) => ({
        url: `/CloseStatement`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Post'],
    }),
    downloadAttachments: builder.query({
      query: ({statementId}) => ({
        url: `/DownloadAttachments`,
        method: 'GET',
        params: {statementId},
        contentType: 'application/zip',
        responseType: 'application/zip'
      }),
    }),
    practicesForApproval: builder.query({
      query: () => ({
        url: `/PracticesForApproval`,
        method: 'GET'
      }),
    }),
    setApprovalStatus: builder.mutation({
      query: ({ data }) => ({
        url: `/SetApprovalStatus`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Post'],
    }),
  }),
});

export const {
  useCreatePracticeMutation,
  useEditPracticeMutation,
  useGetPracticesOfGroupQuery,
  useSetNextApprovalStatusMutation,
  useGetPracticeQuery,
  useGetNextPracticeStatementCreationDetailsQuery,
  useCreateNextPracticeStatementMutation,
  usePracticeStatementResultsQuery,
  useMyPracticeStatementsQuery,
  useEmployeePracticeStatementDetailsQuery,
  useEditPracticeStatementMutation,
  useCloseStatementMutation,
  useDownloadAttachmentsQuery,
  usePracticesForApprovalQuery,
  useSetApprovalStatusMutation
} = practiceApi;
