import {useParams} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import {
    Badge,
    Box,
    Button,
    Card,
    CircularProgress,
    Container,
    Stack,
    Table, TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {GeneralLayout} from "../layouts/GeneralLayout";
import Scrollbar from "../components/scrollbar";
import {
    useGetAttestationDetailsQuery,
    useGetAttestationStatementDetailsQuery
} from "../store/attestation/attestationApi";
import {useEffect, useState} from "react";
import PageTitle from "../components/PageTitle";

export default function AttestationDetailsPage() {
    const {id} = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [selectedGroupId, setSelectedGroupId] = useState(null);
    const [resultsByGroup, setResultsByGroup] = useState(null);
    const [disciplines, setDisciplines] = useState([]);
    const {data} = useGetAttestationDetailsQuery({attestationId: id});
    const resultsByGroupQuery = useGetAttestationStatementDetailsQuery({attestationId: id, groupId: selectedGroupId});
    
    useEffect(() => {
        if (selectedGroupId === null || selectedGroupId === undefined)
            return;
        
        setIsLoading(true);

        resultsByGroupQuery.refetch().unwrap().then((fulfilled) => {
            setIsLoading(false);
            setResultsByGroup(fulfilled?.students);
            setDisciplines(fulfilled?.disciplines);
        });
    }, [selectedGroupId])
    
    function avg(array) {
        if (array === null || array.length === 0)
            return '-';
        
        return Math.round(array.reduce((a, b) => a + b, 0) / array.length * 100) / 100;
    }
    
    function avgByDiscipline(disciplineId) {
        const grades = resultsByGroup.map((s) => {
            return s.results.find((sr) => {
                return sr.disciplineId === disciplineId
            })?.gradeId
        }).filter((g) => g !== null && g >= 2 && g <= 5);
        
        return avg(grades);
    }
    
    function avgByStudent(student) {
        const grades = student.results.filter(g => {
            return g?.gradeId !== null && g.gradeId >= 2 && g.gradeId <= 5;
        })
        
        const missedHours = student.results
            .filter((item) => item.missedHours !== null)
            .map((item) => item.missedHours);
        
        return `${avg(grades)}/${missedHours.reduce((a, b) => a + b, 0)}`;
    }
    
    function gradeIdToColor(gradeId) {
        if (gradeId === null)
            return 'info';
        
        switch (gradeId){
            case 1: return 'info';
            case 2: return 'error';
            case 3: return 'warning';
            case 4: return 'success';
            case 5: return 'success';
            case 6: return 'error';
            case 7: return 'error';
            default: return 'info';
        }
    }
    
    function gradeIdToGradeName(gradeId) {
        switch (gradeId) {
            case 1: return  '-';
            case 6: return  'н/д';
            case 7: return  'н/я';
            default: return gradeId.toString();
        }
    }
    
    function onGroupSelected(groupId) {
        setSelectedGroupId(groupId);
    }

    return (
        <GeneralLayout>
            <PageTitle title={'Аттестационные ведомости'} />

            <Container>
                <Stack direction="column"
                       padding="120px 0px 0px 100px"
                       spacing={2}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {data === null || data === undefined ? <CircularProgress /> : <></>}
                            Аттестационные ведомости
                        </Typography>
                    </Stack>

                    <Stack direction="row" spacing={1}>
                        {data?.data?.groups?.map((item) => {
                            return <Button variant="contained" key={item.groupId} onClick={() => { onGroupSelected(item.groupId) }}>{item.groupName}</Button>
                        })}
                    </Stack>
                    
                    <Card>
                        <Scrollbar>
                            {disciplines?.length > 0 
                            ? <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width={10}>#</TableCell>
                                            <TableCell>ФИО студента</TableCell>
                                            {disciplines?.map((d) => {
                                                return <TableCell align="center">{d.disciplineName}</TableCell>
                                            })}
                                            <TableCell align={"center"}><strong>ИТОГО</strong></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {resultsByGroup?.map((result, index) => {
                                            return <TableRow>
                                                <TableCell align="center">
                                                    <Typography>
                                                        {index + 1}
                                                        <Box bgcolor="#1a90ff" 
                                                             color="white"
                                                             borderRadius={2}
                                                            padding={0.5}>
                                                            <Typography fontSize={"small"}>
                                                                {result.results.filter((r) => {
                                                                    return r.gradeId === 2 || r.gradeId > 5;
                                                                }).length}
                                                                / 
                                                                {result.results.length}
                                                            </Typography>
                                                        </Box>
                                                    </Typography>
                                                </TableCell>

                                                <TableCell>
                                                    <strong>{result.studentLastName}</strong><br />{result.studentFirstName} {result.studentMiddleName}
                                                </TableCell>
                                                {disciplines?.map((discipline) => {
                                                    const resultOfThisDiscipline = result?.results?.find((r) => r.disciplineId === discipline.disciplineId);
                                                    return <TableCell align="center">
                                                        <Badge
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            badgeContent={resultOfThisDiscipline?.missedHours}
                                                            color="primary">
                                                            <Button variant="contained"
                                                                    color={gradeIdToColor(resultOfThisDiscipline?.gradeId)}
                                                                    size="small">
                                                                {
                                                                    resultOfThisDiscipline !== null
                                                                        ? gradeIdToGradeName(resultOfThisDiscipline.gradeId)
                                                                        : '-'
                                                                }
                                                            </Button>
                                                        </Badge>
                                                    </TableCell>
                                                })}
                                                <TableCell align={"center"}>{avgByStudent(result)}</TableCell>
                                            </TableRow>
                                        })}
                                        <TableRow>
                                            <TableCell colSpan={2} align={"right"}><strong>ИТОГО</strong></TableCell>
                                            {disciplines?.map((d) => {
                                                return <TableCell align={"center"}>{avgByDiscipline(d.disciplineId)}</TableCell>
                                            })}
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            : <>{isLoading ? <CircularProgress /> : <></>}</>}
                        </Scrollbar>
                    </Card>
                </Stack>
            </Container>
        </GeneralLayout>)
}