import {useNavigate, useParams} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    Container,
    Stack,
    Table, TableBody, TableCell,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {GeneralLayout} from "../layouts/GeneralLayout";
import {useGetSessionDisciplineDetailsInfoQuery, usePostCreateStatementMutation} from "../store/session/sessionApi";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {useListAllChairsQuery} from "../store/chair/chairApi";
import {
    useCreateNextPracticeStatementMutation,
    useGetNextPracticeStatementCreationDetailsQuery
} from "../store/practice/practiceApi";
import PageTitle from "../components/PageTitle";

export default function CreatePracticeStatementPage() {
    const navigate = useNavigate();
    const {practiceId, groupId} = useParams();
    const [refresh, setRefresh] = useState(false);
    const [statementNumber, setStatementNumber] = useState('');
    const [selectedCommonChairId, setSelectedCommonChair] = useState(null);
    const [copyOfStudents, setCopyOfStudents] = useState([]);
    const {data} = useGetNextPracticeStatementCreationDetailsQuery({practiceId});
    const [createStatement] = useCreateNextPracticeStatementMutation();
    
    const chairsQuery = useListAllChairsQuery(); // request all chairs
    const [copyOfChairs, setCopyOfChairs] = useState([]);
    
    const [isSaving, setIsSaving] = useState(false);


    useEffect(() => {
        if (data !== null && data !== undefined && copyOfStudents.length === 0) {
            setCopyOfStudents(copyObject(data.data.students));
            applyVisualChanges();
        }
        
        if (chairsQuery.isSuccess && copyOfChairs.length === 0) {
            setCopyOfChairs(chairsQuery.data.chairs);
            applyVisualChanges();
        } else if (chairsQuery.isError === true) {
            alert('Произошла ошибка при загрузке');
        }
    }, [data, chairsQuery]);

    function copyObject(obj) {
        return JSON.parse(JSON.stringify(obj));
    }

    function toDateString(date) {
        return new Date(date).toLocaleDateString("ru-RU");
    }

    function formatDate(dateObj) {
        return `${dateObj.getFullYear()}-${withZero(dateObj.getMonth() + 1)}-${withZero(dateObj.getDate())}`;
    }

    function applyVisualChanges() {
        setRefresh(!refresh);
    }

    function withZero(num) {
        return num < 10 ? `0${num}` : num;
    }

    const handleSave = async () => {
        setIsSaving(true);

        // сделать редирект на страницу с ведомостями, а не с сессиями
        await createStatement({data: {
                statementNumber,
                students: copyOfStudents,
                practiceId,
                passingType: data?.data?.passingType
            }})
            .unwrap().then((fulfilled) => {
                
            if (fulfilled.success)
                navigate(`/faculty-practices-for-group/${groupId}`);
            else {
                for (let i = 0; i < fulfilled.errors.length; i+=1) {
                    alert(fulfilled.errors[i]);
                }
                
                setIsSaving(false);
            }
        });
    };
    
    function onStudentChairChanged(chairId, student)
    {
        console.log('chair for student was changed')
        student.chairId = chairId;
        applyVisualChanges();
    }
    
    function setCommonChair() {
        if (selectedCommonChairId === null || selectedCommonChairId === undefined || selectedCommonChairId === 0)
            return;
        
        for (let i = 0; i < copyOfStudents.length; i+=1) {
            copyOfStudents[i].chairId = selectedCommonChairId;
        }
        
        console.log(`Установка общей кафедры ${selectedCommonChairId}`);
        applyVisualChanges();
    }
    
    function passingTypeName(passingType) {
        switch (passingType) {
            case 0: return 'основной';
            case 1: return 'дополнительной';
            case 2: return 'комиссионной';
            default: return '';
        }
    }

    return <GeneralLayout>
        <PageTitle title={'Создать ведомость по практике'} />

        <Container>
            <Stack
                padding="120px 0px 0px 100px"
                direction="column"
                spacing={2}
            >
                <Stack mb={5}>
                    <Typography variant="h4" gutterBottom>
                        {data === null || data === undefined
                            ? <CircularProgress/>
                            : <></>} Создание {passingTypeName(data?.data?.passingType)} ведомости для группы {data?.data.groupName}. Практика
                        "{data?.data.practiceType ?? '-'}"
                    </Typography>
                </Stack>

                <Card>
                    <CardContent>
                        <Stack direction={"row"} justifyContent={"space-between"}>
                            <Stack spacing={1}>
                                <Typography>№ ведомости</Typography>
                                <TextField placeholder={'№ ведомости'}
                                           value={statementNumber}
                                           size={"small"}
                                           onChange={(event) => { setStatementNumber(event.target.value) }}/>
                            </Stack>

                            <Stack spacing={1}>
                                <Typography>Установить кафедру для всех</Typography>
                                <Select size={'small'}
                                        value={selectedCommonChairId}
                                        onChange={(event) => { setSelectedCommonChair(event.target.value); }}>
                                    {copyOfChairs?.map((chair) => {
                                        return <MenuItem key={parseInt(chair.key, 10)} value={parseInt(chair.key, 10)}>{chair.value}</MenuItem>
                                    })}
                                </Select>
                                <Button color={"primary"} 
                                        variant={"contained"}
                                        onClick={() => setCommonChair()}>Установить</Button>
                            </Stack>
                        </Stack>
                    </CardContent>
                </Card>

                <Card>
                    <CardContent>
                        <Stack spacing={5}>
                            <Stack>
                                <Table size={"small"}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width={20}>#</TableCell>
                                            <TableCell>ФИО студента</TableCell>
                                            <TableCell>Кафедра*</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {copyOfStudents.sort((left, right) => {
                                            if (left.fullName > right.fullName)
                                                return 1;
                                            
                                            return -1
                                        }).map((student, index) => {
                                            return <TableRow>
                                                <TableCell>{index+1}</TableCell>
                                                <TableCell>{student.fullName}</TableCell>
                                                <TableCell>
                                                    <Select value={student.chairId}
                                                            fullWidth
                                                            size={"small"}
                                                            onChange={(event) => onStudentChairChanged(event.target.value, student)}>
                                                        {copyOfChairs?.map((chair) => {
                                                            return <MenuItem value={parseInt(chair.key, 10)}>{chair.value}</MenuItem>
                                                        })}
                                                    </Select>
                                                </TableCell>
                                            </TableRow>
                                        })}
                                    </TableBody>
                                </Table>
                                <Stack direction={"row"} justifyContent={'end'}>
                                    <small>*кафедра, на которой студент проходит практику</small>
                                </Stack>
                            </Stack>

                            <Stack justifyContent="space-between">
                                {
                                    !isSaving
                                        ? <Button variant="contained" onClick={handleSave}>Сохранить</Button>
                                        : <CircularProgress/>
                                }
                            </Stack>
                        </Stack>
                    </CardContent>
                </Card>
            </Stack>
        </Container>
    </GeneralLayout>
}