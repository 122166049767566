// routes
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import moment from "moment";
import {Route, BrowserRouter as Router, Routes} from "react-router-dom";
import './scss/master.scss';
// theme

import {StyledChart} from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import ThemeProvider from './theme';
// components
import 'moment/locale/ru';
import {createTheme} from "@mui/material/styles";
import JournalMainPage from "./pages/JournalMainPage";
import MainLayout from "./pages/main";
import {Auth} from "./pages/Auth";
import MyStatementsPage from "./pages/MyStatementsPage";
import JournalEditPage from "./pages/JournalEditPage";

export default function App({children}) {
    const theme = createTheme({
        typography: {
            fontFamily: 'Wix Madefor Text',
        }
    })

    return (
        // <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="ru">
        //     <ThemeProvider theme={theme}>
        //         <div className="App">
        //             {/* Роутер */}
        //             <Router>
        //                 <Routes>
        //                     <Route path="/" element={<Auth/>}/>
        //                     <Route path="/main" element={<MainPage/>}>
        //                         <Route path="journalmain" element={<JournalMainPage/>}/>
        //                         <Route path="mystatements" element={<MyStatementsPage/>}/>
        //                         <Route path="journaledit" element={<JournalEditPage/>}/>
        //                     </Route>
        //                 </Routes>
        //             </Router>
        //         </div>
        //     </ThemeProvider>
        // </LocalizationProvider>

        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="ru">
            <ThemeProvider theme={theme}>
                <StyledChart/>
                <div className="App">
                    {children}
                </div>
            </ThemeProvider>
        </LocalizationProvider>
    );
}
