import {useNavigate, useParams} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import {
    Box,
    Card,
    CardContent,
    CircularProgress,
    Container,
    Stack,
    Tab,
    Table, TableBody, TableCell,
    TableHead, TableRow,
    Typography
} from "@mui/material";
import {GeneralLayout} from "../layouts/GeneralLayout";
import {useState} from "react";
import {TabContext, TabList} from "@mui/lab";
import StatementCard from "../components/session/StatementCard";
import FacultyStatementCard from "../components/session/FacultyStatementCard";
import {useGetSessionStatementsQuery} from "../store/session/sessionApi";
import PageTitle from "../components/PageTitle";

export default function ManageStatementsPage() {
    const navigate = useNavigate();
    const {id} = useParams();
    const [tabValue, setTabValue] = useState('0');
    const {data} = useGetSessionStatementsQuery({sessionId: id});

    function onTabChange(value) {
        setTabValue(value);
    }
    
    function getStatementCard(statement) {
        return <FacultyStatementCard statementId={statement?.statementId ?? 0} 
                                     statementNumber={statement?.statementNumber}
                                     orderNumber={statement?.orderNumber}
                                     canCreate={statement?.canCreate}
                                     closingDate={statement?.dateOfClose}
                                     sessionDisciplineDetailId={statement?.sessionDisciplineDetailId}/>
    }

    function controlTypeToInternalType(controlType) {
        if (controlType === 0)
            return 0;

        if (controlType === 1 || controlType === 2)
            return 1;

        return 2;
    }
    
    return  <GeneralLayout>
        <PageTitle title={`Управление ведомостями`} />

        <Container>
            <Stack
                padding="120px 0px 0px 100px"
                direction="column"
                spacing={2}
            >
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        {data === null || data === undefined
                            ? <CircularProgress/>
                            : <></>} Управление ведомостями {data?.courseName}
                    </Typography>
                </Stack>

                <Card>
                    <TabContext value={tabValue}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={(event, value) => { onTabChange(value); }}>
                                <Tab label="Экзамены" value="0" />
                                <Tab label="Зачёты" value="1" />
                                <Tab label="Курсовые работы" value="2" />
                            </TabList>
                        </Box>
                    </TabContext>
                    
                    <CardContent>
                        {data?.disciplines?.filter((d) => controlTypeToInternalType(d.controlType) === parseInt(tabValue, 10))
                            .map((d) => {
                            return <>
                                <Typography variant="h5">{d.disciplineName}</Typography>
                                <Typography fontSize="small">Кафедра {d.chairName}</Typography>
                                <br />
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Группа</TableCell>
                                            <TableCell align="center">Осн. ведомость</TableCell>
                                            <TableCell align="center">Доп. ведомость</TableCell>
                                            <TableCell align="center">Комиссионная</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {d.groups.filter((g) => g.passingType === 0).map((g) => {
                                            return <TableRow>
                                                <TableCell>{g.groupName}</TableCell>
                                                <TableCell align="center">
                                                    { getStatementCard(d.groups.find((s) => s.passingType === 0 && s.groupName === g.groupName)) }
                                                </TableCell>
                                                <TableCell align="center">
                                                    { getStatementCard(d.groups.find((s) => s.passingType === 1 && s.groupName === g.groupName)) }
                                                </TableCell>
                                                <TableCell align="center">
                                                    { getStatementCard(d.groups.find((s) => s.passingType === 2 && s.groupName === g.groupName)) }
                                                </TableCell>
                                            </TableRow>
                                        })}
                                    </TableBody>
                                </Table>
                                
                                <br />
                            </>
                        })}
                    </CardContent>
                </Card>
            </Stack>
        </Container>
    </GeneralLayout>
}